<template>
    <v-dialog v-model="state.exportCSV" width="600">
        <v-card title="Dialog"       class="mx-auto pa-12 pb-8 mt-5"
      elevation="8"
      max-width="600"
      width="500"
      rounded="lg">
            <div class="text-h6 mb-4">
                General settings
              </div>

       <v-btn
          text="Close Dialog"
          @click="isActive.value = false"
        ></v-btn>

        <div class="text-h6 mb-4">
                General settings
              </div>
              <v-switch
          color="black"
          v-model="state.black"
          label="Dark mode"
        ></v-switch>

        <v-btn
        block
        class="mb-8"
        color="#97c11f"
        size="large"
        variant="tonal"
        @click="downloadCSV"
      >
        Download csv
      </v-btn>
    </v-card>
    
    </v-dialog>
  </template>
    
  <script>
  import api from '../../api'
  import state from '../../state'
  import dataHandler  from '../../script/Calculations/dataHandler'
  import Settings from '../../script/Settings'
  export default {
    data: () => ({
      selectItem: 0,
      select: 'Today',
      dates: [],
      items: ['Today', 'Week', 'Month', 'Last year'],
      selectedProject: 'SAZ',
      allUsers: null,
      currentTime: 1,
      dHandler: new dataHandler(),
      Settings: new Settings(),
      selectedUser: 'OP',
      api,
      userD: {
        name: ''
      },
      loaded: false,
      loadedTest: false,
      exportGas: false,
      exportElectric: false,
      exportChemical: false,
      state,
    }),
    watch: {
    'state.date.select' (val) {
      this.dHandler.getData()
      },
    'state.Settings' (val) {

    }
    },
    mounted() {
      //this.getApiCall();
      this.Settings.getSettings()
      this.loadedTest = true
    },
    methods: {
        downloadCSV() {
          const csvContent = this.convertCSV(state.curing_oven)
          const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'bath_test.csv')
          link.click()
        },

        convertCSV (data) {
          const headers = Object.keys(data[0])
          const rows = data.map(obj => headers.map(header => obj[header]));
          const headerRow = headers.join(',')
          const csvRows = [headerRow, ...rows.map(row => row.join(','))]
          return csvRows.join('\n')

        }
    } 
  }
  </script>
    
  <style scoped>
  .border {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10%;
    text-decoration: none;
  }
  
  .space {
    margin-left: -100px;
    margin-right: -100px;
  }
  
  .select {
    max-width: 200px;
    max-height: 500px;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .top {
    margin-top: 80px;
  }
  
  .v-list-item-group .v-list-item--active {
    color: white !important;
  }
  
  .center-title {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(203, 203, 203);
  }
  
  .center-title2 {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(131, 127, 127);
  }
  
  .cost-graph {
    margin-left:20px;
  }
  
  .pie-chart {
    width: auto;
      margin: auto;
  }
  </style>
    