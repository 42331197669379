<template>
    <v-col cols="12" md="11" ref="col">
      <v-item>
        <v-card :color="state.black ? '#1e1e1e' : 'white'" class="d-flex align-center rounded-xl" dark height="350" title="oven">
          <GChart v-if="this.loaded && state.loadedAll" class="cost-graph" type="AreaChart" :data="chartData"
                  :options="chartOptions" />
        </v-card>
      </v-item>
    </v-col>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'
import state from '../../../state'
export default {
    components: {GChart },
  data: () => ({
      chartData: [
    ['Time', 'Gasdata'],
    ['Pump', 1000],
    ['Lowlevel', 1170],
    ['Highlevel', 660],
    ['E stop', 1030]
  ],
  chartOptions: {
    title: 'Electric (kW)',
    series: {
      0: { color: '#fbb800' },
      1: {color: '#dc6511'},        
      2: { color: '#b70d7f'}
    },
    hAxis: {
      format: 'dd/MM HH:mm',
      textStyle:{color: '#7c7c7c'},
      gridlines: {
        units: {
            days: {format: ['MMM dd']},
            hours: {format: ['HH:mm', 'ha']},
            color: '#434242'
          },
      },
      
    },
    vAxis: {
      textStyle:{color: '#7c7c7c'},        
      gridlines: {
      },
    },
    legendTextStyle: { color: '#7c7c7c' },
    titleTextStyle: { color: '#7c7c7c' },
    color: '#FFF',
    backgroundColor: {fill:'transparent'},
    legend: {position: 'bottom'},
    chartArea: { left: 40, top: 50, width: "88%", height: "70%" },
    width: window.innerWidth / 1.4,
    height:  window.innerHeight / 3
  },
  loaded: false,
  state,
}),
props: {
      stitle: String,
      data: Array,
      amountElements: Number,
},
watch: {
  'state.ElGraph.length' (val) {
    this.editGraph()
  }
},
mounted() {      
  if (state.black == false) {
      this.chartOptions.backgroundColor = "white"
  }
  this.$nextTick(() => {
    window.addEventListener('resize', this.onResize);
  })
  this.chartOptions.width = this.$refs.col.clientWidth
  this.chartOptions.height = this.$refs.col.clientHeight - 40
  this.editGraph()
},

methods: {
  onResize() {
    this.chartOptions.width = this.$refs.col.clientWidth
    this.chartOptions.height = this.$refs.col.clientHeight - 40
  },
  editGraph() {
    this.loaded = false;
    this.chartData = []
    this.chartOptions.title = this.stitle
    if (this.amountElements > 0) {
      switch (this.amountElements) {
        case 1:
        this.chartData[0] = ['Time', 'Element1'];
        break;
        case 2:
        this.chartData[0] = ['Time', 'Element 1', 'Element 2'];
        break;
        case 3:
        this.chartData[0] = ['Time', 'Element 1', 'Element 2', 'Element 3', 'Total'];
        case 4:
          this.chartData[0] = ['Time', 'Element 1', 'Element 2', 'Element 3', 'Total'];
          break;
      }
    } else {
      this.chartData[0] = ['Time', 'overal']
    }

    let comp = 1
  this.data = this.data.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
  let j = 1
  for (let i = 0; i<this.data.length; i++) {
    if (this.data[i].timeStamp) {
      let formatTimestamp = new Date(this.data[i].timeStamp)
            formatTimestamp.setSeconds(0,0)
            formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
      let total = 0
      let added = false
      if (this.amountElements > 0) {
        switch (this.amountElements) {
        case 1:
          if (this.data[i].element1) {
            this.chartData[j] = [formatTimestamp, this.data[i].element1 * 12]
            added = true
          } else {
            this.chartData[j] = [new Date(this.data[i].timeStamp), 0]
          }
        break;
        case 2:
        this.chartData[j] = [new Date(this.data[i].timeStamp), this.data[i].element1, this.data[i].element2]
        added = true
        break;
        case 3:
          total = this.data[i].element1 + this.data[i].element2 + this.data[i].element3
        if (this.data[i].element3 && this.data[i].element2 && this.data[i].element1) {
          this.chartData[j] = [new Date(this.data[i].timeStamp), this.data[i].element1, this.data[i].element2, this.data[i].element3, total]
          added = true
        }
        case 4:
        this.chartData[j] = [new Date(this.data[i].timeStamp), this.data[i].element1 * 12, this.data[i].element2 * 12, this.data[i].element3 * 12, total * 12]
        added = true
        break;
      }
      } else {
        this.chartData[j] = [new Date(this.data[i].timeStamp), this.data[i].totalGeneral * 12]
        added = true
      }
      j += 1
    } else {
      comp += 1
    }
    if ((i+comp) == this.data.length -1) {
          this.loaded = true;
    } else if ( (i + 1) == this.data.length)  {
      this.loaded = true;
    }
    
  }

  
  },
}
}
</script>
<style>
.pie-chart {
  width: auto;
    margin: auto;
}
.icon-play-circle{
  font-size: 100px;
    position: absolute;
    top: 75%;
    left: 45%;
    opacity: 1;
    color: blue;
}
.margin-bottom{
  margin-bottom: 100px;
}
.blurred {
  background-color: #eeeeee;
    flex: inherit;
    width: 85%;
    left: 100px;
    height: 30%;
    border-radius: 20px;
    opacity: 0.1;
    top: 65%;
    flex-direction: column;
    float: inline-end;
    position: absolute;
}
</style>