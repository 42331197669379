import state from '../state'
import axios from 'axios'
import api from '../api'
export default class Settings {
    constructor () {
        state
    }

    getSettings () {
        const body = {
            ID: state.selectedID
        }
        return axios.post(api.getSettings, body).then(res => {
            state.Settings = res.data[0]
            if (state.userData.username == 'demo_protech') {
                state.Settings = {
                    electric_price: 0.5,
                    gas_price: 1.5,
                    chemical_price: 0.3,
                }
            }
            return Promise.resolve('done loading settings')
        })
    }

    saveSettings () {
        axios.post(api.saveSettings, state.Settings).then(res => {
        })
    }
}