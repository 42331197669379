<template>
   <v-col cols="15" md="3" >
                  <v-card height="820" class="mx-auto" :color="state.black ? '#1e1e1e' : 'white'" >
                    <v-app-bar
                    dark class="text-h6">Warm up stats
                  </v-app-bar>
                    <v-list :color="state.black ? '#1e1e1e' : 'white'" :dark="state.black">
                      
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-stopwatch</v-icon>
                      <v-list-item-title >Warm up time</v-list-item-title>
                      <t v-if="state.electricUsage">{{ data.warmUpTime}}m</t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-fire</v-icon>
                      <v-list-item-title >Gas used </v-list-item-title>
                      <t  v-if="state.totalTotalGas">{{data.gasWarmingUp}}m³ </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-fire</v-icon>
                      <v-list-item-title >Protection to setpoint usage</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{Math.round(data.protectionToSetpointUsage)}}m³ </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-clock</v-icon>
                      <v-list-item-title >Start time</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{new Date(data.start_date)}} </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-temperature-low</v-icon>
                      <v-list-item-title >Start temperature</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{data.start_temperature}}°C </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-temperature-low</v-icon>
                      <v-list-item-title >Setpoint</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{data.setpoint}}°C </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fas fa-clock</v-icon>
                      <v-list-item-title >Reached setpoint</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{new Date(data.end_date)}} </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" left>fas fa-link</v-icon>
                      <v-list-item-title >Waiting for chain</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{data.waiting}}m </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                    
                  </v-card>
            </v-col>
</template>

<script>
import state from '../../state'
import { getCurrentInstance } from 'vue';
export default {
  data: () => ({

  loaded: false,
  state,
}),
props: {
  title: String,
  data: Object
},
watch: {

},
mounted() {

},

methods: {
},

}
</script>