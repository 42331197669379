<template>
    <div v-if="state.totalTotalGas > 0" >
      <v-card class="mr-5 ml-5 mt-15 rounded-tl-xl rounded-br-xl" :style="state.black ? {background: '#363636'} : {background: '212121'} ">
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >
      <v-badge content="Base" color="black" inline>
        <h3>Gas usage</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
      <v-expand-transition >
            <v-row v-show="tab"  justify="center" class="align-top">
            <v-col cols="12" md="2">
              <v-row  justify="center" class="align-top mt-3">
                <v-item>
                <v-card :color="'orange'" class="d-flex align-center  mb-15 rounded-tl-xl" dark >
                  <v-row >
                    <v-col cols="12" sm="12">
                      <div class="mt-5 ml-3">
                        <v-icon x-large :color="'white'">fas fa-fire</v-icon>
                      </div>
                      <v-list-item three-line class="">
                        <v-list-item-content>

                          <v-row>
                            <v-col md="6">
                              
                              <v-list-item-subtitle :class="'white--text'">Total</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.totalTotalGas)}} m³ </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Avg</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.gasData.avg)}} m³ </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="mb-5">
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Min</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.gasData.min)}} m³ </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Max</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.gasData.max)}} m³ </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>


                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
            </v-row>


              <v-row  justify="center" class="align-top mb-10">
              <v-item>
                <v-card :color="'orange'" class="d-flex align-center rounded-tl-xl" dark height="200">
                  <v-row >
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large :color="'white'">fas fa-fire</v-icon>

                          </div>
                          <v-list-item-subtitle :class="'white--text'">Drying oven</v-list-item-subtitle>
                          <v-list-item-title class="headline mb-1" :class="'white--text'">
                            <strong  v-if="state.loadedAll"> {{Math.floor(state.totalDryingGas)}} m³ </strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
              </v-row>
              <v-row  justify="center" class="align-top mb-10">
              <v-item >
                <v-card :color="'orange'" class="d-flex align-center rounded-tl-xl" dark height="200">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large :color="'white'">fas fa-fire</v-icon>

                          </div>
                          <v-list-item-subtitle :class="'white--text'">Curing oven</v-list-item-subtitle>
                          <v-list-item-title class="headline mb-1" :class="'white--text'">
                            <strong v-if="state.loadedAll"> {{Math.floor(state.totalCuringGas)}} m³ </strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
              </v-row>
            </v-col>
        <v-col cols="12" md="8">    
        <GasChart v-if="state.loadedAll && state.gasData.length > 0" stitle="Drying oven - heating (m³/h)" :data="state.drying_oven"/>
        <GasChart v-if="state.loadedAll && state.gasData.length > 0" stitle="Curing oven - heating (m³/h)" :data="state.curing_oven"/>
        </v-col>

          </v-row>
        </v-expand-transition>
      </v-card>
       
    </div>
    </template>
    
    <script>
    import state from '../../state'
    import { getCurrentInstance } from 'vue';
    import GasChart from './charts/GasChart.vue'
    export default {
      components: {GasChart},
      data: () => ({
        currentTime: 1,
        state,
        tab: true,
      }),
    
      mounted() {

      },
    }
    </script>