import api from './api'

const state = {
  login: false,
  justLoggedIn: false,
  userData: { admin: true, rights: [] },
  api,
  black: false,
  selectedProfile: { name: '' },
  profileLoaded: false,
  firebase: {},
  storage: {},
  customer: null,
  format: '5',
  ElGraph: [{
    timeStamp: 0,
    kWhD: 0,
    kWhM: 0
  }],
  factor: 1,
  firestore: {},
  chain_available: false,
  currentPage: 'home',
  selectedID: '',
  date: {
    select: 'Today'
  },
  dates: [],
  costColumnData: [],
  Settings: {
    bath1_price: 0,
    gas_price: 0,
    electric_price: 0
  },
  curing_oven_stats: {
    warmUpTime: 0,
    gasWarmingUp: 0,
  },
  chain_graph_Linear: {
    chartData: {
      labels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July'
      ],
      datasets: [
        {
          label: 'Transport running (minutes)',
          backgroundColor: 'black',
          data: []
        },
      ]
    }
  },
  chain_graph: {
    chartData: {
      labels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July'
      ],
      datasets: [
        {
          label: 'Transport running (minutes)',
          backgroundColor: 'black',
          data: []
        },
      ]
    }
  },
  totalChain: 0,
  drying_oven_stats: {},
  noAcces: false,
  exportCSV: false,
  loadedSettings: false,
  loadedAll: false,
  lastUpdated: '',
  gasData: [{}],
  curing_oven: [{}],
  drying_oven: [{}],
  chemicalData: [{}],
  columnsAvailable: {
    gasCuring: true,
    gasDrying: true,
    electricElements: false,
    electricOveral: true,
    temperatureCuring: true,
    setpointCuring: true,
    temperatureDrying: true,
    setpointDrying: true,
    speed: false,
    chainGraph: false,
  },
  curing_oven_dashboard: [{}],
  drying_oven_dashboard: [{}],
  showMenu: true,
  hide: true,
  format: 'Week',
  totalCuringGas: 0,
  totalDryingGas: 0,
  totalTotalGas: 0,
  totalCuringEl: 0,
  totalDryingEl: 0,
  selectedWidget: 'av',
  dryingOvenElGraph: [{}],
  curingOvenElGraph: [{}],
  curingElements: 0,
  dryingElements: 0,
  bath1_data: [{}],
  bath2_data: [{}],
  bath3_data: [{}],
  bath4_data: [{}],
  bath5_data: [{}],
  bath6_data: [{}],
  bath7_data: [{}],
  bath8_data: [{}],
  bath9_data: [{}],
  bath1_stats: {},
  bath2_stats: {},
  bath3_stats: {},
}

export default state
