<template>
  <div>
    <v-card width="500" class="mx-auto mt-9">
      <v-card-title>Bestand uploaden</v-card-title>
          <v-row>
      <v-col cols="12">
        <v-col cols="12" class="mt-12">
          <v-text-field label="Filename" />
          <v-text-field label="Type bestand" />
        </v-col>
        <v-file-input
          v-model="myFile"
          outlined
          accept="image/png, image/jpeg"
          placeholder="Click to upload file"
          :disabled="processing"
        >
          <template v-slot:append-outer>
            <v-progress-circular
              v-if="processing"
              color="grey"
              indeterminate
              small
            />
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="12">
        <v-btn class="file-upload-button" @click="fileInput">Upload het bestand</v-btn>
        <v-img :src="fileUrl" contain v-if="fileUrl" max-height="500"></v-img>
      </v-col>
    <v-alert
    v-if="saved"
        outlined
        text
        type="success"
    >Uploaden is gelukt</v-alert>
    </v-row>
    </v-card>
  </div>
</template>

<script>

export default {
  data () {
    return {
      myFile: null,
      processing: false,
      fileURL: null,
      saved: false
    }
  }

}
</script>
