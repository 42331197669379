<template>
    <v-card class="mx-auto pa-12 pb-8 mt-15" elevation="8" max-width="648" width="500" rounded="lg">
      <div class="text-h4 text-center">Period</div>
      <v-divider></v-divider>
      <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="dates[0]" clearable label="Start date" readonly v-bind="attrs" v-on="on" @click:clear="dates[0] = null" class="shrink" />
        </template>
        <v-date-picker v-model="dates[0]" @change="menu1 = false" :dark="state.black"></v-date-picker>
      </v-menu>
      <v-menu v-model="menu2" :close-on-content-click="false" max-width="290">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field :value="dates[1]" clearable label="End date" readonly v-bind="attrs" v-on="on" @click:clear="dates[1] = null" />
        </template>
        <v-date-picker v-model="dates[1]" @change="menu2 = false" :dark="state.black"></v-date-picker>
      </v-menu>
      <v-btn block class="mb-8 mt-8" color="primary" size="large" @click="$emit('download-csv')">Download CSV</v-btn>
    </v-card>
  </template>
  
<script>
  import state from '../../state'
  export default {
    props: {
      dates: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        menu1: false,
        menu2: false,
        state
      };
    },
  };
</script>
  