<template>
    <div id="app" >
    <sidebar @open-modal="showModal = true"/>
       <v-toolbar color="primary" title="saz" flat>
         <title>Saz</title>
          <p class="center-title"> Users overview </p>
          <v-spacer></v-spacer>
       </v-toolbar>
       <v-toolbar color="#f3f3f3"  title="saz" flat>
        <v-select
    v-model="select"
    :items="items"
    return-object
    single-line
    class="select"
    color="primary"
  ></v-select>

  <v-select
    v-model="state.selectedID"
    :items="state.userData.rights"
    return-object
    single-line
    class="select"
    color="primary"
  ></v-select>
          <v-spacer></v-spacer>
       </v-toolbar>
        <profile v-bind="userD"/>
       <v-divider thickness="20px" />
       <v-container>
   <v-item-group mandatory class="mt-n4" v-if="state.profileLoaded"> 
    <v-container>
      <v-row justify="center" class="align-end">
        <v-col
          cols="12"
          md="2"
        >
          <v-item v-slot="{ active, toggle }" >
           <v-card
              :color="active ?  'primary' : 'white'"
              class="d-flex align-center rounded-xl"
              dark
              height="200"
              @click="toggle"

            >
              <v-row>
                <v-col cols="12" sm="10">
                  <v-list-item three-line  class="mt-10">
                    <v-list-item-content>
                      <div class="mb-6">

                          <v-icon  x-large :color="active ? 'white' : 'primary'">fas fa-circle-exclamation</v-icon>

                      </div>
                      <v-list-item-subtitle :class="active ? 'white--text' : 'black--text'">Total standstills</v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1" :class="active ? 'white--text' : 'black--text'">
                        <strong>{{alarmData.totalCritical}}</strong>
                      </v-list-item-title>

                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-card>
          </v-item>
        </v-col>
         <v-col
          cols="12"
          md="2"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? '#00963f' : 'white'"
              class="d-flex align-center rounded-xl"
              dark
              height="200"
              @click="toggle"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-list-item three-line  class="mt-10">
                    <v-list-item-content>
                      <div class="mb-4">

                          <v-icon  x-large :color="active ? 'white' : '#00963f'">fas fa-chart-line</v-icon>

                      </div>
                      <v-list-item-subtitle :class="active ? 'white--text' : 'black--text'">Total produced</v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1" :class="active ? 'white--text' : 'black--text'">
                        <strong>Not available</strong>
                      </v-list-item-title>

                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-card>
          </v-item>
        </v-col>
         <v-col
          cols="12"
          md="2"
        >
          <v-item v-slot="{ active, toggle }">
           <v-card
              :color="active ? '#0069b4' : 'white'"
              class="d-flex align-center rounded-xl"
              dark
              height="200"
              @click="toggle"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-list-item three-line  class="mt-10">
                    <v-list-item-content>
                      <div class="mb-4">

                          <v-icon  x-large :color="active ? 'white' : '#0069b4'">fas fa-stopwatch</v-icon>

                      </div>
                      <v-list-item-subtitle :class="active ? 'white--text' : 'black--text'">Time not producing</v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1" :class="active ? 'white--text' : 'black--text'">
                        <strong>{{alarmData.totalTimeLost}} Minutes</strong>
                      </v-list-item-title>

                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-card>
          </v-item>
        </v-col>
         <v-col
          cols="12"
          md="2"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? '#dc6511' : 'white'"
              class="d-flex align-center rounded-xl"
              dark
              height="200"
              @click="toggle"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-list-item three-line  class="mt-10">
                    <v-list-item-content>
                      <div class="mb-4">

                          <v-icon  x-large :color="active ? 'white' : '#dc6511'">fas fa-fire</v-icon>

                      </div>
                      <v-list-item-subtitle :class="active ? 'white--text' : 'black--text'">Gass usage</v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1" :class="active ? 'white--text' : 'black--text'">
                        <strong>{{gassData.totalGas}} m3</strong>
                      </v-list-item-title>

                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-card>
          </v-item>
        </v-col>
         <v-col
          cols="12"
          md="2"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? '#fbb800' : 'white'"
              class="d-flex align-center rounded-xl"
              dark
              height="200"
              @click="toggle"
            >
              <v-row>
                <v-col cols="12" sm="12">
                  <v-list-item three-line  class="mt-10">
                    <v-list-item-content>
                      <div class="mb-4">

                          <v-icon  x-large :color="active ? 'white' : '#fbb800'">fas fa-bolt  </v-icon>

                      </div>
                      <v-list-item-subtitle :class="active ? 'white--text' : 'black--text'">Electric usage</v-list-item-subtitle>
                      <v-list-item-title class="headline mb-1" :class="active ? 'white--text' : 'black--text'">
                        <strong v-if="electricData">{{electricData.usage}} KW</strong>
                      </v-list-item-title>

                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-card>
          </v-item>
        </v-col>
      </v-row>

    </v-container>
  </v-item-group>
  
  <strong class="center text-center" v-else>loading...</strong>
  <NoRightsModal v-show="showModal" @close-modal="showModal = false" />
</v-container>
              <GChart
                v-if="gassData.a"
                class="graph"
                type="LineChart"
                :data="chartData"
                :options="chartOptions"
              />
</div>
    
    </template>
    <script>
    import Sidebar from '../components/Sidebar.vue'
    import api from '../api'
    import axios from 'axios'
    import Profile from '../components/Profile.vue'
    import state from '../state'
    import { GChart } from 'vue-google-charts/legacy'
    import NoRightsModal from '../components/NoRightsModal.vue'
    export default {
      components: { Sidebar, Profile, GChart, NoRightsModal },
      data: () => ({
        selectItem: 0,
        select: 'Today',
        items: [ 'Today', 'Week', 'Month', 'Last year' ],
        selectedProject: 'SAZ',
        allUsers: null,
        currentTime: 1,
        showModal: false,
        alarmData: {
          totalCritical: 0,
          totalTimeLost: 0
        },
        selectedUser: 'OP',
        gassData: {
          totalGas: 0,
          loaded: false,
          a: false
        },
        electricData: {
          usage: 0
        },
        api,
        userD: {
          name: ''
        },
        chartData: [
          ['Time', 'Gasdata'],
          ['2014', 1000],
          ['2015', 1170],
          ['2016', 660],
          ['2017', 1030]
        ],
        chartOptions: {
          chart: {
            title: 'Company Performance',
            subtitle: 'Sales, Expenses, and Profit: 2014-2017',
          }
        },
        loaded: false,
        state
    }),

    mounted () {
        let data = {
          user: 'Op'
        }

        this.$ga.page({
      page: '/Users',
      title: 'User page',
      location: window.location.href
    });
    },

    watch: {
      'state.selectedProfile' : function (newVal, oldVal) {
        this.gassData.loaded = false
        let dayMath = (24*60*60*1000)
        if (this.select == "Last year") {
          dayMath = dayMath * 365
        } else if (this.select == "Month") {
          dayMath = dayMath * 31
        } else if (this.select == 'Week') {
          dayMath = dayMath * 7
        } else if (this.select == 'Today') {
          dayMath = dayMath * 1
        }
        let timeStamp = Date.now() - dayMath
        this.selectedUser = newVal
        let data = {
          user: newVal,
          timeStampB: timeStamp,
          ID: this.state.selectedID
        }
        axios.post(this.api.getGass, data).then(res => {
            this.gassData = res.data
            this.changeGasOutput()
        })
        axios.post(this.api.getElectric, data).then(res => {
            this.electricData = res.data
            this.changeEletricOutput()
        })
        axios.post(this.api.getAlarm, data).then(res => {
            this.alarmData = res.data
            this.changeAlarmOutput()
        })
      },

      select : function (newVal, oldVal) {
        this.gassData.loaded = false
        let dayMath = (24*60*60*1000)
        if (this.select == "Last year") {
          dayMath = dayMath * 365
        } else if (this.select == "Month") {
          dayMath = dayMath * 31
        } else if (this.select == 'Week') {
          dayMath = dayMath * 7
        } else if (this.select == 'Today') {
          dayMath = dayMath * 1
        }
        let timeStamp = Date.now() - dayMath
        let data = {
          user: this.selectedUser,
          timeStampB: timeStamp,
          ID: this.state.selectedID
        }
        axios.post(this.api.getGass, data).then(res => {
            this.gassData = res.data
            this.changeGasOutput()
        })
        axios.post(this.api.getElectric, data).then(res => {
            this.electricData = res.data
            this.changeEletricOutput()
        })
        axios.post(this.api.getAlarm, data).then(res => {
            this.alarmData = res.data
            this.changeAlarmOutput()
        })
      },

      'state.selectedID' : function (newVal, oldVal) {
        this.gassData.loaded = false
        let dayMath = (24*60*60*1000)
        if (this.select == "Last year") {
          dayMath = dayMath * 365
        } else if (this.select == "Month") {
          dayMath = dayMath * 31
        } else if (this.select == 'Week') {
          dayMath = dayMath * 7
        } else if (this.select == 'Today') {
          dayMath = dayMath * 1
        }
        let timeStamp = Date.now() - dayMath
        let data = {
          user: this.selectedUser,
          timeStampB: timeStamp,
          ID: this.state.selectedID
        }
        axios.post(this.api.getGass, data).then(res => {
            this.gassData = res.data
            this.changeGasOutput()
        })
        axios.post(this.api.getElectric, data).then(res => {
            this.electricData = res.data
            this.changeEletricOutput()
        })
        axios.post(this.api.getAlarm, data).then(res => {
            this.alarmData = res.data
            this.changeAlarmOutput()
        })
      }
    },

    methods: {
      changeGasOutput () {
        this.gassData.loaded = false
        let lastTimeStamp = 0;
        let lastValue = 0;
        let totalGassUnConverted = 0;
        this.gassData.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
        for(let i = 0; i<this.gassData.length; i++) {
          // calculate time between last and this one
          if (lastTimeStamp == 0) {
           lastTimeStamp = this.gassData[i].timeStamp
           lastValue = this.gassData[i].GasData.GasCo
           // this.chartData[i+1] = [this.gassData[i].timeStamp, this.gassData[i].GasData.GasCo]
           //this.chartData[i+1] = [new Date(this.gassData[i].timeStamp).getHours(), this.gassData[i].GasData.GasCo]
          } else {
            let diff = (parseInt(this.gassData[i].timeStamp) - parseInt(lastTimeStamp)) / 1000 / 60 / 60
            totalGassUnConverted += diff * lastValue
            lastTimeStamp = this.gassData[i].timeStamp
            lastValue = this.gassData[i].GasData.GasCo
            this.chartData[i+1] = [new Date(this.gassData[i].timeStamp).getHours(), this.gassData[i].GasData.GasCo]
          }
        }
        this.gassData.totalGas = Math.floor(totalGassUnConverted)
        this.gassData.loaded = true
      },

      changeEletricOutput () {
        let lastTimeStamp = 0;
        let lastValue = 0;
        let totalGassUnConverted = 0;
        if (this.electricData.length > 0) {
          this.electricData.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
        }
        for(let i = 0; i<this.electricData.length; i++) {
          // calculate time between last and this one
          if (lastTimeStamp == 0) {
           lastTimeStamp = this.electricData[i].timeStamp
           lastValue = this.electricData[i].ElectricData.ElectricUsage
          } else {
            let diff = (parseInt(this.electricData[i].timeStamp) - parseInt(lastTimeStamp)) / 1000 / 60 / 60
            totalTimeLost += diff * lastValue
            lastTimeStamp = this.electricData[i].timeStamp
            lastValue = this.electricData[i].ElectricData.ElectricUsage
          }
        }

        this.electricData.usage = Math.floor(totalGassUnConverted)
      },

      changeAlarmOutput () {
        let lastTimeStamp = 0;
        let lastValue = 0;
        let totalTimeLost = 0;
        let totalCritical = 0;
        for(let i = 0; i<this.alarmData.length; i++) {
          // calculate time between last and this one
          if (this.alarmData[i].CriticalAlarm.Critical) {
            totalCritical += 1
          }
          if (this.alarmData.length == 1) {
            let diff = (Date.now() - parseInt(this.alarmData[i].timeStamp)) / 1000 / 60 
            totalTimeLost += diff
          }
          else if (lastTimeStamp == 0) {
           lastTimeStamp = this.alarmData[i].timeStamp
           lastValue = this.alarmData[i].CriticalAlarm.Critical
          } else {
            if (lastValue) {
              let diff = (parseInt(this.alarmData[i].timeStamp) - parseInt(lastTimeStamp)) / 1000 / 60 
              totalTimeLost += diff
            }
            lastValue = this.alarmData[i].CriticalAlarm.Critical
            lastTimeStamp = this.alarmData[i].timeStamp
          }
        }
        // Set final value
        this.alarmData.totalCritical = totalCritical
        this.alarmData.totalTimeLost = Math.floor(totalTimeLost)
      },
      
      widgetChange () {
      }
    }
}
    </script>
    
    <style scoped>
    .border {
        margin-left: 12px;
        margin-right: 12px;
        border-radius: 10%;
        text-decoration: none;
    }
    .space{
      margin-left: -100px;
      margin-right: -100px;
    }

    .select {
      max-width: 200px;
      max-height: 500px;
      margin-left: 20px;
      margin-top: 20px;
      font-size: 20px;
    }
    .top {
      margin-top: 80px;
    }
    
    .v-list-item-group .v-list-item--active{
        color: white !important;
    }
    
    .center-title {
      align-self: center;
      text-align: center;
      font-size: 25px;
      margin-top: 15px;
      color: rgb(203, 203, 203);
    }
    </style>
    