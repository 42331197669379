<template>
    <div>
        <v-card class="mr-5 ml-5 mt-15 rounded-tl-xl rounded-br-xl" :style="state.black ? {background: '#363636'} : {background: '212121'} ">
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >
      <v-badge content="Base" color="black" inline>
        <h3>Electric usage</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
      <v-expand-transition >
            <v-row v-show="tab"  justify="center" class="align-top mb-15">
                <v-col cols="12" md="2">

                <v-row  justify="center" class="align-top mt-3">
                    <v-item class="mb-10">
                        <v-card :color="'yellow'" class="d-flex align-center rounded-tl-xl" dark>
                        <v-row @click="selectWidget('el')">
                            <v-col cols="12" sm="12">
                            <v-list-item three-line class="mt-10">
                                <v-list-item-content>
                                <div class="mb-4">

                                    <v-icon x-large :color="'white'">fas fa-bolt </v-icon>

                                </div>
                                <v-list-item-subtitle :class="'white--text'">Electric
                                    usage</v-list-item-subtitle>
                                
                          <v-row>
                            <v-col md="6">
                              
                              <v-list-item-subtitle :class="'white--text'">Total</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.electricUsage)}} kWh </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Avg</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.electricData.avg )}} kWh </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="mb-5">
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Min</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.electricData.min)}} kWh </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Max</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(state.electricData.max)}} kWh </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>

                                </v-list-item-content>
                            </v-list-item>
                            </v-col>

                        </v-row>

                        </v-card>

                        
                    </v-item>
                </v-row>

                <v-row v-if="state.totalCuringEl > 0 & state.loadedAll"  justify="center" class="align-top">
                    <v-item class="mb-10">
                        <v-card :color="'yellow'" class="d-flex align-center rounded-tl-xl" dark height="200">
                        <v-row @click="selectWidget('el')">
                            <v-col cols="12" sm="12">
                            <v-list-item three-line class="mt-10">
                                <v-list-item-content>
                                <div class="mb-4">

                                    <v-icon x-large :color="'white'">fas fa-bolt </v-icon>

                                </div>
                                <v-list-item-subtitle :class="'white--text'">Drying oven</v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                    <strong v-if="state.loadedAll">{{ state.totalDryingEl }} kWh</strong>
                                </v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                            </v-col>

                        </v-row>

                        </v-card>

                        
                    </v-item>
                </v-row>

                <v-row v-if="state.totalCuringEl > 0 & state.loadedAll"  justify="center" class="align-top">
                    <v-item class="mb-10">
                        <v-card :color="'yellow'" class="d-flex align-center rounded-tl-xl" dark height="200">
                        <v-row @click="selectWidget('el')">
                            <v-col cols="12" sm="12">
                            <v-list-item three-line class="mt-10">
                                <v-list-item-content>
                                <div class="mb-4">

                                    <v-icon x-large :color="'white'">fas fa-bolt </v-icon>

                                </div>
                                <v-list-item-subtitle :class="'white--text'">Curing oven</v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                    <strong v-if="state.loadedAll">{{state.totalCuringEl }} kWh</strong>
                                </v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                            </v-col>

                        </v-row>

                        </v-card>

                        
                    </v-item>
                </v-row>
            </v-col>
        <v-col cols="12" md="8">       
            <Electricity v-if="state.loadedAll && state.generalElGraph.length > 0" stitle="General usage (kW)" :amount-elements="elements" :data="state.generalElGraph"/>
            <Electricity v-if="state.loadedAll && state.dryingElements > 0" stitle="Drying oven - heating (kW)" :amount-elements="state.dryingElements" :data="state.dryingOvenElGraph"/>
            <Electricity v-if="state.loadedAll && state.curingElements > 0" stitle="Curing oven - heating (kW)" :amount-elements="state.curingElements" :data="state.curingOvenElGraph"/>
        </v-col>
        </v-row>
        
        </v-expand-transition>
    </v-card>
    </div>
    </template>
    
    <script>
    import state from '../../state'
    import { getCurrentInstance } from 'vue';
    import Electricity from './charts/Electricity.vue'
    export default {
      components: {Electricity},
      data: () => ({
        currentTime: 1,
        state,
        tab: true,
      }),
    
      mounted() {
      },
    }
    </script>