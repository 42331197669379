<template>
    <div>
        <v-toolbar>
            <h4 class="mt-3">Customer creation panel</h4>
            <v-spacer></v-spacer>
            <v-btn color="green" @click="show = true" class="mr-15">Add customer</v-btn>
            <v-responsive max-width="344" class="mt-5">
                <v-text-field label="search..." />

            </v-responsive>

        </v-toolbar>
        <CustomerDialog v-model="show" @saveCustomer="addCustomer" />
        <v-row justify="center">
            <v-col md="3" v-for="item in customers" v-bind:key="item.vor">
                <v-card @click="SelectCustomer(item)">
                    <v-toolbar>{{ item.company }}</v-toolbar>
                    <v-card-text>                       
                        <div class="text-h6 mb-1">
                            VOR
                        </div>
                        <div class="text-caption">{{ item.vor }}</div>

                        <div class="text-h6 mb-1 mt-1">
                            First name
                        </div>
                        <div class="text-caption">{{ item.firstname }}</div>
                        <div class="text-h6 mb-1 mt-1">
                             Last name
                        </div>
                        <div class="text-caption">{{ item.lastname }}</div>
                        <div class="text-h6 mb-1 mt-1">
                             Email
                        </div>
                        <div class="text-caption">{{ item.email }}</div>
                        <div class="text-h6 mb-1 mt-1">
                             Company
                        </div>
                        <div class="text-caption">{{ item.company }}</div>
                    </v-card-text>

                </v-card>
            </v-col>

        </v-row>
    </div>
</template>

<script>
import state from '../../state';
import axios from 'axios'
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import CustomerDialog from './CustomerDialog'
export default {
    components: { CustomerDialog },
    data: () => ({
        state,
        axios,
        show: false,
        customers: [],
    }),
    props: {
        value: Boolean
    },
    mounted() {
        this.RefreshCustomers()
    },
    computed: {
    },
    watch: {
    },
    methods: {
        addCustomer(customer) {
            this.customers.push(customer)
            addDoc(collection(this.state.firestore, "websiteUsers"),
                customer
            )
        },
        RefreshCustomers() {
            const q = query(collection(this.state.firestore, 'websiteUsers'), where('offerte', '==', true))

            getDocs(q).then((res) => {
                res.forEach((doc) => {
                    this.customers.push(doc.data())
                })
                console.log(this.customers)
            })
        },
        SelectCustomer (customer) {
            this.state.customer = customer
        }
    }

}
</script>