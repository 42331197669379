<template>
    <transition name="modal-fade">
      <div class="modal-overlay" @click="$emit('close-modal')">
        <div class="modalf" >
            <span icon :ripple="false" class="envelope" @click="findName">
                <v-icon size="80" class="envelope" >fas fa-database</v-icon>
            </span>
            
          
          <h6>No data found</h6>
          <p>It seems like you selected a period in which, there is no data available </p>
          <button class="margeleft">Go back</button>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    methods: {
        findName () {
            document.location = "mailto:h.arling@indufinish.com?subject=Upgrading indufinish connect"
        }
    },
    mounted() {

    },
  }
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }

  .margeleft {
    background-color: #be1a20;
    
  }
  .margeleft:hover {
    background-color: #0069b4;
  }

  .stdButton {
    background-color: #be1a20;
  }

  .stdButton:hover {
    background-color: #0069b4;
  }
  
  .modalf {
    text-align: center;
    background-color: white;
    height: 300px;
    width: 500px;
    margin-top: 10%;
    padding: 60px 0;
    border-radius: 20px;
  }
  .envelope {
    color: #be1a20;
  }

  .envelope:hover{
    color: #0069b4;
    pointer-events: all;
  }
  .close {
    margin: 10% 0 0 16px;
    cursor: pointer;
  }
  
  .close-img {
    width: 25px;
  }
  
  .check {
    width: 150px;
  }
  
  h6 {
    font-weight: 500;
    font-size: 28px;
    margin: 20px 0;
  }
  
  p {
    /* font-weight: 500; */
    font-size: 16px;
    margin: 20px 0;
  }
  
  button {
    background-color: #be1a20;
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    border-radius: 16px;
    margin-top: 50px;
  }
  
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }
  
  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity 0.5s ease;
  }
  </style>
  