import state from '../../state'
export default class costDataHandler {
  constructor() {
    state
  }

  prepareData() {
    state.costData = [{}]
    state.costData.chartDataFlu = [{}]
    state.costData.totalCost = 0
    let maxGas = 0
    let minGas = 0
    let avgGas = 0
    let indexesAboveZero = 0
    let costObject = [
    ]

    let costs = []
    let timestamps = []
    let type = []
    let j = 0
    for (let i = 0; i < state.electricData.length; i++) {
      if (state.electricData[i].cost >= 0) {
        costs[j] = state.electricData[i].cost
        timestamps[j] = state.electricData[i].timeStamp
        type[j] = 'el'
        j += 1
      }
    }
    for (let i = 0; i < state.chemicalData.length; i++) {
      if (state.chemicalData[i].cost >= 0) {
        costs[j] = state.chemicalData[i].cost
        timestamps[j] = state.chemicalData[i].timeStamp
        type[j] = 'ch'
        j += 1
      }
    }
    let d = 0
    for (let i = 0; i < timestamps.length; i++) {
      if (timestamps[i] != 0 && timestamps[i] != null) {
        costObject[d] = {
          costs: costs[i],
          timeStamps: timestamps[i],
          types: type[i]
        }
        d += 1
      }
    }

    state.costData.chartData = []
    state.costData.chartData[0] = ['Time', 'Total cost', 'Gas', 'Chemical', 'Electric']
    state.costData.chartDataFlu[0] = ['Time', 'Total cost', 'Gas', 'Chemical', 'Electric']

    costObject = costObject.sort((a, b) => (a.timeStamps > b.timeStamps) ? 1 : -1)
    let totalCost = 0
    let totalGas = 0
    let totalEl = 0
    let totalChemical = 0
    state.totalTotalGas = 0
    state.totalDryingGas = 0
    state.totalCuringGas = 0

    for (let i = 0; i < state.electricData.length; i++) {
      let currentCost = 0
      let currentGas = 0
      let currentEl = 0
      let currentChemical = 0
      if (state.chemicalData[i].cost) {
        totalChemical += state.chemicalData[i].cost
        currentChemical = state.chemicalData[i].cost
        totalCost += state.chemicalData[i].cost
        currentCost += state.chemicalData[i].cost
      }
      totalCost += state.electricData[i].cost
      currentCost += state.electricData[i].cost

      totalEl += state.electricData[i].cost
      currentEl = state.electricData[i].cost

      if (state.gasData[i]) {
        if (state.gasData[i].curing_oven) {
          if (state.gasData[i].curing_oven.burner1_usage) {
            currentGas += state.gasData[i].curing_oven.burner1_usage * state.Settings.gas_price
            totalGas += state.gasData[i].curing_oven.burner1_usage * state.Settings.gas_price
            state.totalTotalGas += parseFloat(state.gasData[i].curing_oven.burner1_usage.toString().replace('+', ''))

            state.totalCuringGas += parseFloat(state.gasData[i].curing_oven.burner1_usage.toString().replace('+', ''))
            totalCost += state.gasData[i].curing_oven.burner1_usage * state.Settings.gas_price
            currentCost += state.gasData[i].curing_oven.burner1_usage * state.Settings.gas_price
          }

        }

        if (state.gasData[i].drying_oven) {
          if (state.gasData[i].drying_oven.burner1_usage) {
            currentGas += state.gasData[i].drying_oven.burner1_usage * state.Settings.gas_price
            totalGas += state.gasData[i].drying_oven.burner1_usage * state.Settings.gas_price

            state.totalTotalGas += parseFloat(state.gasData[i].drying_oven.burner1_usage.toString().replace('+', ''))

            state.totalDryingGas += parseFloat(state.gasData[i].drying_oven.burner1_usage.toString().replace('+', ''))
            totalCost += state.gasData[i].drying_oven.burner1_usage * state.Settings.gas_price
            currentCost += state.gasData[i].drying_oven.burner1_usage * state.Settings.gas_price
          }
        }

        if (currentGas > 0) {
          if (currentGas < minGas | minGas == 0) {
            minGas = currentGas * state.factor
          }

          if (currentGas > maxGas | maxGas == 0) {
            maxGas = currentGas * state.factor
          }

          avgGas += currentGas * state.factor
          indexesAboveZero += 1
        }
      }
      let formatTimestamp = new Date(state.electricData[i].timeStamp)
      formatTimestamp.setSeconds(0, 0)
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      };

      formatTimestamp = formatTimestamp.toLocaleString([], { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' });
      state.costData.chartData[i + 1] = [formatTimestamp, totalCost, totalGas, totalChemical, totalEl]
      state.costData.chartDataFlu[i + 1] = [formatTimestamp, currentCost * state.factor, currentGas * state.factor, currentChemical * state.factor, currentEl * state.factor]
    }



    state.costData.totalCost = Math.floor(totalCost)
    state.costData.loaded = true
    state.gasData.min = minGas
    state.gasData.max = maxGas
    state.gasData.avg = avgGas / indexesAboveZero
    // when the cost is 0, add this to the graph
    if (state.costData.totalCost == 0) {
      state.costData.chartData[1] = [new Date(Date.now()), 0, 0, 0, 0]
    }

  }


}