<template>
    <div>
      <v-card class="mr-5 ml-5 rounded-tl-xl rounded-br-xl mt-15" :style="state.black ? {background: '#363636'} : {background: '212121'} ">
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >

      <v-badge content="base" color="black" inline>
        <h3>{{stitle}}  insight</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
    <v-expand-transition>
          <v-item-group v-show="tab" class="mb-15">
              <v-row justify="center" class="align-end ml-5">
          <v-col v-if="temperature > 0" cols="12" md="2" align-self="start"> 
              <v-item >
                <v-card color="primary" class="d-flex align-center rounded-xl" dark height="200">
                  <v-row >
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large color="white">fas fa-temperature-half</v-icon>

                          </div>
                          <v-list-item-subtitle class="white--text">Temperature</v-list-item-subtitle>
                          <v-list-item-title class="headline mb-1" color="white--text">
                            <strong>{{ temperature }}	°C</strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
            </v-col>
            <TemperatureGraph v-if="data[0].heating" :data="data" :title="title" />
          </v-row>
          
            <v-row justify="center" class="align-end ml-5">
            <v-col v-if="conductivity > 0" cols="12" md="2" align-self="start">
              <v-item>
                <v-card color="#fbb800" class="d-flex align-center rounded-xl" dark>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large color="white">fas fa-flask</v-icon>

                          </div>
                          <v-list-item-subtitle class="white--text">Conductivity</v-list-item-subtitle>
                          <v-row>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Avg</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ conductivity }} μS/cm</strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="mb-5">
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Min</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ conductivityStats.min }} μS/cm</strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Max</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ conductivityStats.max }} μS/cm</strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                  </v-row>
                </v-card>
              </v-item>
            </v-col>
            <ConductivityGraph v-if="data[0].conductivity" :data="data" :title="title" />
            <v-col v-if="chemicals > 0" cols="12" md="2" align-self="start">
              <v-item v-slot="{ active, toggle }">
                <v-card :color="state.selectedWidget == 'ch' ? 'white' : '#b70d7f'" class="d-flex align-center rounded-xl" dark
                  @click="toggle">
                  <v-row @click="selectWidget('ch')">
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large :color="state.selectedWidget == 'ch' ? '#b70d7f' : 'white'">fas fa-droplet </v-icon>

                          </div>
                          <v-list-item-subtitle :class="state.selectedWidget == 'ch' ? 'black--text' : 'white--text'">Chemical
                            usage</v-list-item-subtitle>
                          <v-list-item-title class="headline mb-1" :class="state.selectedWidget == 'ch' ? 'black--text' : 'white--text'">
                            <strong>{{ chemicals }} Ml</strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                  </v-row>

                </v-card>
              </v-item>
            </v-col>
            </v-row>

          <v-row justify="center" class="align-end ml-5">
            <v-col v-if="ph > 0 && state.format == 'Day'" cols="12" md="2" align-self="start">
              <v-item>
                <v-card color="#dc6511" class="d-flex align-center rounded-xl" dark >
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large color="white">fas fa-eye-dropper</v-icon>

                          </div>
                          <v-list-item-subtitle class="white--text">pH</v-list-item-subtitle>
                          <v-row>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Avg</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(phStats.avg * 10 )/10}} pH </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>
                          <v-row class="mb-5">
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Min</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(phStats.min * 10) / 10}} pH </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                            <v-col md="6">
                              <v-list-item-subtitle :class="'white--text'">Max</v-list-item-subtitle>
                              <v-row>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                  <strong v-if="state.loadedAll"> {{ Math.floor(phStats.max * 10)/10}} pH </strong>
                                </v-list-item-title>
                              </v-row>
                            </v-col>
                          </v-row>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
            </v-col>
            
            <PHGraph v-if="data[0].ph" :data="data" :title="title" />
          </v-row>
        </v-item-group>
        </v-expand-transition>
        </v-card>
    </div>
</template>

<script>
import api from '../api'
import state from '../state'
import ConductivityGraph from './charts/ConductivityGraph.vue'
import TemperatureGraph from './charts/TemperatureGraph.vue'
import PHGraph from './charts/PHGraph.vue'
export default {
  components: { ConductivityGraph, TemperatureGraph, PHGraph },
  data: () => ({
    currentTime: 1,
    selectedUser: 'OP',
    api,
    tab: true,
    userD: {
      name: ''
    },
    loaded: false,
    state,
    title: 'hoi',
    conductivity: 0,
    chemicals: 0,
    ph: 0,
    phStats: {
      min: 0,
      max: 0,
      avg: 0,
      aboveZeroIndex: 0
    },
    conductivityStats: {
      min: 0,
      max: 0,
      avg: 0,
      aboveZeroIndex: 0
    },
    temperature: 0,
  }),
  props: {
        stitle: String,
        data: Array
    },

  created() {
    this.title = this.stitle
    this.dataConverter()
  },

  watch: {
    'data.length' (val) {
    }
  },

  methods: {
    selectWidget (widget) {
        this.state.selectedWidget = widget
    },

    dataConverter () {
      let totalIndexes = 0
      let totalCombinedph = 0
      let totalCombinedus = 0
      let totalCombinedtemp = 0
      let totaladdedDosing= 0
      let avgTemp = 0
      let avgTempC = 0

      for (let i =0; i<this.data.length; i++) {
        let indexData = this.data[i]
        if (indexData.conductivity) {
          let newCond = parseInt(indexData.conductivity.conductivity.replace('+', ''))
          totalCombinedus += newCond

          if (this.conductivityStats.max == 0 | newCond > this.conductivityStats.max) {
              this.conductivityStats.max = newCond       
          }

          if (this.conductivityStats.min == 0 | newCond < this.conductivityStats.min) {
            this.conductivityStats.min = newCond
          }

          avgTempC += newCond
          this.conductivityStats.aboveZeroIndex += 1
        }
        
        if (indexData.ph) {
          let newph = indexData.ph.ph
          totalCombinedph += newph
          
          if (this.phStats.max == 0 | newph > this.phStats.max) {
            this.phStats.max = newph
          }

          if (this.phStats.min == 0 | newph < this.phStats.min) {
            this.phStats.min = newph
          }

          avgTemp += newph 
          console.log(newph)
          this.phStats.aboveZeroIndex += 1
        
        }

        if (indexData.heating) {
          let newTemp = indexData.heating.temperature
          totalCombinedtemp += parseInt(newTemp.replace('+', ''))
        }

        totalIndexes += 1

      }

      this.phStats.avg = avgTemp / this.phStats.aboveZeroIndex
      this.chemicals = 0
      this.ph = Math.round((totalCombinedph / totalIndexes)*10)/10
      this.conductivity = Math.round(totalCombinedus / totalIndexes)
      this.temperature = Math.round(totalCombinedtemp / totalIndexes)
    }
  }
}
</script>
<style>
.border {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 10%;
  text-decoration: none;
}

.space {
  margin-left: -100px;
  margin-right: -100px;
}

.select {
  max-width: 200px;
  max-height: 500px;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 20px;
}

.top {
  margin-top: 80px;
}

.v-list-item-group .v-list-item--active {
  color: white !important;
}

.center-title {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(203, 203, 203);
}

.center-title2 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(255, 255, 255);
}

.center-title3 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(0, 0, 0);
}

.cost-graph {
  margin-left:20px;
}

.pie-chart {
  width: auto;
    margin: auto;
}
</style>