<template>
    <div id="app" class="flex justify-content-center">
      <sidebar @open-modal="showModal = true" />
      <WindowDocumentation/>

      <v-row v-if="false" justify="center">
        <v-col cols="12" lg="2" >
          <v-card
            color="#0068b2"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  P&ID
                </v-card-title>

                <v-card-subtitle>P&ID of the line</v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ms-2"
                    size="small"
                    variant="outlined"
                  >
                    Download
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar
                class="ma-3"
                rounded="0"
                size="125"
              >
                <v-icon size="80">fas fa-file</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" lg="2">
          <v-card
            color="#0068b2"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  Control manual
                </v-card-title>

                <v-card-subtitle>Control description of the software</v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ms-2"
                    size="small"
                    variant="outlined"
                  >
                    Download
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar
                class="ma-3"
                rounded="0"
                size="125"
              >
                <v-icon size="80">fas fa-file</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" lg="2">
          <v-card
            color="#0068b2"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="text-h5">
                  Line layout
                </v-card-title>

                <v-card-subtitle>Layout of the powdercoat line</v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    class="ms-2"
                    size="small"
                    variant="outlined"
                  >
                    Download
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar
                class="ma-3"
                rounded="0"
                size="125"
              >
                <v-icon size="80">fas fa-file</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-col>

        
      </v-row>
      <h1 justify="center" class="text-center" v-else>In development</h1>
      <SelectBar/>
    </div>
  </template>
    
  <script>
  import Sidebar from '../components/Sidebar.vue'
  import api from '../api'
  import state from '../state'
  import SelectBar from '../components/SelectBar.vue'
  import tracking from '../script/tracking/tracking'
    import WindowDocumentation from '../components/WindowDocumentation.vue'
  export default {
    components: { Sidebar, SelectBar, WindowDocumentation},
    data: () => ({
      selectItem: 0,
      trackingjs: new tracking(),
      select: 'Today',
      dates: [],
      items: ['Today', 'Week', 'Month', 'Last year'],
      selectedProject: 'SAZ',
      allUsers: null,
      currentTime: 1,
      selectedUser: 'OP',
      api,
      userD: {
        name: ''
      },
      loaded: false,
      loadedTest: false,
      state,
      preparedJsonObject: [{}],
    }),
    mounted() {
      this.trackingjs.trackAction('', 'Documentation', "")
      this.state.currentPage = "Documentation"
      //this.getApiCall();
      this.loadedTest = true
    },
    methods: {
        save () {
            this.state.Settings.ID = this.state.selectedID
            localStorage.setItem('black', this.black)
            this.Settings.saveSettings()
        },
    } 
  }
  </script>
    
  <style scoped>
  .border {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10%;
    text-decoration: none;
  }
  
  .space {
    margin-left: -100px;
    margin-right: -100px;
  }
  
  .select {
    max-width: 200px;
    max-height: 500px;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .top {
    margin-top: 80px;
  }
  
  .v-list-item-group .v-list-item--active {
    color: white !important;
  }
  
  .center-title {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(203, 203, 203);
  }
  
  .center-title2 {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(131, 127, 127);
  }
  
  .cost-graph {
    margin-left:20px;
  }
  
  .pie-chart {
    width: auto;
      margin: auto;
  }
  </style>
    