const apiLinks = {
  login: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/login/',
  allUsers: 'https://us-central1-indufinish-28c27.cloudfunctions.net/indufinish/getAllUsers',
  saveUser: 'https://us-central1-indufinish-28c27.cloudfunctions.net/Indufinish/saveUser/',
  getUsers: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getUsers/',
  getGass: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getGasUser/',
  getElectric: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getElectricityUser/',
  getAlarm: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getAlarmUser/',
  getChemical: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getChemicalUser/',
  getSettings: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getSettings/',
  saveSettings: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/setSettings/',
  getLabs: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getLab/',
  saveLabs: 'https://us-central1-indusotrage.cloudfunctions.net/Indufinish/setLab/',
  action: "https://us-central1-indusotrage.cloudfunctions.net/Indufinish/action/",
  page: "https://us-central1-indusotrage.cloudfunctions.net/Indufinish/page/",
  checkLicense: "https://us-central1-indusotrage.cloudfunctions.net/Indufinish/checkLicense/",
  getFiles: "https://us-central1-indusotrage.cloudfunctions.net/Indufinish/getFiles/",
  test: "http://127.0.0.1:5001/indusotrage/us-central1/Indufinish/transformDaysTest/"

  
}

export default apiLinks
