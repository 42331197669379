<template>
  <div id="app">
    <SelectBar/>
    <sidebar @open-modal="showModal = true" />
    <WindowData/>
          <GeneralData/>
      <NoRightsModal v-show="showModal" @close-modal="showModal = false" />
  </div>
</template>
  
<script>
import Sidebar from '../components/Sidebar.vue'
import api from '../api'
import state from '../state'
import NoRightsModal from '../components/NoRightsModal.vue'
import GeneralData from '../components/GeneralData.vue'
import SelectBar from '../components/SelectBar.vue'
import WindowData from '../components/Homepage/WindowData.vue'
import NoData from '../components/Homepage/NoData.vue'
export default {
  components: { Sidebar, NoRightsModal, GeneralData, SelectBar, WindowData},
  data: () => ({
    selectItem: 0,
    datePicker: false,
    elements: 0,
    elements_drying: 1,
    elements_curing: 3,
    select: 'Today',
    dates: [],
    items: ['Today', 'Week', 'Month', 'Last year'],
    selectedProject: 'SAZ',
    allUsers: null,
    currentTime: 1,
    showModal: false,
    dates: [],
    selectedUser: 'OP',
    api,
    loaded: false,
    state,
  }),

  mounted() {
    this.$ga.page({
      page: '/Home',
      title: 'Home page',
      location: window.location.href
    });

    this.state.currentPage = "Home"
  },
  watch: {

  },
  methods: {
    resetAll() {
      state.bath1_data = []
      state.bath2_data = []
      state.bath3_data = []
      state.bath4_data = []
      state.bath5_data = []
      state.bath6_data = []
      state.bath7_data = []
      state.bath8_data = []
      state.bath9_data = []
    }
  }
}
</script>
  
<style scoped>
.border {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 10%;
  text-decoration: none;
}

.space {
  margin-left: -100px;
  margin-right: -100px;
}

.select {
  max-width: 200px;
  max-height: 500px;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 20px;
}

.top {
  margin-top: 80px;
}

.v-list-item-group .v-list-item--active {
  color: white !important;
}

.center-title {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(203, 203, 203);
}

.center-title2 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(131, 127, 127);
}

.cost-graph {
  margin-left:20px;
}

.pie-chart {
  width: auto;
    margin: auto;
}
</style>
  