<template>
  <div v-if="scrollpx > 500 || state.currentPage != 'Home' && state.currentPage != 'Indufinish labs' || !state.hide">
    <v-item-group v-if="false">
      <v-app-bar :color="state.black ? '#434242' : 'white'" class="fixed-bar scrollTop" permanent app>
        <v-icon @click="state.showMenu = !state.showMenu" class="mr-15" left>fa fa-bars</v-icon>
        <v-toolbar-title :class="state.black ? 'white--text h2' : 'black--text h2'">{{ state.currentPage }}</v-toolbar-title>

        <v-select
          v-if="shouldShowSelect"
          :dark="state.black"
          v-model="state.selectedID"
          :items="state.userData.rights"
          return-object
          single-line
          class="select mt-4 mr-5"
          color="primary"
        ></v-select>

        <v-row justify="end">
          <v-col md="2" align-self="center">
            <v-btn
              v-if="state.loadedAll && state.currentPage != 'Indufinish labs'"
              color="#97c11f"
              rounded
              class="white--text mr-5 ml-5 text-left"
              @click="load"
            >
              <v-icon class="mr-3" left>fa fa-refresh</v-icon>
              <h4 class="mr-10 mt-2 text-caption">Refresh</h4>
            </v-btn>
            <v-progress-circular v-else color="green" indeterminate></v-progress-circular>
          </v-col>
          <v-col md="1" align-self="center">
            <div v-if="state.loadedAll" class="mb-5 mr-5 text-caption font-italic" height="60" density="compact">
              <p :class="state.black ? 'white--text mt-4' : 'black--text mt-4'">
                <v-icon size="10" class="mr-1" color="#97c11f">fa fa-circle</v-icon>
                Last updated at: {{ state.lastUpdated }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-overlay v-if="!state.loadedAll"></v-overlay>
    </v-item-group>

    <div v-if="state.currentPage != 'Indufinish labs'">
      <v-row justify="center">
        <v-col md="3" class="bottom">
          <v-card height="120" color="#7a7a7a" elevation="20" class="rounded-xl">
            <v-col v-if="!state.loadedAll" class="text-center">
              <v-icon color="pink" left>fa fa-refresh</v-icon>
              <h5>Loading {{ state.currentPage }}</h5>
            </v-col>
            <v-row v-else justify="center">
              <v-col md="auto">
                <v-btn rounded elevation="4" @click="changeFactor(12, 'Day')" :color="state.factor == 12 ? 'primary' : 'grey'">Days</v-btn>
              </v-col>
              <v-col md="auto">
                <v-btn @click="changeFactor(1, 'Week')" :color="state.factor == 1 ? 'primary' : 'grey'" elevation="4" rounded>Week</v-btn>
              </v-col>
              <v-col md="auto" v-if="false">
                <v-btn rounded elevation="4">Month</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="state.loadedAll" justify="center">
              <v-col md="1">
                <v-icon size="20" @click="oneBack">fas fa-arrow-left</v-icon>
              </v-col>
              <v-col md="6">
                <v-menu v-model="menu1" v-if="state.currentPage != 'Indufinish labs'" :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      rounded
                      :value="dateFormat"
                      depressed
                      label="Start date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                      class="shrink ml-2"
                      solo
                      dense
                      hide-details
                    >
                      <v-icon slot="prepend-inner" class="mr-2" color="primary">fas fa-calendar</v-icon>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="state.dates[0]" @change="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col md="1">
                <v-icon size="20" @click="oneForward">fas fa-arrow-right</v-icon>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import api from '../api';
import state from '../state';
import dataHandler from '../script/Calculations/dataHandler';

export default {
  data() {
    return {
      state,
      dHandler: new dataHandler(),
      datePicker: false,
      menu1: false,
      dateFormat: '',
      snackbar: false,
      scrollpx: 0,
    };
  },
  computed: {
    shouldShowSelect() {
      return (
        state.userData.rights.length > 1 &&
        state.currentPage !== 'Settings' &&
        state.currentPage !== 'CSV' &&
        state.currentPage !== 'Documentation'
      );
    },
  },
  watch: {
    'state.dates': function (newVal, oldVal) {
      this.snackbar = true;
      this.changeDate();
    },
    'state.factor': function (val) {
      this.changeDate();
    },
    'state.format': function (val) {
      this.dHandler.getData();
    },
  },
  mounted() {
    this.snackbar = true;
    if (this.state.dates.length === 0) {
      let date = new Date();
      date.setHours(0, 0, 0, 0);
      this.state.dates[0] = date.toISOString().substr(0, 10);
    }
    this.changeDate();
    window.addEventListener('scroll', this.handleScroll);
    this.datePicker = true;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    oneBack() {
      let date = new Date(this.state.dates[0]);
      if (this.state.format === 'Day') {
        date.setDate(date.getDate() - 1);
      } else if (this.state.format === 'Week') {
        date.setDate(date.getDate() - 7);
      }
      this.state.dates[0] = date.toISOString().split('T')[0];
      this.load();
      this.changeDate();
    },
    oneForward() {
      let date = new Date(this.state.dates[0]);
      if (this.state.format === 'Day') {
        date.setDate(date.getDate() + 1);
      } else if (this.state.format === 'Week') {
        date.setDate(date.getDate() + 7);
      }
      this.state.dates[0] = date.toISOString().split('T')[0];
      this.load();
      this.changeDate();
    },
    changeFactor(value, time) {
      this.state.factor = value;
      this.state.format = time;
    },
    getWeek(date) {
      date = new Date(date);
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
      const week1 = new Date(date.getFullYear(), 0, 4);
      return 1 + Math.round(((date - week1) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
    },
    changeDate() {
      if (this.state.factor === 12) {
        this.dateFormat = new Date(this.state.dates[0]);
      } else if (this.state.factor === 1) {
        this.dateFormat = 'Week nr: ' + this.getWeek(this.state.dates[0]);
      }
    },
    load() {
      this.dHandler.getData();
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
      if (window.scrollY > 500) {
        this.state.hide = false;
      }
    },
  },
};
</script>

<style>
.bottom {
  position: fixed;
  bottom: 60px;
  z-index: 1000;
}
</style>
