import api from '../../api'
import state from '../../state'
import axios from 'axios'
import chemicalDataHandler from './chemicalDataHandler'
import electricDataHandler from './electricDataHandler'
import costDataHandler from './costDataHandler'
import checkLicense from '../tracking/checkLicense'
import GasAnalysing from '../Calculations/GasAnalysing'
import { collection, addDoc, getDocs, where, and, getFirestore, query } from "firebase/firestore";
import { Timestamp } from 'firebase/firestore'
export default class dataHandler {
  constructor() {
    state
    api
    this.chemicalDataHandler = new chemicalDataHandler()
    this.elHandler = new electricDataHandler()
    this.costHandler = new costDataHandler()
    this.checkLicensejs = new checkLicense()
    this.gasAnalyzer = new GasAnalysing()
  }

  async getData() {
    state.loadedAll = false
    state.chain_graph = {
      chartData: {
        labels: [

        ],
        datasets: [
          {
            label: 'Transport running (minutes)',
            backgroundColor: 'black',
            borderColor: 'black',
            data: []
          },
        ]
      }
    }

    state.chain_graph_Linear = {
      chartData: {
        labels: [

        ],
        datasets: [
          {
            label: 'Transport running (minutes)',
            backgroundColor: 'orange',
            borderColor: 'orange',
            data: []
          },
        ]
      }
    }
    var date = new Date();
    state.totalChain = 0
    state.lastUpdated = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
    state.electricUsage = 0
    state.loadedAll = false
    state.chain_available = false
    state.curingElements = 0
    state.dryingElements = 0
    state.totalCuringEl = 0
    state.totalDryingEl = 0
    if (state.dates.length > 1) {
      let temp1 = state.dates[0]
      let temp2 = state.dates[1]

      if (temp1 > temp2) {
        state.dates[0] = temp2
        state.dates[1] = temp1
      }
    }
    // getting date
    let dayMath = state.dates[0]
    let nowDate = new Date(state.dates[0])

    dayMath = nowDate - 1

    // Prepare object
    let timeStamp = dayMath
    let data = {
      user: "*",
      timeStampB: timeStamp,
      ID: state.selectedID
    }

    if (state.dates.length > 1) {
      let endDate = new Date(state.dates[1])
      data.timeStampE = endDate.setHours(24) - 1
    }
    let list = []
    //return axios.post(api.getElectric, data).then(res => {
    //this.convertData(res.data)
    //return Promise.resolve('done')
    //})

    let q = query(collection(state.firestore, 'day_data'), where('ID', '==', state.selectedID))
    if (state.format == 'Day') {
      let endDate = new Date(state.dates[0])
      endDate = endDate.setHours(24, 0, 0)
      let beginDate = new Date(state.dates[0])
      beginDate = beginDate.setHours(0, 0, 0)
      q = query(collection(state.firestore, 'Electricity'), where('ID', '==', state.selectedID), where('timeStamp', '>=', beginDate - 1), where('timeStamp', '<=', endDate - 1))

      getDocs(q).then((res) => {
        res.forEach((doc) => {
          list.push(doc.data())
        })
        this.convertData(list)
      })

      
    } else if (state.format == 'Week') {
      let beginDate = new Date(this.convertToWeek(state.dates[0]))
      let endDate = new Date(beginDate)
      endDate.setDate(endDate.getDate() + 7)
      endDate = new Date(endDate) - 1
      beginDate = new Date(beginDate) - 1
      q = query(collection(state.firestore, 'day_data'), where('ID', '==', state.selectedID), where('timeStamp', '>', beginDate),where('timeStamp', '<', endDate))

      getDocs(q).then((res) => {
        res.forEach((doc) => {
          list.push(doc.data())
        })

        this.convertData(list)
      })
    }

  }

  convertToWeek(data) {
    let timeStamp = new Date(data)
    const weekMap = {
      1: 0, // → monday
      2: 1, // → tuesday
      3: 2, // → wednesday
      4: 3, // → thursday
      5: 4, // → friday
      6: 5, // → saturday
      0: 6, // → sunday
  };
    timeStamp.setDate(timeStamp.getDate() - weekMap[timeStamp.getDay()])
    return timeStamp
  }
  convertData(data) {

    // Converting electrical data
    state.curing_oven_dashboard = [{}]
    state.drying_oven_dashboard = [{}]
    let amount = 0;
    let totalChainMinutes = 0;
    let chemicalData = [{}]
    state.columnsAvailable = {
      gasCuring: false,
      gasDrying: false,
      electricElements: false,
      electricOveral: false,
      temperatureCuring: false,
      setpointCuring: false,
      temperatureDrying: false,
      setpointDrying: false,
      chainGraph: false, 
    }
    let electricData = [{}]
    let gasData = [{}]
    let curing_oven = [{}]
    let drying_oven = [{}]
    state.totalTotalGas = 0
    let ovensDashboardCount = 0
    state.curingOvenElGraph = [{}]
    state.chemicalUsage = 0
    // resetting bath data
    state.bath1_data = [{}]
    state.bath2_data = [{}]
    state.bath3_data = [{}]
    state.bath4_data = [{}]
    state.bath5_data = [{}]
    state.bath6_data = [{}]
    state.bath7_data = [{}]
    state.bath8_data = [{}]
    state.bath9_data = [{}]
    let j = -1
    let lastValue = 0
    data = data.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
    for (let i = 0; i < data.length; i++) {

      let indexData = data[i]
      if (amount > indexData.timeStamp - 10000) {
      } else {
        if (state.format == 'Week') {
          let stamp = new Date(indexData.timeStamp)
          stamp = stamp.setHours(0,0,0)
          indexData.timeStamp = stamp
        }
        j += 1
        //(data)
        // Converting electrical data
        electricData[j] = {}
        gasData[j] = {}
        if (indexData.overal_energy) {
          state.columnsAvailable.electricOveral = true
          electricData[j].hasElectrical = true
          electricData[j].overal_electric = indexData.overal_energy
        }

        // curing_oven
        if (indexData.curing_oven) {
          state.curing_oven_dashboard[ovensDashboardCount] = {}
          state.curing_oven_dashboard[ovensDashboardCount].timeStamp = indexData.timeStamp
          electricData[j].curing_oven = []
          gasData[j].curing_oven = []
          curing_oven[j] = {}
          if (typeof indexData.curing_oven.burner1_usage !== 'undefined') {
            state.columnsAvailable.gasCuring = true
            gasData[j].curing_oven.timeStamp = indexData.timeStamp
            gasData[j].curing_oven.burner1_usage = indexData.curing_oven.burner1_usage
            curing_oven[j].timeStamp = indexData.timeStamp
            curing_oven[j].burner1_usage = indexData.curing_oven.burner1_usage
          }


          if (indexData.curing_oven.temperature) {
            state.columnsAvailable.temperatureCuring = true
            state.columnsAvailable.setpointCuring = true
            if (Math.floor(parseFloat(indexData.curing_oven.temperature.toString().replace('+', ''))) / 10 < 220) {
              state.curing_oven_dashboard[ovensDashboardCount].temperature = Math.floor(parseFloat(indexData.curing_oven.temperature.toString().replace('+', ''))) / 10
            } else {
              state.curing_oven_dashboard[ovensDashboardCount].temperature = 0
            }
            
            


            state.curing_oven_dashboard[ovensDashboardCount].setpoint = parseInt(indexData.curing_oven.setpoint.toString().replace('+', ''))
          }
          if (indexData.curing_oven.burner1_usage) {
            state.curing_oven_dashboard[ovensDashboardCount].gas = parseFloat(indexData.curing_oven.burner1_usage.toString().replace('+', ''))
          }
          if (indexData.curing_oven.speed) {
            state.columnsAvailable.speed = true

            state.curing_oven_dashboard[ovensDashboardCount].speed = parseFloat(indexData.curing_oven.speed.toString().replace('+', ''))

          }

          // Element 1 usage
          if (indexData.curing_oven.el1_usage) {
            electricData[j].hasCuringEl1 = true;

            electricData[j].curing_oven.El1Usage = indexData.curing_oven.el1_usage
            state.totalCuringEl += parseInt(indexData.curing_oven.el1_usage.toString().replace('+', 0))
            electricData[j].curing_oven.totalEl1 = 0;
            state.curingElements = 1
          }
          // Element 2 usage
          if (indexData.curing_oven.el2_usage) {
            electricData[j].hasCuringEl2 = true;
            electricData[j].curing_oven.El2Usage = indexData.curing_oven.el2_usage
            state.totalCuringEl += parseInt(indexData.curing_oven.el2_usage.toString().replace('+', 0))
            electricData[j].curing_oven.totalEl2 = 0;
            state.curingElements = 2
          }
          // Element 3 usage
          if (indexData.curing_oven.el3_usage) {
            electricData[j].hasCuringEl3 = true;
            if (parseInt(indexData.curing_oven.el3_usage.toString().replace('+', 0)) > parseInt(indexData.curing_oven.el2_usage.toString().replace('+', 0)) + 10) {
              electricData[j].curing_oven.El3Usage = indexData.curing_oven.el2_usage

            } else {
              electricData[j].curing_oven.El3Usage = indexData.curing_oven.el3_usage
            }
            state.totalCuringEl += parseInt(electricData[j].curing_oven.El3Usage.toString().replace('+', 0))
            electricData[j].curing_oven.totalEl3 = 0;
            state.curingElements = 3
          }
          // Element 4 usage
          if (indexData.curing_oven.el4_usage) {
            electricData[j].hasCuringEl4 = true;
            electricData[j].curing_oven.El4Usage = indexData.curing_oven.el4_usage
            electricData[j].curing_oven.totalEl4 = 0;
            state.curingElements = 4
          }

          // Element 5 usage
          if (indexData.curing_oven.el5_usage) {
            electricData[j].hasCuringEl5 = true;
            electricData[j].curing_oven.El5Usage = indexData.curing_oven.el5_usage
            electricData[j].curing_oven.totalEl5 = 0;
          }

          // Element 6 usage
          if (indexData.curing_oven.el6_usage) {
            electricData[j].hasCuringEl6 = true;
            electricData[j].curing_oven.El6Usage = indexData.curing_oven.el6_usage
            electricData[j].curing_oven.totalEl6 = 0;
          }
        }
        electricData[j].timeStamp = indexData.timeStamp
        // drying oven
        if (indexData.drying_oven) {
          drying_oven[j] = {}
          electricData[j].drying_oven = []
          gasData[j].drying_oven = []
          state.drying_oven_dashboard[ovensDashboardCount] = {}
          state.drying_oven_dashboard[ovensDashboardCount].timeStamp = indexData.timeStamp
          if (indexData.drying_oven.burner1_usage) {
            state.drying_oven_dashboard[ovensDashboardCount].gas = parseFloat(indexData.drying_oven.burner1_usage.toString().replace('+', ''))
          }
          if (indexData.drying_oven.temperature) {
            state.columnsAvailable.setpointDrying = true
            state.columnsAvailable.temperatureDrying = true

            state.drying_oven_dashboard[ovensDashboardCount].setpoint = parseInt(indexData.drying_oven.setpoint.toString().replace('+', ''))
            if (Math.floor(parseFloat(indexData.drying_oven.temperature.toString().replace('+', ''))) / 10 < 270) {
              state.drying_oven_dashboard[ovensDashboardCount].temperature = Math.floor(parseFloat(indexData.drying_oven.temperature.toString().replace('+', ''))) / 10
            } else {
              state.drying_oven_dashboard[ovensDashboardCount].temperature = 0
            }
            if (indexData.drying_oven.speed) {
              state.columnsAvailable.speed = true



              state.drying_oven_dashboard[ovensDashboardCount].speed = parseFloat(indexData.drying_oven.speed.toString().replace('+', ''))
            }
          }
          ovensDashboardCount += 1
          if (indexData.drying_oven.burner1_usage) {
            state.columnsAvailable.gasDrying = true
            gasData[j].drying_oven.timeStamp = indexData.timeStamp
            gasData[j].drying_oven.burner1_usage = indexData.drying_oven.burner1_usage
            drying_oven[j].timeStamp = indexData.timeStamp
            drying_oven[j].burner1_usage = indexData.drying_oven.burner1_usage
          }
          // Element 1 usage
          if (indexData.drying_oven.el1_usage) {

            electricData[j].hasDryingEl1 = true;
            electricData[j].drying_oven.El1Usage = indexData.drying_oven.el1_usage
            state.totalDryingEl += parseInt(indexData.drying_oven.el1_usage.toString().replace('+', 0))

            electricData[j].drying_oven.totalEl1 = 0;
            state.dryingElements = 1
          }
          // Element 2 usage
          if (indexData.drying_oven.el2_usage) {
            electricData[j].hasCuringEl2 = true;
            electricData[j].drying_oven.El2Usage = indexData.drying_oven.el2_usage
            electricData[j].drying_oven.totalEl2 = 0;
            state.dryingElements = 2
          }
          // Element 3 usage
          if (indexData.drying_oven.el3_usage) {
            electricData[j].hasCuringEl3 = true;
            electricData[j].drying_oven.El3Usage = indexData.drying_oven.el3_usage
            electricData[j].drying_oven.totalEl3 = 0;
            state.dryingElements = 3
          }
          // Element 4 usage
          if (indexData.drying_oven.el4_usage) {
            electricData[j].hasCuringEl4 = true;
            electricData[j].drying_oven.El4Usage = indexData.drying_oven.el4_usage
            electricData[j].drying_oven.totalEl4 = 0;
            state.dryingElements = 4
          }
        }

        chemicalData[j] = {}
        // Converting chemical data
        if (indexData.pre_threatment) {
          // Bath 1
          if (indexData.pre_threatment.bath_1) {
            state.bath1_data[j] = {}
            state.bath1_data[j].timeStamp = indexData.timeStamp
            // pump 1
            if (indexData.pre_threatment.bath_1.dosing_pump_1) {
              chemicalData[j].b1HasDosingPump1 = true
              chemicalData[j].b1PulsesPump1 = indexData.pre_threatment.bath_1.dosing_pump_1
              //state.bath1_data[j].timeStamp = indexData.timeStamp
              //state.bath1_data[j].amount = parseInt(indexData.pre_threatment.bath_1.dosing_pump_1.toString().replace('+', ''))
              state.bath1_data[j].dosing = {}
              state.bath1_data[j].dosing.timeStamp = indexData.timeStamp
              state.bath1_data[j].dosing.amount1 = parseInt(indexData.pre_threatment.bath_1.dosing_pump_1.toString().replace('+', ''))
            }
            // pump 2
            if (indexData.pre_threatment.bath_1.dosing_pump_2) {
              chemicalData[j].b1HasDosingPump2 = true
              chemicalData[j].b1PulsesPump2 = indexData.pre_threatment.bath_1.dosing_pump_2
              state.bath1_data[j].dosing.timeStamp = indexData.timeStamp
              state.bath1_data[j].dosing.amount2 = parseInt(indexData.pre_threatment.bath_1.dosing_pump_2.toString().replace('+', ''))
            }

            if (indexData.pre_threatment.bath_1.Temperature) {
              state.bath1_data[j].heating = {}
              state.bath1_data[j].heating.temperature = indexData.pre_threatment.bath_1.Temperature
            }

            if (indexData.pre_threatment.bath_1.Filling) {
              state.bath1_data[j].filling = indexData.pre_threatment.bath_1.Filling
            }

            if (indexData.pre_threatment.bath_1.Spraying) {
              state.bath1_data[j].spraying = indexData.pre_threatment.bath_1.Spraying
            }

            if (indexData.pre_threatment.bath_1.PH) {
              state.bath1_data[j].ph = {}
              state.bath1_data[j].ph.ph = parseFloat(indexData.pre_threatment.bath_1.PH.toString().replace('+', ''))
            }

            if (indexData.pre_threatment.bath_1.Conductivity) {
              state.bath1_data[j].conductivity = {}
              state.bath1_data[j].conductivity.conductivity = indexData.pre_threatment.bath_1.Conductivity
            }
          }

          // Bath 2
          if (indexData.pre_threatment.bath_2) {
            if (indexData.pre_threatment.bath_2) {
              state.bath2_data[j] = {}
              state.bath2_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_2.dosing_pump_1) {
                chemicalData[j].b2HasDosingPump1 = true
                chemicalData[j].b2PulsesPump1 = indexData.pre_threatment.bath_2.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_2.dosing_pump_2) {
                chemicalData[j].b2HasDosingPump1 = true
                chemicalData[j].b2PulsesPump1 = indexData.pre_threatment.bath_2.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_2.Filling) {
                state.bath2_data[j].filling = indexData.pre_threatment.bath_2.Filling
              }

              if (indexData.pre_threatment.bath_2.Spraying) {
                state.bath2_data[j].spraying = indexData.pre_threatment.bath_2.Spraying
              }

              if (indexData.pre_threatment.bath_2.Temperature) {
                state.bath2_data[j].heating = {}
                state.bath2_data[j].heating.temperature = indexData.pre_threatment.bath_2.Temperature
              }

              if (indexData.pre_threatment.bath_2.PH) {
                state.bath2_data[j].ph = {}
                state.bath2_data[j].ph.ph = indexData.pre_threatment.bath_2.PH
              }

              if (indexData.pre_threatment.bath_2.Conductivity) {
                state.bath2_data[j].conductivity = {}
                state.bath2_data[j].conductivity.conductivity = indexData.pre_threatment.bath_2.Conductivity
              }
            }
          }

          // Bath 3
          if (indexData.pre_threatment.bath_3) {
            if (indexData.pre_threatment.bath_3) {
              state.bath3_data[j] = {}
              state.bath3_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_3.dosing_pump_1) {
                chemicalData[j].b3HasDosingPump1 = true
                chemicalData[j].b3PulsesPump1 = indexData.pre_threatment.bath_3.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_3.dosing_pump_2) {
                chemicalData[j].b3HasDosingPump1 = true
                chemicalData[j].b3PulsesPump1 = indexData.pre_threatment.bath_3.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_3.Temperature) {
                state.bath3_data[j].heating = {}
                state.bath3_data[j].heating.temperature = indexData.pre_threatment.bath_3.Temperature
              }

              if (indexData.pre_threatment.bath_3.Spraying) {
                state.bath3_data[j].spraying = indexData.pre_threatment.bath_3.Spraying
              }

              if (indexData.pre_threatment.bath_3.PH) {
                state.bath3_data[j].ph = {}
                state.bath3_data[j].ph.ph = indexData.pre_threatment.bath_3.PH
              }

              if (indexData.pre_threatment.bath_3.Conductivity) {
                state.bath3_data[j].conductivity = {}
                state.bath3_data[j].conductivity.conductivity = indexData.pre_threatment.bath_3.Conductivity
              }
            }
          }

          // Bath 4
          if (indexData.pre_threatment.bath_4) {
            if (indexData.pre_threatment.bath_4) {
              state.bath4_data[j] = {}
              state.bath4_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_4.dosing_pump_1) {
                state.bath4_data[j].dosing = {}
                state.bath4_data[j].dosing.timeStamp = indexData.timeStamp
                chemicalData[j].b4PulsesPump1 = indexData.pre_threatment.bath_4.dosing_pump_1
                chemicalData[j].b4HasDosingPump1 = true

                state.bath4_data[j].dosing.amount1 = parseInt(indexData.pre_threatment.bath_4.dosing_pump_1.toString().replace('+', ''))
              }
              // pump 2
              if (indexData.pre_threatment.bath_4.dosing_pump_2) {
                chemicalData[j].b4HasDosingPump1 = true
                chemicalData[j].b4PulsesPump1 = indexData.pre_threatment.bath_4.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_4.Temperature) {
                state.bath4_data[j].heating = {}
                state.bath4_data[j].heating.temperature = indexData.pre_threatment.bath_4.Temperature
              }

              if (indexData.pre_threatment.bath_4.PH) {
                state.bath4_data[j].ph = {}
                state.bath4_data[j].ph.ph = parseFloat(indexData.pre_threatment.bath_4.PH.toString().replace('+', ''))
              }

              if (indexData.pre_threatment.bath_4.Conductivity) {
                state.bath4_data[j].conductivity = {}
                state.bath4_data[j].conductivity.conductivity = indexData.pre_threatment.bath_4.Conductivity
              }
            }
          }

          // Bath 5
          if (indexData.pre_threatment.bath_5) {
            if (indexData.pre_threatment.bath_5) {
              state.bath5_data[j] = {}
              state.bath5_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_5.dosing_pump_1) {
                chemicalData[j].b5HasDosingPump1 = true
                chemicalData[j].b5PulsesPump1 = indexData.pre_threatment.bath_5.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_5.dosing_pump_2) {
                chemicalData[j].b5HasDosingPump1 = true
                chemicalData[j].b5PulsesPump1 = indexData.pre_threatment.bath_5.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_5.Temperature) {
                state.bath5_data[j].heating = {}
                state.bath5_data[j].heating.temperature = indexData.pre_threatment.bath_5.Temperature
              }

              if (indexData.pre_threatment.bath_5.PH) {
                state.bath5_data[j].ph = {}
                state.bath5_data[j].ph.ph = indexData.pre_threatment.bath_5.PH
              }

              if (indexData.pre_threatment.bath_5.Conductivity) {
                state.bath5_data[j].conductivity = {}
                state.bath5_data[j].conductivity.conductivity = indexData.pre_threatment.bath_5.Conductivity
              }
            }
          }

          // Bath 6
          if (indexData.pre_threatment.bath_6) {
            if (indexData.pre_threatment.bath_6) {
              state.bath6_data[j] = {}
              state.bath6_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_6.dosing_pump_1) {
                chemicalData[j].b6HasDosingPump1 = true
                chemicalData[j].b6PulsesPump1 = indexData.pre_threatment.bath_6.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_6.dosing_pump_2) {
                chemicalData[j].b6HasDosingPump1 = true
                chemicalData[j].b6PulsesPump1 = indexData.pre_threatment.bath_6.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_6.Temperature) {
                state.bath6_data[j].heating = {}
                state.bath6_data[j].heating.temperature = indexData.pre_threatment.bath_6.Temperature
              }

              if (indexData.pre_threatment.bath_6.PH) {
                state.bath6_data[j].ph = {}
                state.bath6_data[j].ph.ph = indexData.pre_threatment.bath_6.PH
              }

              if (indexData.pre_threatment.bath_6.Conductivity) {
                state.bath6_data[j].conductivity = {}
                state.bath6_data[j].conductivity.conductivity = indexData.pre_threatment.bath_6.Conductivity
              }
            }
          }

          // Bath 7
          if (indexData.pre_threatment.bath_7) {
            if (indexData.pre_threatment.bath_7) {
              state.bath7_data[j] = {}
              state.bath7_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_7.dosing_pump_1) {
                chemicalData[j].b7HasDosingPump1 = true
                chemicalData[j].b7PulsesPump1 = indexData.pre_threatment.bath_7.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_7.dosing_pump_2) {
                chemicalData[j].b7HasDosingPump1 = true
                chemicalData[j].b7PulsesPump1 = indexData.pre_threatment.bath_7.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_7.Temperature) {
                state.bath7_data[j].heating = {}
                state.bath7_data[j].heating.temperature = indexData.pre_threatment.bath_7.Temperature
              }

              if (indexData.pre_threatment.bath_7.PH) {
                state.bath7_data[j].ph = {}
                state.bath7_data[j].ph.ph = indexData.pre_threatment.bath_7.PH
              }

              if (indexData.pre_threatment.bath_7.Conductivity) {
                state.bath7_data[j].conductivity = {}
                state.bath7_data[j].conductivity.conductivity = indexData.pre_threatment.bath_7.Conductivity
              }
            }
          }

          // Bath 8
          if (indexData.pre_threatment.bath_8) {
            if (indexData.pre_threatment.bath_8) {
              state.bath8_data[j] = {}
              state.bath8_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_8.dosing_pump_1) {
                chemicalData[j].b8HasDosingPump1 = true
                chemicalData[j].b8PulsesPump1 = indexData.pre_threatment.bath_8.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_8.dosing_pump_2) {
                chemicalData[j].b8HasDosingPump1 = true
                chemicalData[j].b8PulsesPump1 = indexData.pre_threatment.bath_8.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_8.Temperature) {
                state.bath8_data[j].heating = {}
                state.bath8_data[j].heating.temperature = indexData.pre_threatment.bath_8.Temperature
              }

              if (indexData.pre_threatment.bath_8.PH) {
                state.bath8_data[j].ph = {}
                state.bath8_data[j].ph.ph = indexData.pre_threatment.bath_8.PH
              }

              if (indexData.pre_threatment.bath_8.Conductivity) {
                state.bath8_data[j].conductivity = {}
                state.bath8_data[j].conductivity.conductivity = indexData.pre_threatment.bath_8.Conductivity
              }
            }
          }

          // Bath 9
          if (indexData.bath_9) {
            if (indexData.pre_threatment.bath_9) {
              state.bath9_data[j] = {}
              state.bath9_data[j].timeStamp = indexData.timeStamp
              // pump 1
              if (indexData.pre_threatment.bath_9.dosing_pump_1) {
                chemicalData[j].b9HasDosingPump1 = true
                chemicalData[j].b98PulsesPump1 = indexData.pre_threatment.bath_9.dosing_pump_1
              }
              // pump 2
              if (indexData.pre_threatment.bath_9.dosing_pump_2) {
                chemicalData[j].b9HasDosingPump1 = true
                chemicalData[j].b9PulsesPump1 = indexData.pre_threatment.bath_9.dosing_pump_2
              }

              if (indexData.pre_threatment.bath_9.Temperature) {
                state.bath9_data[j].heating = {}
                state.bath9_data[j].heating.temperature = indexData.pre_threatment.bath_9.Temperature
              }

              if (indexData.pre_threatment.bath_9.PH) {
                state.bath9_data[j].ph = {}
                state.bath9_data[j].ph.ph = indexData.pre_threatment.bath_9.PH
              }

              if (indexData.pre_threatment.bath_9.Conductivity) {
                state.bath9_data[j].conductivity = {}
                state.bath9_data[j].conductivity.conductivity = indexData.pre_threatment.bath_9.Conductivity
              }
            }
          }
        }
        if (indexData.chain) {
          state.chain_available = true
          state.columnsAvailable.chainGraph = true
          let formatTimestamp = new Date(indexData.timeStamp)
          formatTimestamp.setSeconds(0, 0)
          formatTimestamp = formatTimestamp.toLocaleString([], { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' });
          state.chain_graph.chartData.labels.push(formatTimestamp)
          state.chain_graph_Linear.chartData.labels.push(formatTimestamp)

          state.totalChain += parseInt(Math.round((indexData.chain.chain_on.toString().replace('+', 0) * 10) / 60)) / 10
          state.chain_graph.chartData.datasets[0].data.push(parseInt(Math.round((indexData.chain.chain_on.toString().replace('+', 0) * 10) / 60)) / 10)
          lastValue = Math.round((indexData.chain.chain_on.toString().replace('+', 0) * 10) / 60) / 10
          state.chain_graph_Linear.chartData.datasets[0].data.push(state.totalChain)



        }
        chemicalData[j].timeStamp = indexData.timeStamp
        electricData[j].timeStamp = indexData.timeStamp

      }
      amount = indexData.timeStamp
    }
    state.electricData = electricData
    state.chemicalData = chemicalData
    state.ElGraph = electricData
    state.gasData = gasData
    state.curing_oven = curing_oven
    state.drying_oven = drying_oven
    this.chemicalDataHandler.prepareData()
    this.elHandler.prepareData()
    this.costHandler.prepareData()
    this.gasAnalyzer.gasToSetpoint()
    state.costColumnData = [
      ['Year', 'Visitations', { role: 'style' }],
      ['Gas', state.totalTotalGas, 'color: gray'],
      ['Water', 0, 'color: #76A7FA'],
      ['Chemical', state.chemicalUsage * (state.Settings.chemical_price / 1000), 'opacity: 0.2'],
      ['Electric', state.electricUsage * state.Settings.electric_price, 'stroke-color: #7c7c7c; stroke-width: 4; color: #7c7c7c'],
    ]
    state.loadedAll = true
  }
}