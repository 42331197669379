import state from '../../state'
export default class chemicalDataHandler {
    constructor () {
        state
    }

    prepareData () {
        let total = 0;
        let acces = true;
        for (let k = 0; k<state.userData.rights.length; k++) {
          if (state.userData.rights[0] == "LENTINK" || state.selectedID == "LENTINK") {
            acces = false;
          }
        }
        if (acces) {

            for (let i = 0; i < state.chemicalData.length; i++) {
              state.chemicalData[i].cost = 0
              state.chemicalData[i].costTimeStamp = 0
              state.chemicalData[i].type = 0
              let totalPulses = 0;
              // bath 1
              if(state.chemicalData[i].b1HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b1PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b1HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b1PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 2
              if(state.chemicalData[i].b2HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b2PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b2HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b2PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 3
              if(state.chemicalData[i].b3HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b3PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b3HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b3PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 4
              if(state.chemicalData[i].b4HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b4PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b4HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b4PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 5
              if(state.chemicalData[i].b5HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b5PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b5HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b5PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 6
              if(state.chemicalData[i].b6HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b6PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b6HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b6PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 7
              if(state.chemicalData[i].b7HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b7PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b7HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b7PulsesPump2.toString().replace('+', ''))
              }
  
              // bath 8
              if(state.chemicalData[i].b8HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b8PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b8HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b8PulsesPump2.toString().replace('+', ''))
              }
  
              
              // bath 9
              if(state.chemicalData[i].b9HasDosingPump1) {
                totalPulses += parseInt(state.chemicalData[i].b9PulsesPump1.toString().replace('+', ''))
              }
              if(state.chemicalData[i].b9HasDosingPump2) {
                totalPulses += parseInt(state.chemicalData[i].b9PulsesPump2.toString().replace('+', ''))
              }
  
              // calculate time between last and this one
              state.chemicalData[i].cost = (totalPulses * 0.93 * state.Settings.chemical_price) / 1000
              state.chemicalData[i].costTimeStamp = state.chemicalData[i].timeStamp
              state.chemicalData[i].type = 'ch'
              total += totalPulses
            }
            
            state.chemicalUsage = Math.floor(total * 0.93)
        }
    }

}