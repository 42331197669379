<template>
    <div>
        <v-card outlined color="transparent" class="contentf2 " width="2500">
            <v-row justify="center" v-if="lists.length > 0">
                <v-col md="2" v-for="list in lists" v-bind:key="list">
                    <v-card height="700">
                        <v-app-bar>
                            <v-app-bar-title>{{ list }}</v-app-bar-title>
                        </v-app-bar>
                        <div class="scroll">
                            <v-item-group v-for="item in items" v-bind:key="item.url">
                                <v-row justify="center" class="mt-15" width="" v-if="item.type == list">
                                    <v-col cols="12" md="10">
                                        <v-card>
                                            <v-card-text>
                                                <div>name</div>

                                                <p class="text-h4 font-weight-black">{{ item.name }}</p>
                                                <p class="text-medium-emphasis">{{ new Date(item.timestamp).toLocaleString() }}
                                                </p>
                                                <div class="text-medium-emphasis">
                                                    {{ item.description }}
                                                </div>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn color="teal-accent-4" variant="text"
                                                    @click="gotoLink(item)">Download</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </div>
                    </v-card>
                </v-col>
            </v-row>



        </v-card>
        
    </div>
</template>

<script>

import api from '../../api'
import state from '../../state'
import axios from 'axios'
import { collection, addDoc, getDocs, where, getFirestore, query } from "firebase/firestore";


export default {
    data: () => ({
        api,
        loaded: false,
        state,
        items: [],
        mail: false,
        accounts: [],
        lists: [],
        axios,
        upload: false,
        caption: '',
        img1: '',
        test: false,
        imageData: null,
        firebase: {},
        storage: {}
    }),
    props: {
        update: Boolean,
    },
    mounted() {
        this.refresh()

    },
    watch: {
        update(val) {
            this.refresh()
        }
    },
    methods: {
        refresh() {
            this.lists = []
            const q = query(collection(this.state.firestore, 'Files'), where('id', '==', this.state.customer.vor))

            getDocs(q).then((res) => {
                res.forEach((doc) => {
                    this.items.push(doc.data())
                    console.log(this.items)
                    this.lists = []

                    for (let i = 0; i < this.items.length; i++) {
                        let found = false
                        for (let j = 0; j < this.lists.length; j++) {
                            if (this.lists[j] == this.items[i].type) {
                                found = true
                            }
                        }
                        this.lists.push(this.items[i].type)
                    }
                    console.log(this.lists)
                })
            })

        },
        gotoLink(item) {
            window.open(item.url, '_blank')
        },

    }

}
</script>