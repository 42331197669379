<template>
  <div id="app" class="flex justify-content-center">
    <sidebar @open-modal="showModal = true" />
    <NoData v-show="state.loadedAll && state.costData.totalCost === 0" @close-modal="state.loadedAll = false" />
    <SelectBar />
    <WindowCSV />
    <v-row justify="center" v-if="state.userData.license === 'premium'">
      <v-col cols="12" md="4" class="ml-15">
        <SettingsCard :dates="state.dates" @download-csv="downloadCSV" />
      </v-col>
      <v-col cols="12" md="4" class="ml-15">
        <ColumnsCard :columns-available="state.columnsAvailable" :columns-enabled.sync="columnsEnabled" />
      </v-col>
    </v-row>
    <DownloadCSV />
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import api from '../api';
import state from '../state';
import dataHandler from '../script/Calculations/dataHandler';
import Settings from '../script/Settings';
import DownloadCSV from '../components/CSV/DownloadCSV.vue';
import SelectBar from '../components/SelectBar.vue';
import NoData from '../components/Homepage/NoData.vue';
import tracking from '../script/tracking/tracking';
import WindowCSV from '../components/WindowCSV.vue';
import SettingsCard from '../components/CSV/SettingsCard.vue';
import ColumnsCard from '../components/CSV/ColumnsCard.vue';

export default {
  components: { Sidebar, SelectBar, DownloadCSV, NoData, WindowCSV, SettingsCard, ColumnsCard },
  data: () => ({
    selectItem: 0,
    trackingjs: new tracking(),
    select: 'Today',
    dates: [],
    items: ['Today', 'Week', 'Month', 'Last year'],
    selectedProject: 'SAZ',
    allUsers: null,
    currentTime: 1,
    dHandler: new dataHandler(),
    Settings: new Settings(),
    selectedUser: 'OP',
    api,
    columnsEnabled: {
      gasCuring: false,
      gasDrying: false,
      electricElements: false,
      electricOveral: false,
      temperatureCuring: false,
      setpointCuring: false,
      temperatureDrying: false,
      setpointDrying: false,
      speed: false,
    },
    userD: {
      name: '',
    },
    loaded: false,
    loadedTest: false,
    state,
    preparedJsonObject: [{}],
  }),
  watch: {
    'state.dates'(val) {
      this.columnsEnabled = {
        gasCuring: false,
        gasDrying: false,
        electricElements: false,
        electricOveral: false,
        temperatureCuring: false,
        setpointCuring: false,
        temperatureDrying: false,
        setpointDrying: false,
        chainGraph: false,
      };
      this.dHandler.getData();
    },
    'state.Settings'(val) {},
    columnsEnabled: {
      deep: true,
      handler(newVal) {
        console.log('Parent Component: columnsEnabled updated', newVal);
      },
    },
  },
  mounted() {
    this.trackingjs.trackAction('', 'CSV', '');
    this.state.currentPage = 'CSV';
    this.loadedTest = true;
  },
  methods: {
    save() {
      this.state.Settings.ID = this.state.selectedID;
      localStorage.setItem('black', this.black);
      this.Settings.saveSettings();
    },
    convertCSV(data) {
      const headers = Object.keys(data[0]);
      const rows = data.map((obj) => headers.map((header) => obj[header]));
      const headerRow = headers.join(',');
      const csvRows = [headerRow, ...rows.map((row) => row.join(','))];
      return csvRows.join('\n');
    },
    prepareJsonObject() {
      let jsonObject = [];
      for (let i = 0; i < state.curing_oven_dashboard.length; i++) {
        let row = { date: new Date(state.curing_oven[i].timeStamp) };
        if (this.columnsEnabled.electricOveral) {
          row.electric_overal = this.state.generalElGraph[i] ? this.state.generalElGraph[i].totalGeneral : 0;
        }
        if (this.columnsEnabled.gasCuring) {
          row.gas_curing_x100 = Math.round(this.state.curing_oven_dashboard[i].gas * 100);
        }
        if (this.columnsEnabled.temperatureCuring) {
          row.temperature_curing = Math.round(this.state.curing_oven_dashboard[i].temperature);
        }
        if (this.columnsEnabled.setpointCuring) {
          row.setpoint_curing = this.state.curing_oven_dashboard[i].setpoint;
        }
        if (this.columnsEnabled.gasDrying) {
          row.gas_drying_x100 = Math.round(this.state.drying_oven_dashboard[i].gas * 100);
        }
        if (this.columnsEnabled.temperatureDrying) {
          row.temperature_drying = Math.round(this.state.drying_oven_dashboard[i].temperature);
        }
        if (this.columnsEnabled.setpointDrying) {
          row.setpoint_drying = this.state.drying_oven_dashboard[i].setpoint;
        }
        if (this.columnsEnabled.speed) {
          row.chain_speed = Math.round(this.state.drying_oven_dashboard[i].speed * 10) / 10;
        }
        if (this.columnsEnabled.chainGraph) {
          row.chain_graph_data = this.state.chain_graph.chartData.datasets[0].data[i] || 0;
        }
        jsonObject.push(row);
      }
      return jsonObject;
    },
    resetColumnsEnabled() {
      this.columnsEnabled = {
        gasCuring: false,
        gasDrying: false,
        electricElements: false,
        electricOveral: false,
        temperatureCuring: false,
        setpointCuring: false,
        temperatureDrying: false,
        setpointDrying: false,
        speed: false,
        chainGraph: false,
      };
    },
    downloadCSV() {
      console.log('downloadCSV()');
      const csvContent = this.convertCSV(this.prepareJsonObject());
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      this.trackingjs.trackAction(this.columnsEnabled, 'CSV', 'get csv file');
      link.href = url;
      link.setAttribute('download', 'indufinish_connect.csv');
      link.click();
    },
  },
};
</script>

<style scoped>
.border {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 10%;
  text-decoration: none;
}
.space {
  margin-left: -100px;
  margin-right: -100px;
}
.select {
  max-width: 200px;
  max-height: 500px;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 20px;
}
.top {
  margin-top: 80px;
}
.v-list-item-group .v-list-item--active {
  color: white !important;
}
.center-title {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(203, 203, 203);
}
.center-title2 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(131, 127, 127);
}
.cost-graph {
  margin-left: 20px;
}
.pie-chart {
  width: auto;
  margin: auto;
}
</style>
