<template>
<div v-if="!state.loadedAll">
    <v-row justify="center">
    <v-col cols="12" md="2">
      <v-skeleton-loader
        class="mx-auto border"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="2">
      <v-skeleton-loader
        class="mx-auto border"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="2">
      <v-skeleton-loader
        class="mx-auto border"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="2">
      <v-skeleton-loader
        class="mx-auto border"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-col>

    <v-col cols="12" md="2">
      <v-skeleton-loader
        class="mx-auto border"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
    </div>
</template>

<script>
import state from '../state'
export default {
    data: () => ({
        state,
})
}
</script>