 <template>
    <div>
      <v-card class="mr-5 ml-5 mt-15 rounded-tl-xl rounded-br-xl" :style="state.black ? {background: '#363636'} : {background: '212121'} ">
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >

      <v-badge content="Base" color="black" inline>
        <h3>Chemical usage</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
      <v-expand-transition>
            <v-row v-show="tab"  justify="center" class="align-top">
          <v-col v-if="state.chemicalData[0].b1HasDosingPump1" cols="12" md="2">
              <v-item v-slot="{ active, toggle }">
                <v-card :color="'purple'" class="d-flex align-center rounded-tl-xl" dark height="200"
                  @click="toggle">
                  <v-row @click="selectWidget('ch')">
                    <v-col cols="12" sm="12">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-4">

                            <v-icon x-large :color="'white'">fas fa-droplet </v-icon>

                          </div>
                          <v-list-item-subtitle :class="'white--text'">Chemicalusage</v-list-item-subtitle>
                          <v-list-item-title class="headline mb-1" :class="'white--text'">
                            <strong v-if="state.loadedAll">{{ Math.floor(state.chemicalUsage / 100) / 10 }} L</strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
            </v-col>
        <ChemicalGraph v-if="state.chemicalData[0].b1HasDosingPump1" title="Bath 1 dosing pump 1" :data="state.bath1_data" />
        <v-row justify="center">
        <v-col md="2"> 
        </v-col>
        <ChemicalGraph v-if="state.chemicalData[0].b1HasDosingPump2" :secondPump="true" title="Bath 1 dosing pump 2" :data="state.bath1_data" />
        </v-row>
        <v-row justify="center">
        <v-col md="2"> 
        </v-col>
        <ChemicalGraph v-if="state.chemicalData[0].b3HasDosingPump1" title="Bath 3" :data="state.bath3_data" />
        </v-row>
        <v-row justify="center">
          <v-col md="2"> 
          </v-col>
          <ChemicalGraph v-if="state.chemicalData[0].b4HasDosingPump1" title="Bath 4 dosing pump 1" :data="state.bath4_data" />
      </v-row>
        <ChemicalGraph v-if="state.chemicalData[0].b5HasDosingPump1" title="Bath 5" :data="state.bath5_data" />
        <ChemicalGraph v-if="state.chemicalData[0].b6HasDosingPump1" title="Bath 6" :data="state.bath6_data" />
        <ChemicalGraph v-if="state.chemicalData[0].b7HasDosingPump1" title="Bath 7" :data="state.bath7_data" />
        <ChemicalGraph v-if="state.chemicalData[0].b8HasDosingPump1" title="Bath 8" :data="state.bath8_data" />
        <ChemicalGraph v-if="state.chemicalData[0].b9HasDosingPump1" title="Bath 9" :data="state.bath9_data" />
        </v-row>
      </v-expand-transition>
        </v-card>
        
    </div>
 </template>
 <script>
 import state from '../../state'
 import { getCurrentInstance } from 'vue';
 import ChemicalGraph from './charts/ChemicalGraph.vue'
 export default {
   components: {ChemicalGraph},
   data: () => ({
     currentTime: 1,
     state,
     tab: true,
   }),
 
   mounted() {
   },
 }
 </script>