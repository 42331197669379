<template>
    <div>
    <v-card class="mr-5 ml-5 rounded-tl-xl rounded-br-xl" :style="state.black ? {background: '#363636'} : {background: 'white'}">
     
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >

      <v-badge content="Premium" color="black" inline>
        <h3>Cost analysis</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
      <v-expand-transition >
        
        <v-row v-show="tab==1"  justify="center" class="align-top">
            <v-col v-if="state.userData.license == 'premium'" cols="15" md="2" class="">
              <v-item  class="mb-5">
                <v-card :color="'green'" round class="d-flex align-center bg-secondary rounded-tl-xl" dark height="200">
                  <v-row>
                    <v-col cols="12" sm="10">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-6">

                            <v-icon x-large :color="'white'">fas fa-euro-sign</v-icon>

                          </div>
                          <v-list-item-subtitle :class="'h2 white--text'">Total
                            cost</v-list-item-subtitle>
                          <v-list-item-title class="h3 headline mb-1" :class="'white--text'">
                            <strong v-if="state.loadedAll">{{ state.costData.totalCost }}</strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>

              <v-card :color="state.black ? '#1e1e1e' : 'white'" class="d-flex align-center" dark height="460">
                  <v-row>
                    <v-col cols="12" sm="10" ref="piechart">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mr-3">
                    <GChart v-if="state.loadedAll" class="cost-graph" type="ColumnChart" :data="state.costColumnData"
                    :options="columnOptions" />
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>             
                </v-card>
            </v-col>
            <v-col v-if="state.userData.license == 'premium'" cols="15" lg="7" ref="bigchart">
              <v-item >
                <v-card :color="state.black ? '#1e1e1e' : 'white'" :dark="state.black" height="680">
                  <v-app-bar
                  dark class="text-h6">Price graph (Euro)
                  </v-app-bar>
                  <GChart v-if="state.loadedAll" type="AreaChart" :data="state.costData.chartData"
                    :options="chartOptions" />
                </v-card>
              </v-item>
            </v-col>
            <v-col v-if="state.userData.license == 'premium'" cols="15" md="2" >
                  <v-card height="680" class="mx-auto" :color="state.black ? '#1e1e1e' : 'white'" >
                    <v-app-bar
                    dark class="text-h6">List of costs (€)
                  </v-app-bar>
                    <v-list :color="state.black ? '#1e1e1e' : 'white'" :dark="state.black">
                      
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="yellow" left>fas fa-bolt</v-icon>
                      <v-list-item-title >Electric</v-list-item-title>
                      <t v-if="state.electricUsage">{{ Math.floor(state.electricUsage * state.Settings.electric_price)}}   </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="orange" left>fas fa-fire</v-icon>
                      <v-list-item-title >Gas</v-list-item-title>
                      <t v-if="state.totalTotalGas">{{ Math.floor(state.totalTotalGas * state.Settings.gas_price)}}  </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="purple" left>fas fa-flask</v-icon>
                      <v-list-item-title >Chemicals</v-list-item-title>
                      <t v-if="state.chemicalUsage">{{ Math.floor(state.chemicalUsage * state.Settings.chemical_price / 100)/10}} </t>
                      <t class="text-caption" v-else>No data available</t>
                      
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="#0069b3" left>fas fa-droplet</v-icon>
                      <v-list-item-title >Water</v-list-item-title>
                      <t v-if="false">{{ Math.floor(state.chemicalUsage * state.Settings.chemical_price / 100)/10}} </t>
                      <t class="text-caption" v-else>No data available</t>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="#6d6b6c" left>mdi mdi-spray</v-icon>
                      <v-list-item-title >Powder</v-list-item-title>
                      <t v-if="false">{{ Math.floor(state.chemicalUsage * state.Settings.chemical_price / 100)/10}} </t>
                      <t class="text-caption" v-else>No data available</t>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="#6d6b6c" left>mdi mdi-alpha-d-circle-outline</v-icon>
                      <v-list-item-title >Demi water</v-list-item-title>
                      <t v-if="false">{{ Math.floor(state.chemicalUsage * state.Settings.chemical_price / 100)/10}} </t>
                      <t class="text-caption" v-else>No data available</t>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      class="mt-2"
                      color="primary"
                    >
                        <v-icon class="mr-3" color="#6d6b6c" left>mdi mdi-arrow-collapse-all</v-icon>
                      <v-list-item-title >Pressured air</v-list-item-title>
                      <t v-if="false">{{ Math.floor(state.chemicalUsage * state.Settings.chemical_price / 100)/10}} </t>
                      <t class="text-caption" v-else>No data available</t>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                    
                  </v-card>
            </v-col>

            <div v-if="state.userData.license == 'base'">
              
              <h4 class="ml-15 mt-5">It appears that a premium license is required to access this page or service. To proceed, please contact Indufinish for information on obtaining a premium license and to explore the associated benefits. In addition, consider discussing the possibility of adding sensors to the production line with Indufinish. This could provide valuable data insights and improve overall efficiency in your operations. By engaging with Indufinish on these matters, you can optimize your processes and maximize the benefits of your investment.</h4>
            <v-row justify="center">
              <v-col cols="15" md="2" class="">
                <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="15" lg="7" ref="bigchart">
              <v-item >
                <v-skeleton-loader type="card"></v-skeleton-loader>
              </v-item>
            </v-col>
            <v-col cols="15" md="2" >
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-row justify="center">
              <v-col cols="15" md="2" class="">
                <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
            <v-col cols="15" lg="7" ref="bigchart">
              <v-item >
                <v-skeleton-loader type="card"></v-skeleton-loader>
              </v-item>
            </v-col>
            <v-col cols="15" md="2" >
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
            </div>
          </v-row>
        </v-expand-transition>
        <v-expand-transition>
          <v-row  justify="center" v-show="tab==1"  full-width >
            <v-col v-if="state.userData.license == 'premium'" cols="12" md="11" ref="fluct">
              <v-item >
                <v-card :color="state.black ? '#1e1e1e' : 'white'" class=" mb-15" :dark="state.black" height="540">
                  <v-app-bar
                    dark class="text-h6">Price graph (Euro/h)
                  </v-app-bar>
                  <GChart v-if="state.loadedAll && state.costData.totalCost > 0" class="d-flex" type="AreaChart" :data="state.costData.chartDataFlu"
                    :options="chartBottomOptions" />
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-expand-transition>
        </v-card>
  </div>
</template>

<script>
import state from '../../state'
import Vue, { getCurrentInstance } from 'vue'
import { GChart } from 'vue-google-charts/legacy'

export default {
  components: { GChart},
  data: () => ({
    items: [
        { text: 'Gas', icon: 'mdi mdi-clock', value: 0 },
        { text: 'Electric', icon: 'mdi-account', value: 0 },
        { text: 'Water', icon: 'mdi-flag', value: 0 },
        { text: 'Chemicals', icon: 'mdi-flag', value: 0 },
        { text: 'Powder', icon: 'mdi-flag', value: 0 },
    ],
    currentTime: 1,
    chartData: [
      ['Time', 'Gasdata'],
      ['Pump', 1000],
      ['Lowlevel', 1170],
      ['Highlevel', 660],
      ['E stop', 1030]
    ],
    columnData: [
      ['Time', 'Gasdata'],
      ['Pump', 1000],
      ['Lowlevel', 1170],
      ['Highlevel', 660],
      ['E stop', 1030]
    ],
    tab: true,
    chartOptions: {
      title: '',
      curveType: 'function',
      series: {
        0: {color: '#029a6a', areaOpacity: 0.85 },
        1: {color: '#c15f20', areaOpacity: 0.85},        
        2: { color: '#970e76', areaOpacity: 0.85},
        3: {color: '#d09a0d', areaOpacity: 0.85}
      },
      
      hAxis: {
        format: 'dd/MM HH:mm',
        textStyle:{color: '#7c7c7c'},
        gridlines: {
          opacity: '100',
          units: {
            count: -1,
              days: {format: ['MMM dd']},
              hours: {format: ['HH:mm', 'ha']},
              color: '#434242'
            },
        },
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'},        
        gridlines: {
        },
      },
      legendTextStyle: { color: '#7c7c7c' },
      titleTextStyle: { color: '#7c7c7c' },
      color: '#FFF',
      backgroundColor: {fill:'transparent'},
      is3D: true,
      chartArea: { left: 60, top: 20, width: "88%", height: "70%" },
      legend: {position: 'bottom'},
      width: window.innerWidth / 1.40,
      height:  window.innerHeight / 1.8
    },
    chartBottomOptions: {
      title: '',
      curveType: 'function',
      series: {
        0: {color: '#029a6a', areaOpacity: 0.85 },
        1: {color: '#c15f20', areaOpacity: 0.85},        
        2: { color: '#970e76', areaOpacity: 0.85},
        3: {color: '#d09a0d', areaOpacity: 0.85}
      },
      hAxis: {
        format: 'dd/MM HH:mm',
        textStyle:{color: '#7c7c7c'},
        gridlines: {
          units: {
              days: {format: ['MMM dd']},
              hours: {format: ['HH:mm', 'ha']},
              color: '#434242'
            },
        },
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'},        
        gridlines: {
        },
      },
      legendTextStyle: { color: '#7c7c7c' },
      titleTextStyle: { color: '#7c7c7c' },
      color: '#FFF',
      backgroundColor: {fill:'transparent'},
      is3D: true,
      chartArea: { left: 60, top: 15, width: "90%", height: "60%" },
      legend: {position: 'bottom'},
      width: window.innerWidth / 1.05,
      height:  window.innerHeight / 2.5
    },
    columnOptions: {
      colors: ['#dc6511', '#0069b3', '#b70d7f', '#fbb800', '#f6c7b6'],
      title: "Cost divison",
      series: {
        0: {color: '#029a6a', areaOpacity: 0.85 },
        1: {color: '#c15f20', areaOpacity: 0.85},        
        2: { color: '#970e76', areaOpacity: 0.85},
        3: {color: '#d09a0d', areaOpacity: 0.85}
      },
      hAxis: {
        textStyle:{color: '#7c7c7c'}
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'}
      },
      center: [100, 100],
      legendTextStyle: { color: '#7c7c7c' },
      titleTextStyle: { color: '#97c11f' },
      color: '#97c11f',
      backgroundColor: {fill:'transparent'},
      chartArea: { left: 0, top: 0, width: "82%", height: "80%" },
      width: window.innerWidth / 7,
      height: window.innerHeight / 3,
      legend: {position: 'bottom'},

    },
    state,
  }),
  watch: {
    'tab': function (newVal, oldVal) {
      this.onResize()
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.chartBottomOptions.width = this.$refs.fluct.clientWidth
    this.chartBottomOptions.height = this.$refs.fluct.clientHeight - 70
    this.chartOptions.width = this.$refs.bigchart.clientWidth
    this.chartOptions.height = this.$refs.bigchart.clientHeight - 40
    this.columnOptions.width = this.$refs.piechart.clientWidth
    this.columnOptions.height = this.$refs.piechart.clientHeight + 200
  },
  methods: {
    onResize() {
      this.chartBottomOptions.width = this.$refs.fluct.clientWidth
      this.chartBottomOptions.height = this.$refs.fluct.clientHeight - 70
      this.chartOptions.width = this.$refs.bigchart.clientWidth
      this.chartOptions.height = this.$refs.bigchart.clientHeight - 40
    }
  }
}
</script>
<style>
.rounded-card{
    border-radius:100px;
}
</style>