<template>
      <v-col cols="12" md="8">
        <v-item>
          <v-card :color="state.black ? '#1e1e1e' : 'white'" class="d-flex align-center " dark title="oven">
            <GChart v-if="chartData" class="cost-graph" type="LineChart" :data="chartData"
                    :options="chartOptions" />
          </v-card>
        </v-item>
      </v-col>
  </template>
  
  <script>
  import { GChart } from 'vue-google-charts/legacy'
  import state from '../../state'
  import { getCurrentInstance } from 'vue';
  export default {
      components: {GChart },
    data: () => ({
        chartData: [
      ['Time', 'Gasdata'],
      ['Pump', 1000],
      ['Lowlevel', 1170],
      ['Highlevel', 660],
      ['E stop', 1030]
    ],
    chartOptions: {
      title: 'Bath 1',
      series: {
        0: { color: '#fbb800' },
        1: {color: '#dc6511'},        
        2: { color: '#b70d7f'}
      },
      hAxis: {
        format: 'dd/MM/yyyy HH:mm',
        textStyle:{color: '#7c7c7c'}
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'},
        minValue: 0,
      },
      legendTextStyle: { color: '#7c7c7c' },
      titleTextStyle: { color: '#7c7c7c' },
      color: '#FFF',
      backgroundColor: {fill:'transparent'},
      chartarea: { left: 50, top: 0, width: "100%", height: "100%" },
      width: window.innerWidth / 1.8,
      height: window.innerHeight / 3
    },
    loaded: false,
    state,
  }),
  props: {
    title: String,
    data: Array
  },
  watch: {
    'state.chemicalData' (val) {
      this.loadGraph ()
    }
  },

  methods: {
    loadGraph () {
      if (state.black == false) {
        this.chartOptions.color = "000"
      }
      this.chartOptions.title = "Conductivity (μS/cm)"
      this.loaded = false;
      let tempData = []
      this.chartData = []
      tempData[0] = ['Time', 'Conductivity']
      let comp = 1
    this.data = this.data.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
    for (let i = 0; i<this.data.length; i++) {
      if (this.data[i].timeStamp) {
        let formatTimestamp = new Date(this.data[i].timeStamp)
            formatTimestamp.setSeconds(0,0)
            formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
          tempData[i+1] = [formatTimestamp, parseInt(this.data[i].conductivity.conductivity.toString().replace('+', ''))]
      } else {
        comp += 1
      }
      if ((i+comp) == this.data.length -1) {
            this.loaded = true;
        }
      }
      this.chartData = tempData
      const instance = getCurrentInstance();
      instance.proxy.forceUpdate();
    }
  },
  created() {
    this.loadGraph ()
        
    },
}
  </script>
  <style>
  .pie-chart {
    width: auto;
      margin: auto;
  }
  .icon-play-circle{
    font-size: 100px;
      position: absolute;
      top: 75%;
      left: 45%;
      opacity: 1;
      color: blue;
  }
  .margin-bottom{
    margin-bottom: 100px;
  }
  .blurred {
    background-color: #eeeeee;
      flex: inherit;
      width: 85%;
      left: 100px;
      height: 30%;
      border-radius: 20px;
      opacity: 0.1;
      top: 65%;
      flex-direction: column;
      float: inline-end;
      position: absolute;
  }
  </style>