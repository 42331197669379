<template>
  <div v-if="shouldShowDrawer">
    <v-expand-transition>
      <v-navigation-drawer
        v-model="drawer"
        permanent
        app
        color="bg-secondary"
        mini-variant
        mini-variant-width="80"
      >
        <v-img
          alt="Vuetify Logo"
          class="d-block textcenter mx-auto radius"
          contain
          src="../assets/logo.png"
          transition="scale-transition"
          width="75px"
          height="75px"
        />
        <v-divider class="mx-3 my-5"></v-divider>
        <v-list flat class="mt-5">
          <v-list-item-group v-model="selectItem">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              @click="onPress(i)"
              :ripple="false"
            >
              <v-btn v-if="hasRights(item.right)" name="i" icon :ripple="false" class="mt-6">
                <v-icon :color="isCurrent(item.icon) ? '#ccdfed' : '#080808'" v-text="item.icon" size="30" />
              </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div class="settings-btn-container">
          <v-btn icon density="compact" :ripple="false" @click="loadSettings">
            <v-icon class="material-icons-round" :color="isCurrent('Settings') ? 'white' : '#080808'">fas fa-gear</v-icon>
          </v-btn>
        </div>
      </v-navigation-drawer>
    </v-expand-transition>
  </div>
</template>

<script>
import state from '../state';

export default {
  data: () => ({
    selectItem: 0,
    drawer: null,
    items: [
      { icon: 'fas fa-house', right: 'base', route: '/Home' },
      { icon: 'fas fa-fire', right: 'oven', route: '/Ovens' },
      { icon: 'fas fa-flask', right: 'base', route: '/PreThreatment' },
      { icon: 'fas fa-file-excel', right: 'excel', route: '/CSV' },
      { icon: 'fas fa-file', right: 'base', route: '/Documentation' },
      { icon: 'mdi mdi-alpha-l-box', right: 'base', route: '/Labs' },
    ],
    showModal: false,
    state,
    scrollpx: 0,
  }),
  emits: ['open-modal'],
  computed: {
    shouldShowDrawer() {
      return (
        this.scrollpx > 500 ||
        this.state.currentPage !== 'Home' ||
        !this.state.hide
      );
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onPress(i) {
      if (!this.hasRights(this.items[i].right)) {
        this.$emit('open-modal');
      } else {
        this.navigate(i);
      }
    },
    navigate(i) {
      const targetRoute = this.items[i].route;
      if (this.$route.path !== targetRoute) {
        this.$router.push({ path: targetRoute, replace: true });
      }
    },
    handleScroll() {
      this.scrollpx = window.scrollY;
    },
    hasRights(item) {
      return this.state.userData.unlocked.includes(item);
    },
    isCurrent(item) {
      const pageIcons = {
        Home: 'fas fa-house',
        'Pre treatment': 'fas fa-flask',
        Ovens: 'fas fa-fire',
        Settings: 'Settings',
        CSV: 'fas fa-file-excel',
        'Indufinish labs': 'mdi mdi-alpha-l-box',
      };
      return pageIcons[this.state.currentPage] === item;
    },
    loadSettings() {
      if (this.$route.path !== '/Settings') {
        this.$router.push({ path: '/Settings', replace: true });
      }
    },
  },
};
</script>

<style scoped>
.radius {
  border-radius: 10%;
  margin-top: 5px;
  margin: 10px;
}

.v-list-item-group .v-list-item--active {
  color: white !important;
}

.settings-btn-container {
  position: absolute;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>
