import state from '../../state'
export default class chemicalDataHandler {
    constructor () {
        state
    }

    gasToSetpoint() {
        state.curing_oven_stats = this.autoCalc(state.curing_oven_dashboard)
        state.drying_oven_stats = this.autoCalc(state.drying_oven_dashboard)
    }

    autoCalc (data) {
        let reachingFirstTime = false
        let startPoint = false
        let minuteCount = 0
        let minutesInProtection = 0
        let usage = 0
        let result = {}
        let usage80ToSetpoint = 0
        data = data.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
        for(let i = 0; i<data.length; i++) {
            
            if (reachingFirstTime == false) {
                if (data[i].gas > 0) {
                    minuteCount += 1
                    if(startPoint == false) {
                        result.start_date = data[i].timeStamp
                        result.start_temperature = data[i].temperature
                        startPoint = true
                    }
                    
                    if (data[i].temperature > 80 & data[i].temperature < 90) {
                        minutesInProtection += 5
                    }
                }

                usage += data[i].gas

                if (data[i].temperature > 80) {
                    usage80ToSetpoint += data[i].gas
                }
                if (data[i].temperature >= (data[i].setpoint-1)) {
                    reachingFirstTime = true
                    result.gasWarmingUp = Math.round(usage)             
                    result.end_date = data[i].timeStamp
                    result.warmUpTime = Math.round((result.end_date - result.start_date) / 1000 / 60)
                    result.waiting = minutesInProtection
                    result.setpoint = data[i].setpoint
                    result.protectionToSetpointUsage = usage80ToSetpoint
                }
            }
        }

        return result
    }

    gasToMaintain(data) {

    }
}