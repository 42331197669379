import Vue from 'vue'
import VueRouter from 'vue-router'
import FileUploader from '../components/FileUploader'
import Users from '../views/Users.vue'
import Home from '../views/HomeView.vue'
import PreThreatment from '../views/PreThreatment.vue'
import Settings from '../views/Settings.vue'
import Oven from '../views/Oven.vue'
import Documentation from '../views/Documentation.vue'
import App from '../App'
import CSV from '../views/CSV'
import Labs from '../views/Labs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/PreThreatment',
    name: 'PreThreatment',
    component: PreThreatment,
  },
  {
    path: '/Documentation',
    name: 'Documentation',
    component: Documentation,
  },
  {
    path: '/UserDetails',
    name: 'UserDetails',
    component: FileUploader
  },
  {
    path: '/CSV',
    name: 'CSV',
    component: CSV
  },
  {
    path: '/Ovens',
    name: 'Ovens',
    component: Oven
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/Labs',
    name: 'Labs',
    component: Labs
  },
  {
    path: '/',
    name: 'App',
    component: App
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
