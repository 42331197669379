<template>
    <v-carousel :show-arrows="false" class="mb-15" >
    <v-carousel-item
      cover
    >
    <v-img src="../csv.jpg">
    </v-img>
    </v-carousel-item>
  </v-carousel>
  </template>

<script>
 import state from '../state'
 import { getCurrentInstance } from 'vue';
 export default {
  data: () => ({
    state,
    items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
  }),
}
</script>