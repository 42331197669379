<template>
  <v-card class="mx-auto pa-12 pb-8 mt-15" elevation="8" max-width="648" width="500" rounded="lg">
    <div class="text-h4 text-center">Columns</div>
    <v-divider></v-divider>
    <v-switch
      v-if="columnsAvailable.electricElements"
      v-model="columnsEnabled.electricElements"
      @change="update('electricElements', columnsEnabled.electricElements)"
      label="Electric Elements"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.electricOveral"
      v-model="columnsEnabled.electricOveral"
      @change="update('electricOveral', columnsEnabled.electricOveral)"
      label="Electric Overal (kWh)"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.gasCuring"
      v-model="columnsEnabled.gasCuring"
      @change="update('gasCuring', columnsEnabled.gasCuring)"
      label="Gas Usage Curing Oven (m³)"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.gasDrying"
      v-model="columnsEnabled.gasDrying"
      @change="update('gasDrying', columnsEnabled.gasDrying)"
      label="Gas Usage Drying Oven (m³)"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.temperatureCuring"
      v-model="columnsEnabled.temperatureCuring"
      @change="update('temperatureCuring', columnsEnabled.temperatureCuring)"
      label="Temperature Curing Oven"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.setpointCuring"
      v-model="columnsEnabled.setpointCuring"
      @change="update('setpointCuring', columnsEnabled.setpointCuring)"
      label="Setpoint Curing Oven"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.setpointDrying"
      v-model="columnsEnabled.setpointDrying"
      @change="update('setpointDrying', columnsEnabled.setpointDrying)"
      label="Setpoint Drying Oven"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.chainGraph"
      v-model="columnsEnabled.chainGraph"
      @change="update('chainGraph', columnsEnabled.chainGraph)"
      label="Chain Graph Data"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.temperatureDrying"
      v-model="columnsEnabled.temperatureDrying"
      @change="update('temperatureDrying', columnsEnabled.temperatureDrying)"
      label="Temperature Drying Oven"
    ></v-switch>
    <v-switch
      v-if="columnsAvailable.speed"
      v-model="columnsEnabled.speed"
      @change="update('speed', columnsEnabled.speed)"
      label="Chain Speed (m/min)"
    ></v-switch>
  </v-card>
</template>

<script>
export default {
  props: {
    columnsAvailable: {
      type: Object,
      required: true,
    },
    columnsEnabled: {
      type: Object,
      required: true,
    },
  },
  methods: {
    update(key, value) {
      console.log('Child Component: Emitting update for', key, 'with value', value);
      this.$emit('update:columnsEnabled', { ...this.columnsEnabled, [key]: value });
    },
  },
};
</script>
