<template>
    <div>
    <v-carousel :show-arrows="false" hide-delimiters class="mb-15" height="1300">
    <v-carousel-item>
        <v-parallex><v-img class="content-pic" width="2500" height="500" src="../../toppage.jpg">

</v-img>
</v-parallex>
    <v-card outlined color="transparent" class="contentf mt-15 " width="2500">
        <v-row justify="center" class="mt-15" width="" v-if="state.loadedAll">
                <v-col cols="12" md="2">
                <v-item >
                    <v-card color="#dedee0" :dark="false" class="d-flex align-center rounded-tl-xl rounded-br-xl elevation-10 rounded-0" height="200">
                    <v-row>
                        <v-col cols="12" sm="10">
                        <v-list-item three-line class="ml-2">
                            <v-list-item-content>

                            <div> 
                                <v-icon x-large class="rounded-circle ml-2 mb-7 pt-5 pr-5 pl-4 pb-5 green" :color="'white'">mdi mdi-currency-eur</v-icon>
                            </div>
                            <v-list-item-subtitle class="text-button font-weight-bold black--text">Total
                                cost</v-list-item-subtitle>
                            <v-list-item-title class="headline mb-1">
                                <strong v-if="state.loadedAll && state.userData.license == 'premium'">{{ state.costData.totalCost }}</strong>
                                <strong v-ifelse="state.loadedAll"></strong>
                            </v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                        </v-col>
                    </v-row>

                    </v-card>
                </v-item>
                </v-col>

                <v-col cols="12" md="2">
                    <v-item>
                        <v-card color="#dedee0" :dark="false" class="d-flex align-center rounded-tl-xl rounded-br-xl elevation-10 rounded-0" height="200">
                        <v-row>
                            <v-col cols="12" sm="12">
                            <v-list-item three-line >
                                <v-list-item-content>
                                <div>

                                    <v-icon x-large class="rounded-circle ml-2 mb-7 pt-5 pr-5 pl-5 pb-5 yellow" :color="'white'">mdi mdi-lightning-bolt-outline </v-icon>

                                </div>
                                <v-list-item-subtitle class="text-button font-weight-bold black--text">Total electric
                                    usage</v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1">
                                    <strong v-if="state.loadedAll">{{ state.electricUsage }} kWh</strong>
                                </v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                            </v-col>

                        </v-row>

                        </v-card>

                        
                    </v-item>
                </v-col>

                <v-col cols="12" md="2">
                    <v-item>
                    <v-card color="#dedee0" :dark="false" class="d-flex align-center rounded-tl-xl rounded-br-xl elevation-10 rounded-0" height="200">
                    <v-row >
                        <v-col cols="12" sm="12">
                        <v-list-item three-line>
                            <v-list-item-content>
                            <div>

                                <v-icon x-large class="rounded-circle ml-2 mb-7 pt-5 pr-5 pl-5 pb-5 orange" :color="'white'">mdi mdi-fire</v-icon>

                            </div>
                            <v-list-item-subtitle class="text-button font-weight-bold black--text">Total gas</v-list-item-subtitle>
                            <v-list-item-title class="headline mb-1">
                                <strong v-if="state.loadedAll"> {{ Math.floor(state.totalTotalGas)}} m³ </strong>
                            </v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                        </v-col>
                    </v-row>

                    </v-card>
                </v-item>

                </v-col>

                <v-col cols="12" md="2" v-if="state.chain_available">
                    <v-item>
                    <v-card color="#dedee0" :dark="false" class="d-flex align-center rounded-tl-xl rounded-br-xl elevation-10 rounded-0" height="200" >
                    <v-row >
                        <v-col cols="12" sm="12">
                        <v-list-item three-line>
                            <v-list-item-content>
                            <div>

                                <v-icon x-large class="rounded-circle ml-2 mb-7 pt-5 pr-5 pl-5 pb-5 black" :color="'white'">mdi mdi-cog-outline</v-icon>

                            </div>
                            <v-list-item-subtitle class="text-button font-weight-bold black--text">Total minutes run (chain)</v-list-item-subtitle>
                            <v-list-item-title class="headline mb-1">
                                <strong v-if="state.loadedAll"> {{ Math.floor(state.totalChain)}} m </strong>
                            </v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                        </v-col>
                    </v-row>

                    </v-card>
                </v-item>

                </v-col>
                <v-col v-if="state.chemicalData[0].b1HasDosingPump1" cols="12" md="2">
                <v-item>
                    <v-card color="#dedee0" :dark="false" class="d-flex align-center rounded-tl-xl rounded-br-xl elevation-10 rounded-0" height="200">
                    <v-row @click="selectWidget('ch')">
                        <v-col cols="12" sm="12">
                        <v-list-item three-line>
                            <v-list-item-content>
                            <div>

                                <v-icon x-large class="rounded-circle ml-2 mb-7 pt-5 pr-5 pl-5 pb-5 purple" :color="'white'">mdi mdi-flask-outline</v-icon>

                            </div>
                            <v-list-item-subtitle class="text-button font-weight-bold black--text">Total chemicalusage</v-list-item-subtitle>
                            <v-list-item-title class="headline mb-1">
                                <strong v-if="state.loadedAll">{{ Math.floor(state.chemicalUsage / 100) / 10 }} L</strong>
                            </v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                        </v-col>
                        
                  </v-row>

                </v-card>
              </v-item>
            </v-col>
        </v-row>

        <div v-else>
            <v-row justify="center" class="mt-15" >
            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>
            </v-row>
        </div>
    </v-card>
        <video playsinline autoplay muted loop width="2500"  class="video">
            
            <source src="../../window.mp4" type="video/mp4">

        </video>

    </v-carousel-item>
  </v-carousel>
</div>
  </template>

<script>
 import state from '../../state'
 import { getCurrentInstance } from 'vue';
 export default {
  data: () => ({
    state,
    items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
  }),
}
</script>

<style>
.video{
    margin-top: 0px;
    filter: grayscale(100%);
}

.contentf {
  position: absolute;
  z-index: 20000;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  opacity: 1;
  margin-bottom: 450px;
}


.content-pic {
z-index: 20000;
  position: absolute;
  padding-bottom: 20px;
  opacity: 0.85;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  margin-bottom: 800px;

}
</style>