<template>
    <div class="pa-4 text-center">
        <v-dialog v-model="show" max-width="1000">
            <v-card rounded="lg">
                <v-card-title class="d-flex justify-space-between align-center">
                    <div class="text-h5 text-medium-emphasis ps-2">
                        Create new customer
                    </div>

                    <v-btn icon="mdi-close" variant="text" @click="isActive.value = false"></v-btn>
                </v-card-title>

                <v-divider class="mb-4"></v-divider>

                <v-card-text>
                    <div class="text-medium-emphasis mb-4">
                        Which language would you like to use?
                    </div>
                    <v-select v-model="selectedLanguage" :items="language" />
                    <div class="mb-2 mt-5">VOR number</div>
                    <v-text-field v-model="newObject.vor"
                        label="Wat is the number you wanna identify the project with"></v-text-field>
                    <div class="mb-2 mt-5">First Name</div>
                    <v-text-field v-model="newObject.firstname"
                        label="What is the name of the person that you are dealing with?"></v-text-field>
                    <div class="mb-2 mt-5">Last name</div>
                    <v-text-field v-model="newObject.lastname"
                        label="What is the last name of the person that you are dealing with?"></v-text-field>
                    <div class="mb-2 mt-5">Company name</div>
                    <v-text-field v-model="newObject.company"
                        label="What is the last name of the company that you are dealing with?"></v-text-field>
                    <div class="mb-2 mt-5">Customers mail</div>
                    <v-text-field v-model="newObject.email" label="What is the customers email adress?"></v-text-field>
                    <div class="mb-2 mt-5">Subject</div>
                    <v-text-field v-model="subject"
                        label="What should be the subject of the automated mail"></v-text-field>
                    <div class="mb-2 mt-5">Description</div>

                    <v-textarea class="mb-2" auto-grow rows="5" v-model="newObject.description" variant="outlined"
                        persistent-counter></v-textarea>
                </v-card-text>

                <v-divider class="mt-2"></v-divider>

                <v-card-actions class="my-2 d-flex jufstify-end">
                    <v-btn class="text-none font-weight-bold " v-if="!isUploading" color="primary" variant="text"
                        @click="send">Create and send mail</v-btn>
                    <v-progress-circular indeterminate v-if="isUploading" color="primary"></v-progress-circular>
                    <v-btn class="text-none" rounded="xl" @click="show = false">Cancel</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import state from '../../state';
import axios from 'axios'
export default {
    data: () => ({
        state,
        isUploading: false,
        subject: '',
        newObject: {
            name: '',
            email: '',
            firstname: '',
            lastname: '',
            password: '',
            company: '',
            vor: '',
            description: '',
            rights: ['offerte'],
            type: '',
            url: '',
            timestamp: '',
            id: ''
        },
        selectedLanguage: 'English',
        language: [
            'English',
            'Polish',
            'Dutch',
            'German'
        ],
        size: 8,
        characters: 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z',
        axios,
    }),
    props: {
        value: Boolean
    },
    mounted() {
        this.newObject.password = this.generate()
        this.changeText('English')
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        'selectedLanguage'(val) {
            this.changeText(val)
        },

        'newObject.lastname'(val) {
            this.changeText(this.selectedLanguage)
        },

        'newObject.email'(val) {
            this.changeText(this.selectedLanguage)
        }
    },
    methods: {
        generate() {
            let charactersArray = this.characters.split(',');
            let CharacterSet = '';
            let password = '';
            CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
            CharacterSet += '0123456789';
            CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

            for (let i = 0; i < this.size; i++) {
                
                console.log(i)
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
            }
            return password;
        },
        send() {
            this.newObject.username = this.newObject.email

            axios.post('https://us-central1-indusotrage.cloudfunctions.net/Indufinish/sendMail/', {
                to: this.newObject.email,
                subject: this.subject,
                text: this.newObject.description,
                html: this.newObject.description.replace(/\n/g, '<br>')
            })
            const { createHash } = require('crypto');
            const customer = {
                username: this.newObject.email,
                password: createHash('sha256').update(this.newObject.password + 'bier').digest('hex'),
                rights: ['offerte'],
                firstname: this.newObject.firstname,
                lastname: this.newObject.lastname,
                company: this.newObject.company,
                vor: this.newObject.vor,
                offerte: true,
                email: this.newObject.email
            }
            this.$emit('saveCustomer', customer)
            this.show=false
        },

        changeText(val) {
            if (val == 'English') {
                this.subject = "Your Requested Documentation"
                this.newObject.description = "Dear mister/misses " + this.newObject.lastname + ",\n\nAn account has been created for you on our website to access the powder coating installations documentation. Your login details are as follows:\n\nUsername: " + this.newObject.email + "\nPassword: " + this.newObject.password + "\n\nThis is an automatically generated email, so you cannot reply to it. Please visit https://www.indufinish.app and log in with your credentials to view the documents.\n\nKind regards,\n\nIndufinish"
            } else if (val == 'Dutch') {
                this.subject = "Uw aangevraagde documentatie"
                this.newObject.description = "Beste meneer/mevrouw " + this.newObject.lastname + ",\n\nEr is een account voor u aangemaakt op onze website om toegang te krijgen tot de documentatie van de poedercoatinstallaties. Uw inloggegevens zijn als volgt:\n\nGebruikersnaam: " + this.newObject.email + "\nWachtwoord: " + this.newObject.password + "\n\nDit is een automatisch gegenereerde e-mail, dus u kunt hier niet op reageren. Bezoek https://www.indufinish.app en log in met uw gegevens om de documenten te bekijken.\n\nMet vriendelijke groet,\n\nIndufinish"
            } else if (val == 'German') {
                this.subject = "Ihre angeforderte Dokumentation"
                this.newObject.description = "Sehr geehrter Herr/Frau " + this.newObject.lastname + ",\n\nEin Konto wurde für Sie auf unserer Website erstellt, um auf die Dokumentation der Pulverbeschichtungsanlagen zuzugreifen. Ihre Anmeldeinformationen lauten wie folgt:\n\nBenutzername: " + this.newObject.email + "\nPasswort: " + this.newObject.password + "\n\nDies ist eine automatisch generierte E-Mail, daher können Sie nicht darauf antworten. Bitte besuchen Sie https://www.indufinish.app und melden Sie sich mit Ihren Anmeldeinformationen an, um die Dokumente einzusehen.\n\nMit freundlichen Grüßen,\n\nIndufinish"
            } else if (val == 'Polish') {
                this.subject = "Żądana dokumentacja"
                this.newObject.description = "Szanowny Panie/Pani " + this.newObject.lastname + ",\n\nNa naszej stronie internetowej zostało dla Państwa utworzone konto, aby uzyskać dostęp do dokumentacji instalacji do malowania proszkowego. Państwa dane logowania są następujące:\n\nNazwa użytkownika: " + this.newObject.email + "\nHasło: " + this.newObject.password + "\n\nTo jest automatycznie generowany e-mail, więc nie można na niego odpowiadać. Proszę odwiedzić https://www.indufinish.app i zalogować się za pomocą swoich danych, aby zobaczyć dokumenty.\n\nZ poważaniem,\n\nIndufinish"
            }
        }
    }

}
</script>