<template>
    <v-carousel :show-arrows="false" class="mb-15 no-gray" >
    <v-carousel-item
      cover
    >
    <v-img src="../../Oven.jpg">
        <v-card outlined color="transparent" class="mt-15 no-gray">
        <v-row justify="center" class="mt-15 no-gray" v-if="state.loadedAll">
                <v-col cols="12" md="2" class="mr-5 no-gray">
                    <v-item class="mb-10">
                        <v-card :color="'#fbb800'" class="d-flex align-center rounded-xl" dark height="200">
                        <v-row>
                            <v-col cols="12" sm="12">
                            <v-list-item three-line class="mt-10">
                                <v-list-item-content>
                                <div class="mb-4">

                                    <v-icon x-large :color="'white'">fas fa-bolt </v-icon>

                                </div>
                                <v-list-item-subtitle :class="'white--text'">Total electric
                                    usage</v-list-item-subtitle>
                                <v-list-item-title class="headline mb-1" :class="'white--text'">
                                    <strong v-if="state.loadedAll">{{ state.electricUsage }} kWh</strong>
                                </v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                            </v-col>

                        </v-row>

                        </v-card>

                        
                    </v-item>
                </v-col>

                <v-col cols="12" md="2" class="mr-5">
                <v-row  justify="center" class="align-top">
                    <v-item>
                    <v-card :color="'#dc6511'" class="d-flex align-center rounded-xl mb-15" dark height="200">
                    <v-row >
                        <v-col cols="12" sm="12">
                        <v-list-item three-line class="mt-10">
                            <v-list-item-content>
                            <div class="mb-4">

                                <v-icon x-large :color="'white'">fas fa-fire</v-icon>

                            </div>
                            <v-list-item-subtitle :class="'white--text'">Total gas</v-list-item-subtitle>
                            <v-list-item-title class="headline mb-1" :class="'white--text'">
                                <strong v-if="state.loadedAll"> {{ Math.floor(state.totalTotalGas)}} m³ </strong>
                            </v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                        </v-col>
                    </v-row>

                    </v-card>
                </v-item>
                </v-row>

                </v-col>
        </v-row>

        <div v-else>
            <v-row justify="center" class="mt-15" >
            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>

            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>
            </v-row>
        </div>
    </v-card>
    </v-img>
    </v-carousel-item>
  </v-carousel>
  </template>

<script>
 import state from '../../state'
 import { getCurrentInstance } from 'vue';
 export default {
  data: () => ({
    state,
    items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
  }),
}
</script>
<style>
.grayf {
    filter: grayscale(100%);
}

.no-gray{
    filter: grayscale(0%);
}
</style>