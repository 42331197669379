<template>
    <div class="pa-4 text-center">
        <v-dialog
        v-model="show"
        max-width="600"
        >  
        <v-card
        v-if="state.loadedAll"
            prepend-icon="mdi-account"
        >
        <v-card-title>Element creation panel</v-card-title>
            <v-card-text class="mt-5">
            <v-row dense>
                <v-col
                cols="12"
                md="4"
                sm="6"
                >
                <v-text-field
                    label="Name of data"
                    v-model="title"
                    required
                ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                md="4"
                sm="6"
                >
                <v-select
                    v-model="selectedType"
                  :items="items"
                  label="Type*"
                  required
                ></v-select>

                </v-col>
                <v-col
                cols="12"
                md="4"
                sm="6"
                >
                <v-select
                    v-model="selectedSize"
                  :items="sizes"
                  label="Size*"
                  required
                ></v-select>

                </v-col>
            </v-row>

            <small class="text-caption text-medium-emphasis">*indicates required field</small>
            </v-card-text>

            <v-divider></v-divider>
            <v-item-group v-if="selectedType == 'Graph' | selectedType=='Bar' | selectedType =='Doughnut'">
                <v-card-title>Period</v-card-title>
                    <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            max-width="290"

                            >
                            <template v-slot:activator="{ on, attrs }">
                                
                                <v-text-field
                                :value="state.dates[0]"
                                depressed
                                label="Start date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="date = null"
                                class="shrink mr-6 mt-5 mb-5 ml-6"
                                prepend-inner-icon="fas fa-calendar"
                                :dark="state.black"
                                solo dense hide-details
                                >
                                <v-icon slot="prepend-inner" class="mr-2" color="primary">
                                    fas fa-calendar
                                </v-icon>
                            </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="state.dates[0]"
                                @change="menu1 = false"
                                :dark="state.black"
                            ></v-date-picker>
                </v-menu>
                <v-select
                    class="ml-7 mr-7 mt-8"
                        v-model="selectedDays"
                        underlined
                    :items="days"
                    label="Days*"
                    shrink
                    required
                    ></v-select>
                <v-divider></v-divider>
                <v-card-title>Selection</v-card-title>
                <v-chip v-for="chip in chips" :color="chip.dataset.backgroundColor" outlined v-bind:key="chip.name" class="ml-5 mt-5">
                        {{chip.name}}
                        <v-btn
            class="ml-2"
            icon
            color="primary"
            variant="outlined"
            @click="deleteItem(chip.name)"
        >
            <v-icon
            dark
            small
            >
            fa-xmark
            </v-icon>
        </v-btn>
                </v-chip>
                <v-divider></v-divider>
                <v-row class="ml-3">
                    <v-col
                    cols="12"
                    md="10"
                    sm="6"
                    >
                        <v-select
                            v-model="selectedPart"
                        :items="availableParts"
                        label="Part of the installation*"
                        required
                        ></v-select>
                    </v-col>
                </v-row>

            <v-card-text>

            </v-card-text>
        </v-item-group>
        <v-item-group v-if="selectedType == 'Note'">
            <div class="mb-2 mt-5 ml-5 mr-5">Description</div>

            <v-textarea
            :counter="800"
            class="mb-2 ml-5 mr-5"
            rows="2"
            v-model="description"
            variant="outlined"
            persistent-counter
            ></v-textarea>
        </v-item-group>
                <v-divider></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                variant="plain"
                @click="show = false"
            >Close</v-btn>

            <v-btn
                color="primary"
                variant="tonal"
                @click="returnElement()"
            >Save</v-btn>
            </v-card-actions>
            
        </v-card>
        <v-skeleton-loader
        v-if="!state.loadedAll"
            class="mx-auto"
            elevation="12"
            max-width="600"
            type="table-heading, list-item-two-line, image, table-tfoot"
        ></v-skeleton-loader>
        </v-dialog>
    </div>
  </template>

  <script>
    import state from '../../state';
    import dataHandler from '../../script/Calculations/dataHandler'
    export default {
        data: () => ({
            items: [
                'Graph' ,
                'Doughnut',
                'Note',
            ],
            sizes: [
                'small' ,
                'medium' ,
                'big',
            ],
            days: [
                1,
                2,
                3,
                4,
                5,
                6,
                7
            ],
            dHandler: new dataHandler(),
            menu1: false,
            menu2: false,
            chips: [],
            selectedType: '',
            selectedDays: 5,
            selectedSize: '',
            selectedPart: '',
            title: '',
            state,
            color: '',
            timestamps: [],
            description: '',
            columnsAvailable: {
                gasCuring: false,
                gasDrying: false,
                electricElements: false,
                electricOveral: false,
                temperatureCuring: false,
                setpointCuring: false,
                temperatureDrying: false,
                setpointDrying: false,
                speed: false,
                conductivity: false,
                ph: false,
                bathTemp: false,
            },
            columnsEnabled: {
                gasCuring: false,
                gasDrying: false,
                electricElements: false,
                electricOveral: false,
                temperatureCuring: false,
                setpointCuring: false,
                temperatureDrying: false,
                setpointDrying: false,
                speed: false,
                conductivity: false,
                ph: false,
                bathTemp: false,
            },
            installationsParts: {
                bath1: false,
                bath2: false,
                bath3: false,
                bath4: false,
                bath5: false,
                bath6: false,
                bath7: false,
                bath8: false,
                bath9: false,
            },
            availableParts: [],
        }),
        props: {
            value: Boolean
        },
        mounted() {
            this.checkAllAvailibility()
        },
        computed: {
            show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
            }
        },
        watch: {
            'state.dates' (val) {
                let day = new Date(state.dates[0])
                day.setDate(day.getDate() + this.selectedDays)
                state.dates[1] = day
                this.dHandler.getData()
                this.checkAllAvailibility()
            },

            selectedDays(val) {
                let day = new Date(state.dates[0])
                day.setDate(day.getDate() + this.selectedDays)
                state.dates[1] = day
                this.dHandler.getData()
                this.checkAllAvailibility()
            },

            'state.loadedAll' (val) {
                if (val) {
                    let day = new Date(state.dates[0])
                    day.setDate(day.getDate() + this.selectedDays)
                    state.dates[1] = day
                    this.checkAllAvailibility()
                }
            },

            'selectedPart' (val) {
                if (val.split(' ')[0] == "Bath") {
                    this.checkBath (val.split(' ')[1])
                } else if (val.split(' ')[0] == "Drying") {
                    this.checkOven(state.drying_oven_dashboard)
                }
            }
        },
        methods: {
            checkOven (data){
                console.log(data[0])

                this.columnsAvailable = {
                    gasOven: false,
                    electricElements: false,
                    electricOveral: false,
                    setpointOven: false,
                    temperatureOven: false,
                    speed: false,
                    conductivity: false,
                    ph: false,
                    bathTemp: false,
                }

                if (data[0].hasOwnProperty('gas')) {
                    this.columnsAvailable.gasOven = true
                }

                if (data[0].hasOwnProperty('setpoint')) {
                    this.columnsAvailable.setpointOven = true
                }

                if (data[0].hasOwnProperty('temperature')) {
                    this.columnsAvailable.temperatureOven = true
                }
            },
            checkDataDifference () {
                let same = true
                // double loop sucks
                for (let i = 0; i<this.chips.length; i++){
                    let temp = this.chips[i].timestamps[0]
                    for (let j = 0; j<this.chips.length; j++) {
                        if (temp != this.chips[j].timestamps[0]) {
                            same = false
                        }
                    }      
                }

                let list = []

                if (same) {
                    for (let k = 0; k<this.chips[0].timestamps.length; k++) { 
                        let formatTimestamp = new Date(this.chips[0].timestamps[k])
                        formatTimestamp.setSeconds(0,0)
                        formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
                        list.push(formatTimestamp)
                    }
                    return list
                }
                else {
                    for (let k = 0; k<this.chips[0].timestamps.length; k++) { 
                        let formatTimestamp = new Date(this.chips[0].timestamps[k])
                        formatTimestamp.setSeconds(0,0)
                        formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
                        list.push(formatTimestamp)
                    }
                    return list
                }
            },
            returnElement ()
            {
                let chartData = {
                    labels: [],
                    datasets: [
                    ],
                    timestamp: [],
                    size: 8,
                    title: this.title,
                    description: this.description,
                    type: this.selectedType,
                }

                
                if (this.selectedType != 'Note' & this.selectedType != 'Doughnut') {
                    // add timestamp to it
                    chartData.labels = this.checkDataDifference()
                    for (let i = 0; i<this.chips.length; i++) {
                        chartData.datasets.push(this.chips[i].dataset)
                    }


                } else if(this.selectedType == 'Doughnut') {
                    // code for the
                    console.log(this.chips)
                    let newDatasets = []
                    let data = []
                    let dataset = JSON.parse(JSON.stringify(this.chips[0].dataset))
                    dataset.backgroundColor = []
                    for (let i = 0; i<this.chips.length; i++) {
                        

                        chartData.labels.push(this.chips[i].name)
                        let total = 0
                        for (let j = 0; j<this.chips[i].dataset.data.length; j++) {
                           total += this.chips[i].dataset.data[j]
                        }
                        data.push(total)
                        console.log(this.chips[i].backgroundColor)
                        dataset.backgroundColor.push(this.chips[i].dataset.backgroundColor)
                        
                        
                    }
                    dataset.data = data
                    newDatasets.push(dataset)
                    chartData.datasets = newDatasets

                }
                // add datasets
                
                // add size
                switch (this.selectedSize) {
                    case 'small':
                        chartData.size = 4
                        break;
                    case 'medium':
                        chartData.size = 6
                        break;
                    case 'big':
                        chartData.size = 12
                        break;
                }

                
                this.show = false
                this.$emit('newItem', chartData)
            },
            checkBath(val) {
                switch(val) {
                    case '1':
                        this.giveSensorsBath(state.bath1_data)
                    break;
                    case '2':
                        this.giveSensorsBath(state.bath2_data)
                    break;
                    case '3':
                        this.giveSensorsBath(state.bath3_data)
                    break;
                    case '4':
                        this.giveSensorsBath(state.bath4_data)
                    break;
                    case '5':
                        this.giveSensorsBath(state.bath5_data)
                    break;
                    case '6':
                        this.giveSensorsBath(state.bath6_data)
                    break;
                    case '7':
                        this.giveSensorsBath(state.bath7_data)
                    break;
                    case '8':
                        this.giveSensorsBath(state.bath1_data)
                    break;
                    case '9':
                        this.giveSensorsBath(state.bath1_data)
                    break;
                }
            },
            giveSensorsBath (data) {
                this.columnsAvailable = {
                    gasCuring: false,
                    gasDrying: false,
                    electricElements: false,
                    electricOveral: false,
                    temperatureCuring: false,
                    setpointCuring: false,
                    temperatureDrying: false,
                    setpointDrying: false,
                    speed: false,
                    conductivity: false,
                    ph: false,
                    bathTemp: false,
                }

                if (data[0].heating) {
                    this.columnsAvailable.bathTemp = true
                }

                if (data[0].ph) {
                    this.columnsAvailable.ph = true
                }

                if (data[0].conductivity) {
                    this.columnsAvailable.conductivity = true
                }
            },
            giveSensorsOven (data) {

            },
            checkAllAvailibility () {
                if(this.state.loadedAll) {
                    if(state.bath1_data.length > 1) {
                        this.availableParts.push('Bath 1')
                    }

                    if(state.bath2_data.length > 1) {
                        this.availableParts.push('Bath 2')
                    }

                    if(state.bath3_data.length > 1) {
                        this.availableParts.push('Bath 3')
                    }

                    if(state.bath4_data.length > 1) {
                        this.availableParts.push('Bath 4')
                    }

                    if(state.bath5_data.length > 1) {
                        this.availableParts.push('Bath 5')
                    }

                    if(state.bath6_data.length > 1) {
                        this.availableParts.push('Bath 6')
                    }

                    if(state.bath7_data.length > 1) {
                        this.availableParts.push('Bath 7')
                    }

                    if(state.bath8_data.length > 1) {
                        this.availableParts.push('Bath 8')
                    }

                    if(state.bath9_data.length > 1) {
                        this.availableParts.push('Bath 9')
                    }

                    if (state.drying_oven.length > 1) {
                        this.availableParts.push('Drying oven')
                    }

                    if (state.curing_oven.length > 1) {
                        this.availableParts.push('Curing oven')
                    }
                }
            },
            deleteItem (name) {
                let index = 0
                for( let i = 0; i<this.chips.length; i++) {
                    if (name == this.chips[i].name) {
                        index = i
                    }
                }

                this.chips.splice(index, 1)
            },
            change(value) {
                let newItem = {
                    name: this.selectedPart + ' ' + value + ' ' + new Date(this.state.dates[0]).toDateString() + ' - ' + new Date(this.state.dates[1]).toDateString(),
                    key: this.selectedPart + ' ' + value + ' ' + new Date(this.state.dates[0]).toDateString() + ' - ' + new Date(this.state.dates[1]).toDateString(),
                    dataset: {
                        label: this.selectedPart + ' ' + value,
                        data: [],
                        backgroundColor: this.color,
                        borderColor: this.color,
                        borderWidth: 1,
                        pointRadius: 1,
                        pointHoverRadius: 10
                    },
                    timestamps: []
                }

                let obj = this.returnData(value)
                newItem.dataset.data = obj.dataset
                newItem.timestamps = obj.timestamps
                let unique = true
                for( let i = 0; i<this.chips.length; i++) {
                    if (newItem.name == this.chips[i].name) {
                        unique = false
                    }
                }

                if (unique) {
                    this.chips.push(newItem)
                }
            },

            returnData(value) {
                if (this.selectedPart.split(' ')[0] == "Bath") {
                    let bathNumber = this.selectedPart.split(' ')[1]
                    
                    switch(bathNumber) {
                        case '1':
                            return this.returnBathDataSet(state.bath1_data, value)
                        break;
                        case '2':
                            return this.returnBathDataSet(state.bath2_data, value)
                        break;
                        case '3':
                            return this.returnBathDataSet(state.bath3_data, value)
                        break;
                        case '4':
                            return this.returnBathDataSet(state.bath4_data, value)
                        break;
                        case '5':
                            return this.returnBathDataSet(state.bath5_data, value)
                        break;
                        case '6':
                            return this.returnBathDataSet(state.bath6_data, value)
                        break;
                        case '7':
                            return this.returnBathDataSet(state.bath7_data, value)
                        break;
                        case '8':
                            return this.returnBathDataSet(state.bath8_data, value)
                        break;
                        case '9':
                            return this.returnBathDataSet(state.bath9_data, value)
                        break;
                }
                } else if (this.selectedPart.split(' ')[1] == "oven") {
                    let oven = this.selectedPart.split(' ')[0]
                    
                    switch(oven)
                    {
                        case 'Drying':
                            return this.returnOvenDataSet(state.drying_oven_dashboard, value)
                        break;
                        case 'Curing':
                            return this.returnOvenDataSet(state.curing_oven_dashboard, value)
                        break;
                }
                }
            },

            returnBathDataSet (data, sensor) {
                let dataset = []
                let timestamps = []
                let totalObject = {}
                for (let i = 0; i<data.length; i++) {
                    timestamps.push(data[i].timeStamp)
                    switch (sensor) {
                        case 'conductivity':
                            dataset.push(parseInt(data[i].conductivity.conductivity.replace('+', '')))                
                        break;
                        case 'ph':
                            dataset.push(data[i].ph.ph)
                       
                        break;
                        case 'temperature':
                            dataset.push(parseInt(data[i].heating.temperature.replace('+', '')))
                        break;
                    }
                }
                totalObject = {
                    dataset: dataset,
                    timestamps: timestamps
                }
                return totalObject
            },
            returnOvenDataSet (data, sensor) {
                let dataset = []
                let timestamps = []
                let totalObject = {}
                for (let i = 0; i<data.length; i++) {
                    timestamps.push(data[i].timeStamp)
                    switch (sensor) {
                        case 'temperature':
                            dataset.push(data[i].temperature)                
                        break;
                        case 'setpoint':
                            dataset.push(data[i].setpoint)
                       
                        break;
                        case 'gas':
                            dataset.push(data[i].gas)
                        break;
                    }
                }
                totalObject = {
                    dataset: dataset,
                    timestamps: timestamps
                }
                return totalObject
            },
        },

    }
</script>