<template>
<div class="team">
  <v-item-group mandatory class="mt-n4">
    <v-container>
      <v-row justify="center" class="space">
        <v-col
          cols="12"
          md="2"
          v-for="person in team" :key="person.key"
        >
        <v-item v-slot="{ active, toggle }" >
          <v-card class="text-center ma-2 rounded-xl" :color="active ?  '#757373' : 'white'" >
                <v-responsive class="pt-4">
                   <v-avatar size="120" class="red lighten-2">
                     <img src="../assets/img1.png" alt="" >
                   </v-avatar>
                </v-responsive>
                <v-card-text v-if="person.name">
                  <v-list-item-subtitle :color="active ?  'primary' : 'white'"  class="subheading">{{person.name}}</v-list-item-subtitle>
                </v-card-text>
                <v-card-actions class="ma-2 text-center" @click="toggle">
                  <v-btn name="toggle" @click=" onPress(person.name)">Show data</v-btn>
                </v-card-actions>
              </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-container>
    </v-item-group>
  </div>

</template>
    
    <script>
    import axios from 'axios'
    import api from '../api'
    import state from '../state'
    export default {
      data: () => ({
        selectItem: {
          name: ''
        },
        drawer: null,
        text: 'td',
        team: [],
        api,
        done: false,
        active: true,
        state
      }),
      computed: {
        currentProperties: function() {
          return this.selectItem  
        }
      },

    mounted () {
      const data = {
        ID: state.selectedID
      }
        axios.post(this.api.getUsers, data).then(res => {
            this.team = []
            let cnt = 0;
            let unique = []

            for (let i = 0; i<res.data.length; i++) {
                var m = new Date(res.data[i].timeStamp).getMinutes()
                var tm = Date.now()
                let uniqueB = true
                if( res.data[i].UserData.User !== '') {
                for (let j = 0; j<unique.length; j++) {
                  if (unique[j] == res.data[i].UserData.User) {
                    uniqueB = false
                  }
                }
                  if(uniqueB) {
                    this.team[cnt] = {
                        name: res.data[i].UserData.User,
                        role: res.data[i].UserData.User,
                        key: cnt,
                        timeAgo: m
                    }
                    unique[cnt] = res.data[i].UserData.User
                    cnt += 1;
                  }
                }
            }
            this.done = true;
            this.state.profileLoaded = true;
            this.state.selectedProfile = this.team[0]
        })
    },
      methods: {
        onPress(key) {
          this.state.selectedProfile = key
        }
      }
    }
    </script>
    
    <style scoped>
    .radius {
      border-radius: 10%;
          margin-top: 12px;
        margin-top: 12px;
      margin: 10px;
    }
    
    .v-list-item-group .v-list-item--active{
        color: white !important;
    }

    .profile-card {
        margin: 20px;
        width: 200px;
        height: 300px;
    }
    
    .icon {
        display: grid;
        margin-top: 50px;
    }

    .bottom {
        color: 'primary';
        background-color: 'primary';
        height: 50px;
    }
    </style>
    