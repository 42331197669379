<template>
  <div id="app" class="backg">
    <v-app-bar v-if="state.userData.rights[1] == 'admin' && state.customer != null">
      <v-btn icon class="hidden-xs-only" @click="state.customer = null" v-if="state.customer">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <h4 class="mt-3" v-if="state.customer">{{ state.customer.vor }}</h4>
      <v-spacer></v-spacer>
      <v-btn @click="uploadDialog = true" color="green" class="mr-5">Upload new file</v-btn>
      <v-btn @click="mail = true" class="mr-5" color="yellow">Send mail</v-btn>
    </v-app-bar>
    <v-carousel v-if="state.customer != null" :show-arrows="false" hide-delimiters height="1300">
      <v-carousel-item>
        <v-parallex><v-img class="content-pic" width="2500" height="500" src="../toppage.jpg">

          </v-img>
        </v-parallex>
        <customer v-if="uploadDialog == false && mail == false"></customer>
        <video playsinline autoplay muted loop width="2600" class="video">

          <source src="../window.mp4" type="video/mp4">

        </video>
      </v-carousel-item>
    </v-carousel>
    <CustomerCreation v-else></CustomerCreation>
    <Uploader v-model="uploadDialog" @refresh="refresh"></Uploader>
    <v-expand-transition>
      <Mail v-model="mail" />
    </v-expand-transition>
    <v-item-group v-if="state.customer">
      <v-img class="pic2" src="../wow.jpg">
        <v-row justify="end" class="move">
          <v-col md="3">

            <v-card class="mx-auto" color="#979da2" elevation="16" dark >
              <v-card-title>
                Insight
              </v-card-title>

              <v-card-text class="text-h5 font-weight-bold mr-15">
                "What if you could have complete insights into your powdercoating process?"
              </v-card-text>

            </v-card>
          </v-col>

          <v-row justify="left">
            <v-col md="4" class="md-ml auto mt-15">

              <v-card class="mx-auto normal rounded-tl-xl " elevation="16" outlined color="#bd7141" dark>
                <v-card-title>
                  {{titles[0]}}
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold">
                  {{ textFirst[0] }}
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col md="5" class="md-ml auto mt-15 mr-15">

              <v-card class="mx-auto normal rounded-tr-xl " elevation="16" outlined color="#979da2" dark>
                <v-card-title>
                  {{titles[1]}}
                </v-card-title>
                <v-card-text class="text-h5 font-weight-bold mb-15 pre-line">
                  {{ textFirst[1] }}
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" class="md-ml auto mt-15">

              <v-card class="mx-auto normal rounded-tl-xl " elevation="16" outlined color="#bd7141" dark>
                <v-card-title>
                  {{titles[2]}}
                </v-card-title>
                <v-card-text class="text-h5 font-weight-bold mb-15 pre-line">
                  {{ textFirst[2] }}
                </v-card-text>

              </v-card>
            </v-col>

          </v-row>
          <v-row justify="end">
            <v-col md="5" class="md-ml auto mt-15 mr-15">

              <v-card class="mx-auto normal rounded-tr-xl " elevation="16" outlined color="#979da2" dark>
                <v-card-title>
                  {{titles[6]}}
                </v-card-title>
                <v-card-text class="text-h5 font-weight-bold mb-15 pre-line">
                  {{ textFirst[8] }}
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-img>
      <v-img class="pic3" cover src="../demo.jpg">
        <v-row justify="end" class="move">
          <v-row justify="left">
            <v-col md="3" class="md-ml auto mt-15">

              <v-card class="mx-auto normal rounded-tl-xl " elevation="16" outlined color="#bd7141" dark >
                <v-card-title>
                  {{titles[3]}}
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold pre-line">
                  {{ textFirst[3] }}
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>

          <v-row justify="end">
            <v-col md="5" class="md-ml auto mt-15 mr-15">

              <v-card class="mx-auto normal rounded-tr-xl "  elevation="16" outlined color="#979da2" dark>
                <v-card-title>
                  {{titles[4]}}
                </v-card-title>
                <v-card-text class="text-h5 font-weight-bold mb-15">
                  {{ textFirst[4] }}
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" class="md-ml auto mt-15 ">

              <v-card class="mx-auto normal rounded-tl-xl " elevation="16" outlined color="#bd7141" dark>
                <v-card-title>
                  {{titles[5]}}
                </v-card-title>
                <v-card-text class="text-h5 font-weight-bold mb-15">
                  {{ textFirst[5] }}
                </v-card-text>

              </v-card>
            </v-col>

          </v-row>
          <v-row justify="end">
            <v-col md="5" class="md-ml auto mt-15 mr-15">

              <v-card class="mx-auto normal rounded-tl-xl " elevation="16" outlined color="#bd7141" dark>
                <v-card-title>
                  {{titles[7]}}
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold pre-line">
                  {{ textFirst[6] }}
                  {{ textFirst[7] }}
                  {{ textFirst[9] }}
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-img>
      <v-img class="pic4" cover src="../logbac.jpg">
        <v-row justify="end" class="move">
        </v-row>
      </v-img>
    </v-item-group>
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue'
import api from '../api'
import state from '../state'
import axios from 'axios'
import Customer from '../components/offerte/Customer.vue'
import Uploader from '../components/offerte/Uploader.vue'
import Mail from '../components/offerte/Mail.vue'
import CustomerCreation from '../components/offerte/CustomerCreation.vue'
export default {
  components: { Customer, Uploader, CustomerCreation, Mail },
  data: () => ({
    api,
    loaded: false,
    state,
    updateFiles: false,
    axios,
    textFirst: [
      "Indufinish Connect is a revolutionary platform that prioritizes making data insight accessible and actionable. Historically, extracting data from installations in a secure manner was a complex and cumbersome task. The traditional approach often involved exporting CSV files from installations, necessitating constant backups within the factory. Indufinish Connect overcomes this challenge with its cutting-edge development.\n\n",

      "With Indufinish Connect, all sensors installed at customer sites become fully transparent and manageable. Key metrics include:\n\n- Temperatures of ovens and baths\n- Chemical sensors in baths, such as conductivity and pH levels\n- Pump pulses converted into liters\n- Conveyor speeds\n- Energy consumption\n- Gas usage\n\n",

      "Our platform is meticulously organized across various pages, making it easy for both managers and technical teams to access the information they need. The intuitive layout ensures that critical data is always at your fingertips.\n\n",

      "One of our latest innovations, Indufinish Labs, allows customers to create their own customizable dashboards. Users can select where specific data points are displayed, compare different data sets in a single graph, and choose specific time periods for analysis. This feature supports various metrics and includes the ability to add notes, providing a comprehensive tool for in-depth data analysis.\n\n",

      "Indufinish Connect includes advanced cost analysis tools, enabling you to closely monitor the cost per unit based on the consumption data from your installations. This feature is invaluable for understanding and optimizing production costs.\n\n",

      "The platform also boasts automated analysis capabilities, such as monitoring the startup times of ovens, identifying delays, and more. The potential for incorporating machine learning into Indufinish Connect means the platform is poised for future advancements, continuously improving its value to your operations.\n\n",

      "At Indufinish, we value expandability. Our platform is designed to integrate seamlessly with additional sensors from the customer, and we are always open to discussions about expanding Indufinish Connect's capabilities to meet your specific needs.\n\n",

      "Indufinish Connect is not just a tool; it's a transformative solution that elevates your powder coating processes through superior data insights and process optimization. Embrace the future of powder coating with Indufinish Connect and unlock the endless possibilities for efficiency and productivity.\n\n",

      "Transform your decision-making process with the actionable insights provided by Indufinish Connect. By converting raw data into meaningful information, our platform empowers you to make informed decisions that drive continuous improvement. Unlock the full potential of your powder coating operations with data-driven strategies.",
      "Talk with the salesman to learn more and request a demo. Engage with us to explore how Indufinish Connect can revolutionize your operations today."
    ],
    titles: [
      "Seamless Data Integration",
      "User-Friendly Interface",
      "Introducing Indufinish Labs",
      "Cost Analysis and Efficiency",
      "Automated Analysis and Future Potential",
      "Expandability and Customization",
      "Unlock the Potential of Data-Driven Decision Making",
      "Join the Indufinish Connect Revolution"
    ],
    uploadDialog: false,
    caption: '',
    img1: '',
    imageData: null,
    mail: false,
    firebase: {},
    storage: {}
  }),

  mounted() {
    console.log(this.state)

  },
  watch: {
    'uploadDialog'() {
      this.refresh()
    }
  },
  methods: {
    gotoLink(item) {
      window.open(item.url, '_blank')
    },
    refresh() {
      if (this.uploadDialog == false) {
        this.mail = true
      }

    }
  }
}
</script>

<style>
.video {
  margin-top: 0px;
  filter: grayscale(100%);
}

.pic2 {
  bottom: 60px;
}

.scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 600px;
}

.pic3 {
  bottom: 50px;
}

.normal {
  background-color: rgb(15, 15, 3);
  color: black;
}

.contentf2 {
  position: absolute;
  z-index: 20000;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  margin-bottom: 40px;
  opacity: 1;
}


.content-pic {
  z-index: 20000;
  position: absolute;
  padding-bottom: 20px;
  opacity: 0.85;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  margin-bottom: 800px;

}

.backg {
  background-color: #191c1a;
}

.pre-line {
  white-space: pre-line;
}
</style>
