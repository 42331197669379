<template>
    <div id="app" class="flex justify-content-center">
      <sidebar @open-modal="showModal = true" />
      
      <SelectBar/>
      <WindowPre/>
      <div v-if="state.loadedAll">
            <BathData v-if="loadedTest" stitle="Bath 1" :data="state.bath1_data"/>
            <BathData v-if="loadedTest && state.bath2_data.length > 1" stitle="Bath 2" :data="state.bath2_data"/>
            <BathData v-if="loadedTest && state.bath3_data.length > 1" stitle="Bath 3" :data="state.bath3_data"/>
            <BathData v-if="loadedTest && state.bath4_data.length > 1" stitle="Bath 4" :data="state.bath4_data"/>
            <BathData v-if="loadedTest && state.bath5_data.length > 1" stitle="Bath 5" :data="state.bath5_data"/>
            <NoData v-show="state.loadedAll & state.bath1_data.length < 1" @close-modal="state.loadedAll = false" />  
          <v-divider/>
        <NoRightsModal v-show="showModal" @close-modal="showModal = false" />
      </div>
    </div>
  </template>
    
  <script>
  import Sidebar from '../components/Sidebar.vue'
  import api from '../api'
  import state from '../state'
  import tracking from '../script/tracking/tracking'
  import axios from 'axios'
  import NoRightsModal from '../components/NoRightsModal.vue'
  import BathData from '../components/BathData.vue'
  import dataHandler  from '../script/Calculations/dataHandler'
  import SelectBar from '../components/SelectBar.vue'
  import NoData from '../components/Homepage/NoData.vue'
  import LoadScreen from '../components/LoadScreen.vue'
import WindowPre from '../components/pretreatment/WindowPre.vue'
  export default {
    components: { Sidebar, NoRightsModal,BathData, SelectBar, NoData, WindowPre},
    data: () => ({
      selectItem: 0,
      allUsers: null,
      trackingjs: new tracking(),
      currentTime: 1,
      dHandler: new dataHandler(),
      showModal: false,
      alarmData: {
        totalCritical: 0,
        totalTimeLost: 0
      },
      api,
      loaded: false,
      loadedTest: false,
      state,
    }),
    watch: {
    'state.dates' (val) {
      this.trackingjs.trackAction(this.state.dates, 'Pre treatment', "change period of data")
      this.dHandler.getData()
      },
    'state.loadedAll' (val) {
      this.loadedTest = this.state.loadedAll
    }
    },
    mounted() {
      this.trackingjs.trackAction('', 'Pre treatment', "")
      this.state.currentPage = "Pre treatment"

      if (this.state.dates.length == 0) {
      let date = new Date()
      date.setHours(0,0,0,0)
      this.state.dates[0] = date.toJSON()
    }
      //this.getApiCall();
      this.$ga.page({
        page: '/Home',
        title: 'Home page',
        location: window.location.href
      });
      if (this.state.bath1_data.length < 1) {
        this.dHandler.getData()
      }

      this.loadedTest = true
    },
  }
  </script>
    
  <style scoped>
  .border {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10%;
    text-decoration: none;
  }
  
  .space {
    margin-left: -100px;
    margin-right: -100px;
  }
  
  .select {
    max-width: 200px;
    max-height: 500px;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .top {
    margin-top: 80px;
  }
  
  .v-list-item-group .v-list-item--active {
    color: white !important;
  }
  
  .center-title {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(255, 248, 248);
  }
  
  .center-title2 {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(131, 127, 127);
  }
  
  .cost-graph {
    margin-left:20px;
  }
  
  .pie-chart {
    width: auto;
      margin: auto;
  }
  </style>
    