import api from '../../api'
import state from '../../state'
import axios from 'axios'

export default class tracking {
    constructor () {
      state
      api
    }

    trackAction (content_t, page_t, action_t) {
        let action = {
            ip: "",
            user: "",
            page: "",
            action: "",
            time: "",
            timestamp: 0,
            gioResult: [{}],
            ipResult: [{}],
            content: {}
        }
        const date = new Date()
        action.time = date.toString()
        action.timestamp = date - 1
        let test = {
            user: 'geenidee'
        }
        action.user = state.userData.username
        action.page = page_t
        action.content = content_t
        action.action = action_t
        // get pinpoint location
        if ("geolocation" in navigator) {
            // Prompt user for permission to access their location
            navigator.geolocation.getCurrentPosition(
              // Success callback function
              (position) => {
                // Get the user's latitude and longitude coordinates
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
          
                // Do something with the location data, e.g. display on a map
                console.log(`Latitude: ${lat}, longitude: ${lng}`);

                // get location
                var requestOptions = {
                    method: 'GET',
                  };
                  
                  fetch("https://api.geoapify.com/v1/geocode/reverse?lat=" + lat.toString() + "&lon=" + lng.toString() + "&apiKey=ed0248b2aa5a42fab7be5265186d8477", requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        action.gioResult = result.features[0].properties
                        fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=ed0248b2aa5a42fab7be5265186d8477", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            action.ipResult = result
                            action.ip = result.ip
                            return axios.post(api.action, action).then(res => {
                                console.log(test)
                            })
                        })
                        .catch(error => console.log('error', error));
                    })
                    .catch(error => console.log('error', error));
              },
              // Error callback function
              (error) => {
                // Handle errors, e.g. user denied location sharing permissions
                console.error("Error getting user location:", error);
              }
            );
          } else {
            // Geolocation is not supported by the browser
                            // get location
                            var requestOptions = {
                                method: 'GET',
                              };
                              
                              fetch("https://api.geoapify.com/v1/geocode/reverse?lat=" + lat.toString() + "&lon=" + lng.toString() + "&apiKey=ed0248b2aa5a42fab7be5265186d8477", requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    action.gioResult = result.features[0].properties
                                    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=ed0248b2aa5a42fab7be5265186d8477", requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        action.ipResult = result
                                        action.ip = result.ip
                                        return axios.post(api.action, action).then(res => {
                                            console.log(action)
                                        })
                                    })
                                    .catch(error => console.log('error', error));
                                })
                                .catch(error => console.log('error', error));
          }        

    }

    trackPage (page) {
        
    }

    getIp () {

    }

}