<template>
  <div>
   <v-row class="">
      <v-col md="9" :style=" {background: 'black'}">
        <v-img
      class="fc"
      full-screen
      src="../bac.jpg"
    >
        </v-img>
      </v-col>
      <v-col md="3" :style=" {background: 'white'}">
        <v-card
          class="mx-auto login-marg"
          elevation="0"
          :style=" {background: 'transparent'}"
          max-width="448"
          rounded="lg"
        >
        <div class="text-h4 text-medium-emphasis text-center">Welcome back</div>
        <v-divider class="border-opacity-25 mb-6" color="#be1a20"></v-divider>
          <div class="text-subtitle-1 text-medium-emphasis">Account</div>

          <v-text-field
            density="compact"
            placeholder="Email address"
            prepend-inner-icon="mdi-email-outline"
            variant="outlined"
            v-model="username"
          ></v-text-field>

          <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
            Password

          </div>

          <v-text-field
            :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
            :type="visible ? 'text' : 'password'"
            density="compact"
            placeholder="Enter your password"
            prepend-inner-icon="mdi-lock-outline"
            variant="outlined"
            @click:append-inner="visible = !visible"
            v-model="password"
          ></v-text-field>

          <v-btn
            block
            class="mb-8 mt-2"
            color="primary"
            size="large"
            variant="tonal"
            @click="login"
          >
            Log In
          </v-btn>
        </v-card>
      </v-col>
      
  </v-row>

    <SavedModal v-show="showModal" @close-modal="showModal = false" />
    </div>

</template>

<script>
import api from '../api'
import axios from 'axios'
import state from '../state'
import SavedModal from './SavedModal.vue'
import CheckLicense from '../script/tracking/checkLicense.js'
export default {
  name: 'Login',
  components: { SavedModal },
  data: () => ({
    checkLicense: new CheckLicense(),
    showPassword: false,
    username: '',
    password: '',
    loginFailed: false,
    isLoading: false,
    api,
    visible: false,
    showModal: false,
    state
  }),
  computed: {
    canShowPassword () {
      return this.showPassword
    }
  },
  mounted () {
    this.$router.push({ path: '/Home', replace: true })
    window.addEventListener('keypress', e => {
      if (e.key === 'Enter') {
        this.login()
      }
    })
  },
  methods: {
    backukp () {
      axios.post(this.api.login, data).then(res => {
        this.isLoading = false
        if (!res.data[0]) {
          this.showModal = true
          this.loginFailed = true
        } else {
          this.state.login = true
          this.state.justLoggedIn = true
          this.state.userData = res.data[0]
          this.state.selectedID = res.data[0].rights[0]
        }
      })
    },

    
    login () {
      const { createHash } = require('crypto');

      const data = {
        username: this.username.toLocaleLowerCase(),
        password: createHash('sha256').update(this.password + 'bier').digest('hex')
      }
      axios.post(this.api.login, data).then(res => {
        this.isLoading = false
        if (!res.data[0]) {
          this.showModal = true
          this.loginFailed = true
        } else {
          this.state.login = true
          this.state.justLoggedIn = true
          this.state.userData = res.data[0]
          if (!this.state.userData.license) {
            this.state.userData.license = 'premium'
          }

          if (res.data[0].vor) {
            this.state.customer = res.data[0]
          }
          this.state.selectedID = res.data[0].rights[0]
        }
      })
    },
    invertShowPassword () {
      this.showPassword = !this.showPassword
    }
  }
}
</script>
<style scoped lang="scss">
body {
  background: url("../assets/background.jpg") no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.login-marg{
  margin-top: 300px;
  margin-left: 300px;
}
.fc {
  height:100vh;
}
</style>

