import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/main.css'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'echarts'
import VueAnalytics from 'vue-analytics';

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-teal/theme.css'
Vue.use(VueAnalytics, {
  id: 'G-PSNFSB1ZE9',
  router,
  autoTracking: {
    screenview: true
  }
});
Vue.use(Vuetify)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(PrimeVue)
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#be1a20',
        secondary: '#0069b9',
        accent: '#757273',
        error: '#b71c1c',
        success: '#009640',
        background: '#212529',
        green: '#029a6a',
        yellow: '#d09a0d',
        orange: '#c15f20',
        purple: '#970e76',
      }
    }
  },
  icons: {
    iconfont: 'fa' || 'md'
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
