import api from '../../api'
import state from '../../state'
import axios from 'axios'

export default class checkLicense {
    constructor () {
      state
      api
    }

    async checkLicense () {
        let p_data = {
            timestamp: 0,
            ip: '3',
            username: ''
        }

        const date = new Date()
        p_data.username = state.userData.username
        p_data.timestamp = date - 1
        var requestOptions = {
            method: 'GET',
          };
          
                fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=ed0248b2aa5a42fab7be5265186d8477", requestOptions)
                .then(response => response.json())
                .then(result => {
                    p_data.ip = result.ip
                        return axios.post(api.checkLicense, p_data).then(res => {
                            console.log(res.data)
                            if (res.data == 'nothing') {
                                console.log(res)
                                state.noAcces = false
                            } else {
                                state.noAcces = false
                            }
                            
                        })
                })
                .catch(error => console.log('error', error));
    }
}