<template>
    <div class="pa-4 text-center">
        <v-dialog v-model="show" max-width="1000">
            <v-card rounded="lg">
                <v-card-title class="d-flex justify-space-between align-center">
                    <div class="text-h5 text-medium-emphasis ps-2">
                        Send email
                    </div>

                    <v-btn icon="mdi-close" variant="text" @click="isActive.value = false"></v-btn>
                </v-card-title>

                <v-divider class="mb-4"></v-divider>

                <v-card-text>
                    <div class="text-medium-emphasis mb-4">
                        Which language would you like to use?
                    </div>
                    <v-select v-model="selectedLanguage" :items="language" />
                    <div class="mb-2 mt-5">Your Email</div>
                    <v-text-field v-model="email"></v-text-field>
                    <div class="mb-2 mt-5">Customers mail</div>
                    <v-text-field v-model="state.customer.email"></v-text-field>
                    <div class="mb-2 mt-5">Subject</div>
                    <v-text-field v-model="newObject.name" label="What name should be displayed"></v-text-field>
                    <div class="mb-2 mt-5">Description</div>

                    <v-textarea class="mb-2" auto-grow rows="5" v-model="newObject.description" variant="outlined"
                        persistent-counter></v-textarea>
                </v-card-text>

                <v-divider class="mt-2"></v-divider>

                <v-card-actions class="my-2 d-flex jufstify-end">
                    <v-btn class="text-none font-weight-bold " v-if="!isUploading" color="primary" variant="text"
                        @click="send">Send</v-btn>
                    <v-progress-circular indeterminate v-if="isUploading" color="primary"></v-progress-circular>
                    <v-btn class="text-none" rounded="xl" @click="show = false">Cancel</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import state from '../../state';
import axios from 'axios'
import {Email} from '../../assets/smtp.js'
export default {
    data: () => ({
        state,
        isUploading: false,
        email: '',
        newObject: {
            name: '',
            description: '',
            type: '',
            url: '',
            timestamp: '',
            Email,
            id: ''
        },
        selectedLanguage: 'English',
        language: [
            'English',
            'Polish',
            'Dutch',
            'German'
        ],
        axios,
    }),
    props: {
        value: Boolean
    },
    mounted() {
        this.change('English')
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        'selectedLanguage'(val) {
            this.change(this.selectedLanguage)
        }
    },
    methods: {
        send() {
            this.isUploading = true
            axios.post('https://us-central1-indusotrage.cloudfunctions.net/Indufinish/sendMail/', {
                to: this.state.customer.email,
                subject: this.newObject.name,
                text: this.newObject.description,
                html: this.newObject.description.replace(/\n/g, '<br>')
            }).then((res) => {
                this.isUploading = false
                this.show = false
            })
        },
        change (val) {
            if (val == 'English') {
                this.newObject.name = "New Documentation"
                this.newObject.description = "Dear Mr./Mrs. " + this.state.customer.lastname + ",\n\nWe are pleased to inform you that a document has just been uploaded to your account on our website. Please log in to your account to view the document.\n\nThis is an automatically generated email, so you cannot reply to it. Please visit https://www.indufinish.app and log in to access your account and view the document.\n\nKind regards,\n\nIndufinish"
            } else if(val == 'Dutch') {
                this.newObject.name = "Nieuwe Documentatie"
                this.newObject.description = "Geachte heer/mevrouw " + this.state.customer.lastname + ",\n\nWe zijn verheugd u te kunnen informeren dat er zojuist een document is geüpload naar uw account op onze website. Log alstublieft in op uw account om het document te bekijken.\n\nDit is een automatisch gegenereerde e-mail, dus u kunt hier niet op reageren. Bezoek https://www.indufinish.app en log in om toegang te krijgen tot uw account en het document te bekijken.\n\nMet vriendelijke groet,\n\nIndufinish"
            } else if (val == 'German') {
                this.newObject.name = "Neue Dokumentation"
                this.newObject.description = "Sehr geehrter Herr/Frau " + this.state.customer.lastname + ",\n\nWir freuen uns, Ihnen mitteilen zu können, dass gerade ein Dokument in Ihrem Konto auf unserer Website hochgeladen wurde. Bitte melden Sie sich in Ihrem Konto an, um das Dokument anzusehen.\n\nDies ist eine automatisch generierte E-Mail, daher können Sie nicht darauf antworten. Bitte besuchen Sie https://www.indufinish.app und loggen Sie sich ein, um auf Ihr Konto zuzugreifen und das Dokument anzusehen.\n\nMit freundlichen Grüßen,\n\nIndufinish"
            } else if (val == 'Polish') {
                this.newObject.name = "Nowa Dokumentacja"
                this.newObject.description = "Szanowny Panie/Pani " + this.state.customer.lastname + ",\n\nZ przyjemnością informujemy, że właśnie został przesłany dokument na Państwa konto na naszej stronie internetowej. Prosimy zalogować się do swojego konta, aby obejrzeć dokument.\n\nTo jest automatycznie generowany e-mail, więc nie można na niego odpowiadać. Proszę odwiedzić https://www.indufinish.app i zalogować się, aby uzyskać dostęp do swojego konta i zobaczyć dokument.\n\nZ poważaniem,\n\nIndufinish"
            }
        }
    }

}
</script>