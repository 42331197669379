<template>
  <div class="pa-4 text-center">
    <v-dialog v-model="show" max-width="600">
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-space-between align-center">
          <div class="text-h5 text-medium-emphasis ps-2">
            Upload file
          </div>

          <v-btn icon="mdi-close" variant="text" @click="isActive.value = false"></v-btn>
        </v-card-title>

        <v-divider class="mb-4"></v-divider>

        <v-card-text>
          <div class="text-medium-emphasis mb-4">
            Upload a file here, for example a layout
          </div>
          <div class="mb-2 mt-5">Name</div>
          <v-text-field v-model="newObject.name" label="What name should be displayed"></v-text-field>
          <div class="mb-2 mt-5">Description</div>

          <v-textarea :counter="300" class="mb-2" label="You want the customer to see a description?" rows="2"
            v-model="newObject.description" variant="outlined" persistent-counter></v-textarea>
          <div class="mb-2 mt-5">Type</div>
          <v-text-field label="Which kind of file is it? Layout? (Make sure you spell it correctly, it will be sorted based on your input)" v-model="newObject.type">Column</v-text-field>
          <v-file-input v-model="img1" />
        </v-card-text>

        <v-divider class="mt-2"></v-divider>

        <v-card-actions class="my-2 d-flex jufstify-end">
          <v-btn class="text-none font-weight-bold " v-if="!isUploading" color="primary" variant="text"
            @click="onUpload">add</v-btn>
          <v-progress-circular indeterminate v-if="isUploading" color="primary"></v-progress-circular>
          <v-btn class="text-none" rounded="xl" @click="show = false">Cancel</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import state from '../../state';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from 'axios'
import { collection, addDoc, getDocs, doc, getFirestore } from "firebase/firestore";
export default {
  data: () => ({
    state,
    img1: {},
    isUploading: false,
    newObject: {
      name: '',
      description: '',
      type: '',
      url: '',
      timestamp: '',
      id: ''
    },
    axios,
  }),
  props: {
    value: Boolean
  },
  mounted() {
    this.newObject.id = this.state.customer.vor
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
  },
  methods: {
    onUpload() {
      console.log(this.img1)
      this.isUploading = true
      const mountainsRef = ref(this.state.storage, this.img1.name)
      uploadBytes(mountainsRef, this.img1).then((snapshot) => {
        getDownloadURL(mountainsRef).then((url) => {
          this.isUploading = false
          this.newObject.url = url
          this.newObject.timestamp = new Date() - 1
          this.newObject.id = this.state.customer.vor
          console.log(this.state.firestore)
          addDoc(collection(this.state.firestore, "Files"), this.newObject).then((res) => console.log(res))
          this.show = false
        })
        console.log('worked?')
      }).catch(err => {
        console.log(err)
        this.isUploading = false
      })
    },

  }

}
</script>