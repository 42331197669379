import { h } from 'vue';
import state from '../../state'
export default class electricDataHandler {
    constructor () {
        state,
        this.hasGeneral = true
        this.hasDrying = true
        this.hasCuring = true
    }

    prepareData () {
      let lastTimeStamp = 0;
      let totalElectricUnConverted = 0;
      let totalTimeLost = 0;
      let cnt = 0;
      let tempE = 0;
      let cntGraph = 0;
      state.generalElGraph = [0]
      state.dryingOvenElGraph = [0]
      let lastValue = 0;
      let averageValue = 0
      let minValue = 0
      let indexesAboveZero = 0
      let maxValue = 0
      
      // sort the list based on date and time
      if (state.electricData.length > 0) {
        state.electricData.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
      }

      // start looping trough the electric data
      for (let i = 0; i < state.electricData.length; i++) {
        let total = 0;
        let totalGeneral = 0;
        let indexData = state.electricData[i]
        
        // calculate time between last and this 
        cnt += 1
        
        totalGeneral += this.filterGeneral(indexData, cntGraph)

        if (this.hasGeneral == false) {
          totalGeneral += this.filterDrying(indexData, cntGraph, i)
          totalGeneral += this.filterCuring(indexData, cntGraph, i)
        } else {
          this.filterDrying(indexData, cntGraph, i)
          this.filterCuring(indexData, cntGraph, i)
        }

            tempE += totalGeneral
            state.electricData[i].type = 'el'
            totalElectricUnConverted += totalGeneral

            let diff = (parseInt(state.electricData[i].timeStamp) - parseInt(lastTimeStamp)) / 1000 / 60 / 60
            totalTimeLost += diff * totalGeneral
            if (new Date(state.electricData[i].timeStamp) == null) {
            }

            cntGraph += 1
            state.electricData[i].cost = totalGeneral * state.Settings.electric_price

          if (state.electricData.length == i) {
            dn = true
          }
          if (totalGeneral > 0) {
        
            if (totalGeneral > maxValue) {
              maxValue = totalGeneral
            } else if(maxValue == 0) {
              maxValue = totalGeneral
            }
      
            if (totalGeneral < minValue) {
      
            } else if(minValue == 0) {
              minValue = totalGeneral
            }
    
            averageValue += totalGeneral
            indexesAboveZero += 1
          }
          lastTimeStamp = state.electricData[i].timeStamp
          lastValue = totalGeneral
        }

      if (this.hasGeneral == false) {
        state.generalElGraph = []
      }

      if (this.hasDrying == false) {
        state.dryingOvenElGraph = []
      }

      if (this.hasCuring == false) {
        state.curingOvenElGraph = []
      }


      state.electricData.min = minValue * state.factor
      state.electricData.avg = (averageValue / indexesAboveZero) * state.factor
      state.electricData.max = maxValue * state.factor
      
      state.electricUsage = Math.floor(totalElectricUnConverted)

    }

    filterGeneral (indexData, cntGraph) {
      let total = 0
      state.generalElGraph[cntGraph] = {}
      if (indexData.hasElectrical) {
        state.generalElGraph[cntGraph].totalGeneral = parseInt(indexData.overal_electric.toString().replace('+', ''))
        total += parseInt(indexData.overal_electric.toString().replace('+', ''))
        state.generalElGraph[cntGraph].hasGeneral = true;
        this.hasGeneral = true
      }else {
        this.hasGeneral = false
      }
      state.generalElGraph[cntGraph].timeStamp = indexData.timeStamp
      return total
    }

    filterDrying (indexData, cntGraph, i) {
      state.dryingOvenElGraph[cntGraph] = {}
      let total = 0
      if (indexData.hasDryingEl1) {
        
        this.hasDrying = true
        total += parseInt(indexData.drying_oven.El1Usage.toString().replace('+', ''))
        state.electricData[i].drying_oven.totalEl1 += parseInt(indexData.drying_oven.El1Usage.toString().replace('+', ''))
        state.dryingOvenElGraph[cntGraph].element1 = parseInt(indexData.drying_oven.El1Usage.toString().replace('+', ''))
        state.dryingOvenElGraph[cntGraph].hasElements = true;
        state.dryingElements = 1
      } else if (state.dryingOvenElGraph.length < 1){
        this.hasDrying = false
      }

      // element 2
      if (indexData.hasDryingEl2) {
        total += indexData.drying_oven.el2_usage
        state.electricData[i].drying_oven.totalEl2 += indexData.drying_oven.el2_usage
        state.dryingOvenElGraph[cntGraph].element2 = parseInt(indexData.drying_oven.el2_usage.toString().replace('+', ''))
      }

      // element 3
      if (indexData.hasDryingEl3) {
        total += indexData.drying_oven.el3_usage
        state.electricData[i].drying_oven.totalEl3 += indexData.drying_oven.el3_usage
        state.dryingOvenElGraph[cntGraph].element3 = parseInt(indexData.drying_oven.el3_usage.toString().replace('+', ''))
      }

      // element 4
      if (indexData.hasDryingEl4) {
        total += indexData.drying_oven.el4_usage
        state.electricData[i].drying_oven.totalEl4 += indexData.drying_oven.el4_usage
        state.dryingOvenElGraph[cntGraph].element4 = parseInt(indexData.drying_oven.el4_usage.toString().replace('+', ''))
      }
      if (!state.dryingOvenElGraph[cntGraph].element1) {
        state.dryingOvenElGraph[cntGraph].element1 = 0
      }
      state.dryingOvenElGraph[cntGraph].timeStamp = indexData.timeStamp
      return total
    }

    filterCuring (indexData, cntGraph, i) {
      state.curingOvenElGraph[cntGraph] = {}
      let total = 0
              // element 1 
              if (indexData.hasCuringEl1) {
                this.hasCuring = true
                if (indexData.curing_oven.El1Usage.toString().replace('+', '')) {
                  total += parseInt(indexData.curing_oven.El1Usage.toString().replace('+', ''))
                  state.electricData[i].curing_oven.totalEl1 += indexData.curing_oven.el1_usage
                  state.curingOvenElGraph[cntGraph].element1 = parseInt(indexData.curing_oven.El1Usage.toString().replace('+', ''))
                  state.curingOvenElGraph[cntGraph].hasElements = true;
                  state.curingOvenElGraph[cntGraph].timeStamp = indexData.timeStamp
                }

                state.curingElements = 1
              } else if (state.curingOvenElGraph.length < 1){
                this.hasCuring = false
              }
              // element 2
              if (indexData.hasCuringEl2) {
                if (indexData.curing_oven.El2Usage.toString().replace('+', '')) {
                total += parseInt(indexData.curing_oven.El2Usage.toString().replace('+', ''))
                state.electricData[i].curing_oven.totalEl2 += indexData.curing_oven.el2_usage
                state.curingOvenElGraph[cntGraph].element2 = parseInt(indexData.curing_oven.El2Usage.toString().replace('+', ''))
                state.curingOvenElGraph[cntGraph].hasElements = true;
                state.curingElements = 2
                }
              } else {
                state.curingOvenElGraph[cntGraph].element2 = 0
              }
      
              // element 3
              if (indexData.hasCuringEl3) {
                if (indexData.curing_oven.El3Usage.toString().replace('+', '')) {
                total += parseInt(indexData.curing_oven.El3Usage.toString().replace('+', ''))
                state.electricData[i].curing_oven.totalEl3 += indexData.curing_oven.el3_usage
                state.curingOvenElGraph[cntGraph].element3 = parseInt(indexData.curing_oven.El3Usage.toString().replace('+', ''))
                state.curingOvenElGraph[cntGraph].hasElements = true;
                
                }
                state.curingElements = 3
              } else {
                state.curingOvenElGraph[cntGraph].element3 = 0
              }
      
              // element 4
              if (indexData.hasCuringEl4) {
                total += parseInt(indexData.curing_oven.El4Usage.toString().replace('+', ''))
                state.electricData[i].curing_oven.totalEl4 += indexData.curing_oven.el4_usage
                state.curingOvenElGraph[cntGraph].element4 = parseInt(indexData.curing_oven.El4Usage.toString().replace('+', ''))
                state.curingOvenElGraph[cntGraph].hasElements = true;
                state.curingElements = 3
              } else {
                state.curingOvenElGraph[cntGraph].element4 = 0
              }
      
              // element 5
              if (indexData.hasCuringEl5) {
                total += indexData.curing_oven.el5_usage
                state.electricData[i].curing_oven.totalEl5 += indexData.curing_oven.el5_usage
              }
      
              // element 6
              if (indexData.hasCuringEl6) {
                total += indexData.curing_oven.el6_usage
                state.electricData[i].curing_oven.totalEl6 += indexData.curing_oven.el6_usage
              }
              return total
    }


}