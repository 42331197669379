<template>
    <div>
      <CostDashboard v-if="state.loadedAll"></CostDashboard>
      <ChainDashboard v-if="state.loadedAll"/>
      <GasDashboard v-if="state.loadedAll"> </GasDashboard>
      <ElectricDashboard v-if="state.loadedAll"></ElectricDashboard>
      <ChemicalDashboard v-if="state.loadedAll"/>
      <div v-if="state.costData.totalCost"><NoData v-show="state.loadedAll & state.costData.totalCost == 0" @close-modal="state.loadedAll = false" /></div>
      
    </div>
</template>

<script>
import api from '../api'
import axios from 'axios'
import tracking from '../script/tracking/tracking'
import state from '../state'
import WindowData from './Homepage/WindowData.vue'
import dataHandler from '../script/Calculations/dataHandler'
import CostDashboard from './Homepage/CostDashboard.vue'
import ElectricDashboard from './Homepage/ElectricDashboard.vue'
import GasDashboard from './Homepage/GasDashboard.vue'
import ChainDashboard from './Homepage/ChainDashboard.vue'
import Settings from '../script/Settings'
import { getCurrentInstance } from 'vue';
import { GChart } from 'vue-google-charts/legacy'
import NoData from './Homepage/NoData.vue'
import ChemicalDashboard from './Homepage/ChemicalDashboard.vue'
export default {
  components: { ChemicalDashboard, CostDashboard, ElectricDashboard, GasDashboard, ChemicalDashboard, NoData, ChainDashboard },
  data: () => ({
    currentTime: 1,
    dHandler: new dataHandler(),
    settings: new Settings(),
    trackingjs: new tracking(),
    elecDone: false,
    selectedUser: 'OP',
    api,
    userD: {
      name: ''
    },
    loaded: false,
    state,
  }),

  mounted() {
    //this.state.dates[0] = Date.now() - 1
    if (!this.state.loadedAll) {
      this.settings.getSettings().then( (status) => {
      this.dHandler.getData().then(status => {
      })
    },
    )
    }

    this.trackingjs.trackAction("", "Homepage", "none")

    
    window.addEventListener('zoom', this.handleResize())
  },

  watch: {
    'state.date.select': function (newVal, oldVal) {
      this.settings.getSettings()
      this.dHandler.getData()
    },

    'state.dates': function (newVal, oldVal) {
      this.trackingjs.trackAction(this.state.dates, 'Homepage', "change date")
      this.settings.getSettings()
      this.dHandler.getData()

    },

    'state.selectedID': function (newVal, oldVal) {
      this.settings.getSettings()
      this.dHandler.getData()
    },
  },

  methods: {
    selectWidget (widget) {
        this.state.selectedWidget = widget
    },
    handleResize () {

    }
  }
}
</script>
<style>
.border {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 10%;
  text-decoration: none;
}

.space {
  margin-left: -100px;
  margin-right: -100px;
}

.select {
  max-width: 200px;
  max-height: 500px;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 20px;
}

.top {
  margin-top: 80px;
}

.v-list-item-group .v-list-item--active {
  color: white !important;
}
.container {
  max-width: 1500px;
  padding-left: 14rem;
  padding-right: 14rem;  
}

.center-title {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: '#FFF';
}

.center-title2 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: '#FFF';
}

.center-title3 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: '#0000';
}

.cost-graph {
  margin-left:0px;
  margin-right: 100px;
}

</style>