<template>
        <v-col cols="12" md="12" ref="col">
        <v-item>
          <v-card :color="state.black ? '#1e1e1e' : 'white'" dark height="400" title="oven">
            <v-app-bar
                  dark class="text-h6">{{stitle}}
                  </v-app-bar>
            <GChart v-if="this.loaded" class="cost-graph d-flex mt-2" type="AreaChart" :data="chartData"
                    :options="chartOptions" />
          </v-card>
        </v-item>
      </v-col>
  </template>
  
  <script>
  import { GChart } from 'vue-google-charts/legacy'
  import state from '../../../state'
  export default {
      components: {GChart },
    data: () => ({
        chartData: [
      ['Time', 'Gasdata'],
      ['Pump', 1000],
      ['Lowlevel', 1170],
      ['Highlevel', 660],
      ['E stop', 1030]
    ],
    chartOptions: {
      title: 'Gas (m³/h)',
      series: {
        0: { color: '#c15f20', areaOpacity: 0.85 },
        1: {color: '#c15f20'},        
        2: { color: '#b70d7f'}
      },
      hAxis: {
        format: 'dd/MM HH:mm',
        textStyle:{color: '#7c7c7c'},
        gridlines: {
            count: -1,
            units: {
              days: {format: ['MMM dd']},
              hours: {format: ['HH:mm', 'ha']},
              color: '#434242'
            },
          },
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'},        
        gridlines: {
        },
      },
      backgroundColor: 'transparent',
      chartArea: { left: 40, top: 20, width: "85%", height: "60%" },
      legend: {position: 'bottom'},
      width: window.innerWidth / 1.4,
      height:  window.innerHeight / 2.9
    },
    loaded: false,
    state,
  }),
  props: {
        stitle: String,
        data: Array,
        amountElements: Number,
  },
  watch: {
    'state.gasData.length' (val) {
      this.editGraph()
    }
  },
  mounted() {      
    if (state.black == false) {
        this.chartOptions.backgroundColor = "white"
    }

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.chartOptions.width = this.$refs.col.clientWidth
    this.chartOptions.height = this.$refs.col.clientHeight- 100
    this.editGraph() 
  },

  methods: {
    onResize() {
      this.chartOptions.width = this.$refs.col.clientWidth
      this.chartOptions.height = this.$refs.col.clientHeight - 100
    },
    editGraph() {
      this.loaded = false;
      this.chartData = []
      this.chartOptions.title = ''
      this.chartData[0] = ['Time', 'Burner 1'];
      let j = 1
      let comp = 1
      if (this.data) {
        this.data = this.data.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
        for (let i = 0; i<this.data.length; i++) {
          if (this.data[i]) {
            if(this.data[i].timeStamp) {
              let formatTimestamp = new Date(this.data[i].timeStamp)
            formatTimestamp.setSeconds(0,0)
            formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
            let added = false
            this.chartData[j] = [formatTimestamp, parseFloat(this.data[i].burner1_usage.toString().replace('+', '') * this.state.factor)]
            added = true
            j += 1
            }
          } else {
            comp += 1
          }
          if ((i+comp) == this.data.length -1) {
                this.loaded = true;
          } else if ( (i + 1) == this.data.length)  {
            this.loaded = true;
          }
        }
      }

     
    
    if (j==1) {
      this.chartData[1] = [0, 0];
      this.loaded = true
    }
    },

  }
}
  </script>
  <style>
  .pie-chart {
    width: auto;
      margin: auto;
  }
  .icon-play-circle{
    font-size: 100px;
      position: absolute;
      top: 75%;
      left: 45%;
      opacity: 1;
      color: blue;
  }
  .margin-bottom{
    margin-bottom: 100px;
  }
  .blurred {
    background-color: #eeeeee;
      flex: inherit;
      width: 85%;
      left: 100px;
      height: 30%;
      border-radius: 20px;
      opacity: 0.1;
      top: 65%;
      flex-direction: column;
      float: inline-end;
      position: absolute;
  }
  </style>