<template>
    <div id="app" class="flex justify-content-center" >
      <sidebar @open-modal="showModal = true" />
      <SelectBar/>
      <WindowOven/>
      <div>
        <OvenData v-if="state.drying_oven_dashboard.length > 2 && state.loadedAll" graph-title="Drying oven - heating (kW)" :stats="state.drying_oven_stats" :stitle="'Drying oven'" :electricGraph = "state.dryingOvenElGraph" :elements="1" :data="state.drying_oven_dashboard"/>
        <OvenData v-if="state.curing_oven_dashboard.length > 2 && state.loadedAll" graph-title="Curing oven - heating (kW)" :stats="state.curing_oven_stats" :electricGraph = "state.curingOvenElGraph" :elements="state.curingElements" :stitle="'Curing oven'" :data="state.curing_oven_dashboard"/>
        <NoData v-show="state.curing_oven_dashboard.length <1 && state.loadedAll" @close-modal="state.loadedAll = false" />  
          <v-divider/>
        <NoRightsModal v-show="showModal" @close-modal="showModal = false" />
      </div>
    </div>
  </template>
    
  <script>
  import Sidebar from '../components/Sidebar.vue'
  import api from '../api'
  import state from '../state'
  import axios from 'axios'
  import NoRightsModal from '../components/NoRightsModal.vue'
  import dataHandler  from '../script/Calculations/dataHandler'
  import SelectBar from '../components/SelectBar.vue'
  import OvenData from '../components/Ovenpage/OvenData.vue'
  import NoData from '../components/Homepage/NoData.vue'
  import WindowOven from '../components/Ovenpage/WindowOven.vue'
  export default {
    components: { Sidebar, NoRightsModal, SelectBar, OvenData, NoData, WindowOven },
    data: () => ({
      selectItem: 0,
      allUsers: null,
      currentTime: 1,
      dHandler: new dataHandler(),
      showModal: false,
      alarmData: {
        totalCritical: 0,
        totalTimeLost: 0
      },
      api,
      loaded: false,
      loadedTest: false,
      state,
    }),
    watch: {
    'state.date.select': function (newVal, oldVal) {
      this.state.loadedAll = false
      this.dHandler.getData()
    },

    'state.dates': function (newVal, oldVal) {
      this.state.loadedAll = false
      this.dHandler.getData()

    },

    'state.selectedID': function (newVal, oldVal) {
      this.dHandler.getData()
    },
    },
    mounted() {
      this.state.currentPage = "Ovens"
      console.log(this.state.curing_oven_dashboard)
      if (this.state.dates.length == 0) {
      let date = new Date()
      
      date.setHours(0,0,0,0)
      this.state.dates[0] = date.toJSON()
    }
      //this.getApiCall();
      this.$ga.page({
        page: '/Home',
        title: 'Home page',
        location: window.location.href
      });

      if (!this.state.loadedAll) {
        this.dHandler.getData()
      }
      this.loadedTest = true
    },
  }
  </script>
    
  <style scoped>
  .border {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10%;
    text-decoration: none;
  }
  
  .space {
    margin-left: -100px;
    margin-right: -100px;
  }
  
  .select {
    max-width: 200px;
    max-height: 500px;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .top {
    margin-top: 80px;
  }
  
  .v-list-item-group .v-list-item--active {
    color: white !important;
  }
  
  .center-title {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(255, 248, 248);
  }
  
  .center-title2 {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(131, 127, 127);
  }
  
  .cost-graph {
    margin-left:20px;
  }
  
  .pie-chart {
    width: auto;
      margin: auto;
  }
  </style>
