<template>
    <v-col cols="12" md="11" ref="col">
      <v-item>
        <v-card :color="state.black ? '#1e1e1e' : 'white'" class="d-flex align-center rounded-xl" dark height="600" title="oven">
          <GChart v-if="chartData && state.loadedAll" class="cost-graph" type="LineChart" :data="chartData"
                  :options="chartOptions" />
        </v-card>
      </v-item>
    </v-col>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'
import state from '../../../state'
import { getCurrentInstance } from 'vue';
export default {
    components: {GChart },
  data: () => ({
      chartData: [
    ['Time', 'Gasdata'],
    ['Pump', 1000],
    ['Lowlevel', 1170],
    ['Highlevel', 660],
    ['E stop', 1030]
  ],
  view: {},
  chartOptions: {
    title: 'Bath 1',
    series: {
      0: { color: '#be1a20' },
      1: {color: 'black'},        
      2: { color: '#b70d7f'}
    },
    hAxis: {
        format: 'dd/MM/yyyy HH:mm',
        textStyle:{color: '#7c7c7c'}
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'},
        viewWindow: {
            max:220,
            min:0
        }
      },
      legendTextStyle: { color: '#7c7c7c' },
      titleTextStyle: { color: '#7c7c7c' },
      color: '#FFF',
      
    backgroundColor: {fill:'transparent'},
      legend: {position: 'bottom'},
      chartArea: { left: 40, top: 50, width: "88%", height: "70%" },
        width: window.innerWidth / 1.8,
        height: window.innerHeight / 5.6
  },
  loaded: false,
  state,
}),
props: {
  title: String,
  data: Array
},
watch: {

},

mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.chartOptions.width = this.$refs.col.clientWidth
    this.chartOptions.height = this.$refs.col.clientHeight - 40
},

methods: {
    onResize() {
      this.chartOptions.width = this.$refs.col.clientWidth
      this.chartOptions.height = this.$refs.col.clientHeight - 40
    },
  loadGraph () {
    this.chartOptions.title = this.title
    this.loaded = false;
    let tempData = []
    this.chartData = []
    tempData[0] = ['Time', 'Temperature', 'Setpoint']
    let comp = 1
  this.data = this.data.sort((a, b) => (a.timeStamp > b.timeStamp) ? 1 : -1)
  for (let i = 0; i<this.data.length; i++) {
    if (this.data[i].timeStamp) {
      let formatTimestamp = new Date(this.data[i].timeStamp)
            formatTimestamp.setSeconds(0,0)
            formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
        tempData[i+1] = [formatTimestamp, this.data[i].temperature, this.data[i].setpoint]
    } else {
      comp += 1
    }
    if ((i+comp) == this.data.length -1) {
          this.loaded = true;
      }
    }
    this.chartData = tempData
    const instance = getCurrentInstance();
  }
},
created() {
  this.loadGraph ()
      
  },
}
</script>
<style>
.pie-chart {
  width: auto;
    margin: auto;
}
.icon-play-circle{
  font-size: 100px;
    position: absolute;
    top: 75%;
    left: 45%;
    opacity: 1;
    color: blue;
}
.margin-bottom{
  margin-bottom: 100px;
}
.blurred {
  background-color: #eeeeee;
    flex: inherit;
    width: 85%;
    left: 100px;
    height: 30%;
    border-radius: 20px;
    opacity: 0.1;
    top: 65%;
    flex-direction: column;
    float: inline-end;
    position: absolute;
}
</style>