<template>
  <v-app :style="state.black ? {background: '#161616'} : {background: '#f5f5f9'} ">
    <v-main >
      
      <router-view v-if="state.login & state.userData.rights[0] != 'offerte'" class="mb-15"/>
      <div v-else-if="state.userData.rights[0] == 'offerte'"><Offerte/></div>
      <login v-else/>
      <no-license v-show="state.noAcces" @close-modal="state.noAcces = false" />
    </v-main>
  </v-app>
</template>

<script>
import state from './state'
import Login from './components/Login'
import NoLicense from './components/Homepage/NoLicense.vue'
import Offerte from './views/Offerte.vue'
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
export default {
  name: 'App',
  components: { Login, NoLicense, Offerte},
  data: () => ({
    state
  }),
  mounted () {
    if (localStorage.getItem('black') === null) {
      this.state.black = localStorage.getItem('black')
    }
    const config = {
      apiKey: "AIzaSyBa39bhxCeAOSDdC-9-Rr6vgTH3ujF3lIQ",
      authDomain: "indusotrage.firebaseapp.com",
      projectId: "indusotrage",
      storageBucket: "indusotrage.appspot.com",
      messagingSenderId: "763508422089",
      appId: "1:763508422089:web:20ed432318eab3a8366418",
      measurementId: "G-PXHMRJRMJB",
      storageBucket: 'gs://indusotrage.appspot.com'
    }
    this.state.firebase = initializeApp(config);
    this.state.storage = getStorage(this.state.firebase, 'gs://indusotrage.appspot.com')
    this.state.firestore = getFirestore(this.state.firebase)

    this.state.loadedAll = false
  },
  methods: {
    Logout () {
      state.login = false
      sessionStorage.setItem('149130fivjx-092', null)
      this.$router.push({ path: '/', replace: true })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
