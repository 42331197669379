<template>
    <v-col md="1" align-self="center">
        <v-card v-if="onhover" height="450" width="200">

        </v-card>
    <v-btn @click="clicked()" icon><v-icon>fa fa-plus</v-icon></v-btn>
    </v-col>
</template>

<script>
  export default {

    methods: {
        clicked () {
            this.$emit('clicked')
        }
    }
  }
</script>