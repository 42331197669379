<template>
    <v-carousel :show-arrows="false" class="mb-15" >
    <v-carousel-item
      cover
    >
    <v-img src="../../voorbehandeling.jpg">
        <v-card outlined color="transparent" class="mt-15">
        <v-row justify="center" class="mt-15" v-if="state.loadedAll">
                <v-col v-if="state.chemicalData[0].b1HasDosingPump1" cols="12" md="2" class="mr-5">
                <v-item>
                    <v-card :color="'#b70d7f'" class="d-flex align-center rounded-xl" dark height="200">
                    <v-row @click="selectWidget('ch')">
                        <v-col cols="12" sm="12">
                        <v-list-item three-line class="mt-10">
                            <v-list-item-content>
                            <div class="mb-4">

                                <v-icon x-large :color="'white'">fas fa-droplet </v-icon>

                            </div>
                            <v-list-item-subtitle :class="'white--text'">Total chemicalusage</v-list-item-subtitle>
                            <v-list-item-title class="headline mb-1" :class="'white--text'">
                                <strong v-if="state.loadedAll">{{ Math.floor(state.chemicalUsage / 100) / 10 }} L</strong>
                            </v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>
                        </v-col>
                        
                  </v-row>

                </v-card>
              </v-item>
            </v-col>
        </v-row>

        <div v-else>
            <v-row justify="center" class="mt-15" >
            <v-col cols="12" md="2">
            <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
            ></v-skeleton-loader>
            </v-col>
            </v-row>
        </div>
    </v-card>
    </v-img>
    </v-carousel-item>
  </v-carousel>
  </template>

<script>
 import state from '../../state'
 import { getCurrentInstance } from 'vue';
 export default {
  data: () => ({
    state,
    items: [
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
          },
          {
            src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
          },
        ],
  }),
}
</script>