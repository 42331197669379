<template>
    <div>
      <v-card class="mr-5 ml-5 rounded-tl-xl rounded-br-xl mt-15" :style="state.black ? {background: '#363636'} : {background: '212121'} ">
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >

      <v-badge content="base" color="black" inline>
        <h3>{{stitle}}  insight</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
    <v-expand-transition>
      <v-item-group v-show="tab" class="mb-10">
        <v-row justify="center" class="align-top">
          <v-col cols="12" md="8">
            <v-card class="mb-15" :color="state.black ? '#1e1e1e' : 'white'">
              <v-app-bar
                    dark class="text-h6">Oven graphs
                  </v-app-bar>
            <v-row justify="center">
            <oven_temperature v-if="state.format=='Day'" :title="'Temperature (°C)'" :data="data"/>
          </v-row>
            <v-row justify="center">          
            <Electricity v-if="state.loadedAll && electricGraph.length > 1 && state.format=='Day'" :stitle="graphTitle" :amount-elements="elements" :data="electricGraph"/>
          </v-row>
          <v-row justify="center">            
            <oven_gas v-if="state.totalTotalGas > 0" :title="'Gas (m³/h)'" :data="data"/>
        </v-row >
            <v-row justify="center">
            <oven_speed :title="'Speed (m/min)'" :data="data"/>
        </v-row>
      </v-card>
        </v-col>

            <WarmUp v-if="state.format == 'Day'" :data="stats"/>

            
          </v-row>
          
        </v-item-group>
      </v-expand-transition>
    </v-card>
    </div>
</template>

<script>
import api from '../../api'
import state from '../../state'
import oven_gas from './charts/oven_gas.vue'
import oven_speed from './charts/oven_speed.vue'
import oven_temperature  from './charts/oven_temperature.vue'
import Electricity from './charts/oven_electric.vue'
import WarmUp from './WarmUp.vue'
export default {
  components: { oven_gas, oven_speed, oven_temperature, Electricity, WarmUp },
  data: () => ({
    currentTime: 1,
    selectedUser: 'OP',
    api,
    tab: true,
    userD: {
      name: ''
    },
    loaded: false,
    state,
    title: 'hoi',
    conductivity: 0,
    chemicals: 0,
    ph: 0,
    temperature: 0,
  }),
  props: {
        stitle: String,
        data: Array,
        stats: Object,
        electricGraph: Array,
        elements: Number,
        graphTitle: String,
    },

  created() {
    this.title = this.stitle
    this.dataConverter()
  },

  watch: {
    'data.length' (val) {
    }
  },

  methods: {
    selectWidget (widget) {
        this.state.selectedWidget = widget
    },

    dataConverter () {
      let totalIndexes = 0
      let totalCombinedph = 0
      let totalCombinedus = 0
      let totalCombinedtemp = 0
      let totaladdedDosing= 0

      for (let i =0; i<this.data.length; i++) {
        let indexData = this.data[i]
        if (indexData.conductivity) {
          let newCond = indexData.conductivity.conductivity
          totalCombinedus += parseInt(newCond.replace('+', ''))
        }
        
        if (indexData.ph) {
          let newph = indexData.ph.ph
          totalCombinedph += parseInt(newph.replace('+', ''))
          
        }

        if (indexData.heating) {
          let newTemp = indexData.heating.temperature
          totalCombinedtemp += parseInt(newTemp.replace('+', ''))
        }

        totalIndexes += 1

      }

      this.chemicals = 0
      this.ph = Math.round(totalCombinedph / totalIndexes)
      this.conductivity = Math.round(totalCombinedus / totalIndexes)
      this.temperature = Math.round(totalCombinedtemp / totalIndexes)
    }
  }
}
</script>
<style>
.border {
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 10%;
  text-decoration: none;
}

.space {
  margin-left: -100px;
  margin-right: -100px;
}

.select {
  max-width: 200px;
  max-height: 500px;
  margin-left: 20px;
  margin-top: 20px;
  font-size: 20px;
}

.top {
  margin-top: 80px;
}

.v-list-item-group .v-list-item--active {
  color: white !important;
}

.center-title {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(203, 203, 203);
}

.center-title2 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(255, 255, 255);
}

.center-title3 {
  align-self: center;
  text-align: center;
  font-size: 25px;
  margin-top: 15px;
  color: rgb(0, 0, 0);
}

.cost-graph {
  margin-left:20px;
}

.pie-chart {
  width: auto;
    margin: auto;
}
</style>