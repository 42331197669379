<template>
    <div class="pa-4 text-center">
        <v-dialog
        v-model="show"
        max-width="600"
        >  
        <v-card rounded="lg">
              <v-card-title class="d-flex justify-space-between align-center">
                <div class="text-h5 text-medium-emphasis ps-2">
                  Lab creation panel
                </div>

                <v-btn
                  icon="mdi-close"
                  variant="text"
                  @click="isActive.value = false"
                ></v-btn>
              </v-card-title>

              <v-divider class="mb-4"></v-divider>

              <v-card-text>
                <div class="text-medium-emphasis mb-4">
                  Create a new lab, by giving it a name and descripton
                </div>
                <div class="mb-2 mt-5">Name</div>
                <v-text-field v-model="newObject.name"></v-text-field>
                <div class="mb-2 mt-5">Description</div>

                <v-textarea
                  :counter="300"
                  class="mb-2"
                  rows="2"
                  v-model="newObject.description"
                  variant="outlined"
                  persistent-counter
                ></v-textarea>

                <v-btn
                  class="text-none font-weight-bold ms-n4"
                  color="primary"
                  text="Retry Premium Free"
                  variant="text"
                ></v-btn>
              </v-card-text>

              <v-divider class="mt-2"></v-divider>

              <v-card-actions class="my-2 d-flex justify-end">
                <v-btn
                  class="text-none"
                  rounded="xl"
                >Cancel</v-btn>

                <v-btn
                  class="text-none"
                  color="primary"
                  rounded="xl"
                  variant="flat"
                  @click="createLab()"
                >Create</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
  </template>

  <script>
    import state from '../../state';
    export default {
        data: () => ({
            state,
            newObject: {
                name: '',
                description: '',
            }
        }),
        props: {
            value: Boolean
        },
        mounted() {
        },
        computed: {
            show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
            }
        },
        watch: {
        },
        methods: {
            createLab () {
                this.$emit('createLab', this.newObject)
            }
        }

    }
</script>